import React, { useEffect, useState } from "react"
import "./Uncategorized.styles.scss"
import ParticipantsTable from "../../components/Uncategorized/ParticipantsTable/ParticipantsTable.component"
import { useDispatch, useSelector } from "react-redux"
import BulkActionsDrawer from "../../components/Uncategorized/BulkActionsDrawer/BulkActionsDrawer.component"
import SearchPeople from "../../components/UI/SearchPeople/SearchPeople.component"
import { CSSTransition } from "react-transition-group"
import { setRightRailCollapsed } from "../../redux/App/App.actions"
import { ReactComponent as PeopleWithMagnifyingGlass } from "./../../images/PeopleWithMagnifyingGlass.svg"
import { nylasAuthenticationURI } from "../../config/nylas"

const UncategorizedPage = () => {
  const dispatch = useDispatch()
  const selectedParticipantsIds = useSelector(
    (state) => state.AppState.selected_participants_ids
  )
  const user = useSelector((state) => state.UserState)
  const contactDrawerVisible = useSelector(
    (state) => state.AppState.contactDrawerVisible
  )
  const rightRailCollapsed = useSelector(
    (state) => state.AppState.rightRailCollapsed
  )

  const [prevRightRailCollapsed, setPrevRightRailCollapsed] = useState(false)
  const [selectedOpened, setSelectedOpened] = useState(false)

  useEffect(() => {
    if (selectedParticipantsIds.length) {
      setSelectedOpened(true)
    } else {
      setSelectedOpened(false)
    }
  }, [selectedParticipantsIds])

  useEffect(() => {
    if (contactDrawerVisible || selectedOpened) {
      setPrevRightRailCollapsed(rightRailCollapsed)
      dispatch(setRightRailCollapsed(false))
    } else if (prevRightRailCollapsed) {
      dispatch(setRightRailCollapsed(true))
    }
    // eslint-disable-next-line
  }, [contactDrawerVisible, selectedOpened])

  const connectAccount = () => {
    nylasAuthenticationURI(null, null, (url) => {
      window.location.replace(url)
    })
  }

  return (
    <div className="UncategorizedPage">
      {["intern", "rookie"].includes(user.status) && (
        <div className="DatabasePage_WelcomeInfo">
          <PeopleWithMagnifyingGlass />
          <div>
            <h3>Are these people important?</h3>
            <span>
              Once you’ve{" "}
              <button onClick={connectAccount}>
                connect your email and calendar
              </button>
              , Relatable goes to work pulling in everyone you’ve communicated with.
              <br />
              <br />
              For the people you want to remember and stay in touch with, add them to
              one or more Sphere. The rest you can Dismiss (you can always come back
              to them later).
            </span>
          </div>
        </div>
      )}
      <SearchPeople />
      <div>
        <CSSTransition in={true} timeout={1000} classNames="fade" appear>
          <ParticipantsTable uncategorized={true} />
        </CSSTransition>
        <BulkActionsDrawer visible={selectedParticipantsIds.length > 0} />
      </div>
    </div>
  )
}

export default UncategorizedPage
