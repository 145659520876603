import React, { useState, useEffect } from "react"
import "./Onboarding.styles.scss"
import { Drawer, Progress, Spin } from "antd"
import { nylasAuthenticationURI } from "../../../config/nylas"
import axios from "axios"
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { hideOnboardingFlow } from "../../../redux/User/User.actions"
import { addAccount } from "../../../redux/Accounts/Accounts.actions"
import { ReactComponent as PeopleWithMagnifyingGlass2 } from "../../../images/PeopleWithMagnifyingGlass2.svg"
import { ReactComponent as ContactCard } from "../../../images/ContactCard.svg"
import { ReactComponent as Timer } from "../../../images/Timer.svg"
import { notification } from "antd"
import FadedConvos from "../../Admin/AccountsModal/FadedConvos.component"
import OnboardingCal from "../OnboardingScreens/OnboardingEWebinar/OnboardingEWebinar.component"
import { useQueryParam, StringParam } from "use-query-params"

const Onboarding = ({ defaultStep = "0" }) => {
  const user = useSelector((state) => state.UserState)
  const accounts = useSelector((state) => state.AccountsState.accounts || [])
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [updatingChoices, setUpdatingChoices] = useState(false)
  const [selectedChoices, setSelectedChoices] = useState([])
  const [currentStep, setCurrentStep] = useState(parseInt(defaultStep))
  const [fadedConversations, setFadedConversations] = useState([])
  const [selectedContacts, setSelectedContacts] = useState([])
  const [progress, setProgress] = useState(0)
  const [userEmail, setUserEmail] = useState(null)
  const [code] = useQueryParam("code", StringParam)
  const [service] = useQueryParam("service", StringParam)
  const [state] = useQueryParam("state", StringParam)
  const status = useSelector((state) => state.UserState.status)

  useEffect(() => {
    if (progress == 0 && userEmail) {
      fetchFadedConversations()
    }
  }, [userEmail])

  const connectAccount = () => {
    nylasAuthenticationURI(
      null,
      `${process.env.REACT_APP_DEV_UI_URL}/dashboard?onboarding_step=2`,
      (url) => {
        window.location.replace(url)
      }
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      // Poll for status
      const interval = setInterval(async () => {
        const status = await axios.get("/v1/nylas/get_recent_participants_status", {
          params: { email: userEmail },
        })

        setProgress(status.data.progress)

        if (status.data.status === "error") {
          clearInterval(interval)
          notification.error({
            message: "Error",
            description: status.data.error || "Failed to process participants",
          })
          setLoading(false)
        } else if (status.data.status === "participants_collected") {
          const result = await axios.get("/v1/nylas/get_faded_conversations", {
            params: { email: userEmail },
          })
          setFadedConversations(result.data.faded_contacts)
          setProgress(100)
        } else if (status.data.status === "completed") {
          clearInterval(interval)
          setLoading(false)
        }
      }, 500)

      // Add timeout after 2 minutes
      setTimeout(() => {
        if (interval && progress < 100 && loading) {
          notification.warning({
            message: "Timeout",
            description: "Request took too long. Please try again.",
          })
          setLoading(false)
          clearInterval(interval)
        }
      }, 120000)

      return () => clearInterval(interval)
    }

    if (userEmail && progress == 0) {
      fetchData()
    }
  }, [userEmail])

  const updateChoices = async () => {
    if (selectedChoices.length === 0) return

    setUpdatingChoices(true)
    await axios.request({
      url: "/v1/users/update_choices",
      data: {
        choices: selectedChoices,
      },
      method: "PATCH",
    })

    setUpdatingChoices(false)
    handleStepChange(4)
  }

  const handleChoiceChange = (choice) => {
    setSelectedChoices((prevChoices) => {
      if (prevChoices.includes(choice)) {
        return prevChoices.filter((c) => c !== choice)
      } else {
        return [...prevChoices, choice]
      }
    })
  }

  // Handle step change and update URL
  const handleStepChange = (nextStep) => {
    setCurrentStep(nextStep)
    // Update URL to reflect current step
    history.push(`/dashboard?onboarding_step=${nextStep}`)
  }

  const options = [
    {
      label: "I want to generate more business opportunities",
      value: "business_opportunities",
    },
    {
      label: "I want to keep in better touch with my network",
      value: "keep_in_touch",
    },
    {
      label: "I am trying to grow my network",
      value: "grow_network",
    },
    {
      label: "I want to be more of a connector",
      value: "be_connector",
    },
    {
      label: "I just love people",
      value: "love_people",
    },
  ]

  const quotes = {
    0: {
      text: "The scariest and most courageous thing you'll ever do is to be yourself.",
      author: "Dan Sullivan",
    },
    1: {
      text: "Doubt can only be removed by action.",
      author: "Ted Lasso",
    },
    2: {
      text: "We have a strategic plan. It's called doing things.",
      author: "George Kelleher",
    },
    3: {
      text: "We have a strategic plan. It's called doing things.",
      author: "George Kelleher",
    },
    4: {
      text: "We have a strategic plan. It's called doing things.",
      author: "George Kelleher",
    },
    5: {
      text: "We have a strategic plan. It's called doing things.",
      author: "George Kelleher",
    },
  }

  useEffect(() => {
    if (code) {
      dispatch(
        addAccount(
          code,
          state,
          `${process.env.REACT_APP_DEV_UI_URL}/dashboard?onboarding_step=2`
        )
      )
    }
    // eslint-disable-next-line
  }, [code, service])

  const renderConnectedAccounts = () => {
    if (accounts && accounts.length > 0) {
      return (
        <div className="Onboarding_ConnectedAccounts">
          <h3>Your connected accounts:</h3>
          <div className="Onboarding_ConnectedAccounts_List">
            {accounts.map((account) => (
              <div key={account.id} className="Onboarding_ConnectedAccounts_Item">
                <div className="Onboarding_ConnectedAccounts_Icon">✉️</div>
                <div className="Onboarding_ConnectedAccounts_Details">
                  <div className="Onboarding_ConnectedAccounts_Email">
                    {account.email}
                  </div>
                  <div className="Onboarding_ConnectedAccounts_Status">
                    {account.state === "running" ? (
                      <span className="status-running">Active</span>
                    ) : (
                      <span className="status-other">{account.state}</span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
    return null
  }

  const renderProgress = () => {
    if (progress < 100 && userEmail) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            padding: "20px",
            margin: "auto",
          }}
        >
          <span style={{ color: "#73746f", fontSize: "14px", fontWeight: "bold" }}>
            Searching for people...
          </span>
          <Progress
            showInfo={false}
            type="line"
            strokeColor="#F4C041FF"
            percent={progress}
            status={progress < 100 ? "active" : "success"}
            style={{ marginTop: "20px" }}
          />
        </div>
      )
    }
    return null
  }

  const renderContent = () => {
    switch (currentStep.toString()) {
      case "0":
        return (
          <>
            <div className="Onboarding_Step_Header">
              <h1>
                Hello {user?.first_name ? user.first_name : ""}!<br></br> Welcome to
                Relatable.
              </h1>
            </div>
            <div className="Onboarding_Step_Body">
              <p>Relatable is here to help you do one thing, really well.</p>
              <p>
                <b>Nurture the relationships that matter.</b>
              </p>
            </div>
            <div className="Onboarding_Step_Footer">
              <button onClick={() => handleStepChange(1)}>Alright, let's go!</button>
            </div>
          </>
        )
      case "1":
        return (
          <>
            <div className="Onboarding_Step_Header">
              <h1>First, let's get connected</h1>
            </div>
            <div className="Onboarding_Step_Body">
              <p>You can connect as many accounts as you'd like.</p>
              <p>
                Connect your main email account, and we'll take a look at who you've
                been emailing or meeting with, for starters.
              </p>
              {renderConnectedAccounts()}
            </div>
            <div className="Onboarding_Step_Footer">
              <button
                className="connect-button"
                onClick={() => {
                  connectAccount()
                }}
              >
                Let's get connected!
              </button>
              <button
                className="skip-button"
                onClick={() => {
                  const email = accounts[0]?.email || null
                  setUserEmail(email)
                  handleStepChange(3)
                }}
              >
                Skip for now
              </button>
            </div>
          </>
        )
      case "2":
        return (
          <>
            <div className="Onboarding_Step_Header">
              <h1>You connected your first account!</h1>
            </div>
            {renderConnectedAccounts()}
            <div className="Onboarding_Step_Body">
              <p>Great work! You can connect more later in your settings.</p>
              <p>
                Our magical elves are now hand-sorting your inbox and calendar,
                looking for interesting people.
              </p>
              <p>
                It's actually software code - no humans or elves ever see your
                personal information. But it just sounds cooler, doesn't it?
              </p>
            </div>
            <div className="Onboarding_Step_Footer">
              {renderProgress()}
              <button
                className={`Onboarding_Step_Footer_Button`}
                onClick={() => handleStepChange(3)}
              >
                Alright, I'm ready for what's next!
              </button>
            </div>
          </>
        )

      case "3":
        return (
          <>
            <div className="Onboarding_Step_Header">
              <h1>What brings you to Relatable today?</h1>
            </div>
            <div className="Onboarding_Step_Body">
              <p>
                Relationships are critical for so many aspects of our jobs,
                businesses, and lives. What are you focused on right now?
              </p>
              <div className="Onboarding_Step_Body_Choices">
                {options.map((option) => (
                  <div
                    key={option.value}
                    className={`Onboarding_Step_Body_Choices_Item ${
                      selectedChoices.includes(option.value)
                        ? "Onboarding_Step_Body_Choices_Item--selected"
                        : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      id={option.value}
                      checked={selectedChoices.includes(option.value)}
                      onChange={() => handleChoiceChange(option.value)}
                    />
                    <label htmlFor={option.value}>{option.label}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="Onboarding_Step_Footer">
              {renderProgress()}
              <button
                className={`Onboarding_Step_Footer_Button ${
                  selectedChoices.length === 0
                    ? "Onboarding_Step_Footer_Button--disabled"
                    : ""
                }`}
                onClick={updateChoices}
                disabled={updatingChoices || selectedChoices.length === 0}
              >
                {updatingChoices ? <Spin size="small" /> : "Alright, let's go!"}
              </button>
            </div>
          </>
        )
      case "4":
        return (
          <>
            <div className="Onboarding_Step_Header">
              <h1>Here's how Relatable works</h1>
            </div>
            <div className="Onboarding_Step_Body">
              <p>We can do a lot of things to help you nurture those people!</p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: "40px",
                  maxWidth: "600px",
                  margin: "20px 0",
                }}
              >
                <div className="Onboarding_Step_Body_Info">
                  <PeopleWithMagnifyingGlass2 className="Onboarding_Step_Body_Info_Image" />
                  <div className="Onboarding_Step_Body_Info_Container">
                    <h1>
                      <b>Bring 'em in!</b>
                    </h1>
                    <p>
                      Relatable automagically syncs all your contacts and the
                      conversations you have with them - email, phone, calendar,
                      text, WhatsApp, etc.
                    </p>
                  </div>
                </div>
                <div className="Onboarding_Step_Body_Info">
                  <div className="Onboarding_Step_Body_Info_Container">
                    <h1>
                      <b>Get Organized!</b>
                    </h1>
                    <p>
                      You can add any details you want about your contacts (we
                      already add a lot) - and most importantly, what{" "}
                      <b>Sphere(s)</b> you want them to go in.
                    </p>
                  </div>
                  <ContactCard className="Onboarding_Step_Body_Info_Image" />
                </div>
                <div className="Onboarding_Step_Body_Info">
                  <Timer className="Onboarding_Step_Body_Info_Image" />
                  <div className="Onboarding_Step_Body_Info_Container">
                    <h1>
                      <b>Stay in touch!</b>
                    </h1>
                    <p>
                      We'll help you find people in your Spheres that you may want to
                      engage with - birthdays, job changes, or if it's just been a
                      while - then help you reach out!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Onboarding_Step_Footer">
              {renderProgress()}
              <button
                onClick={() => {
                  if (userEmail) {
                    handleStepChange(5)
                  } else {
                    handleStepChange(6)
                  }
                }}
              >
                I'm in! What do I need to do?
              </button>
            </div>
          </>
        )

      case "5":
        return (
          <>
            <div className="Onboarding_Step_Header">
              <h1>Let's get started organizing your database.</h1>
            </div>
            <div className="Onboarding_Step_Body">
              <p>Doing a few contacts every day makes a huge difference.</p>
              <p>
                Choose which <b>Spheres</b> you want to add them to, or Dismiss them
                to hide them. Don't worry, you can always move them around later!
              </p>

              {progress < 100 && userEmail ? (
                renderProgress()
              ) : (
                <div className="participants-table-container">
                  <FadedConvos
                    participants={fadedConversations}
                    userEmail={userEmail}
                  />
                </div>
              )}
            </div>
            <div className="Onboarding_Step_Footer">
              <button
                className={`Onboarding_Step_Footer_Button ${
                  loading ? "Onboarding_Step_Footer_Button--disabled" : ""
                }`}
                onClick={() => {
                  if (!loading) {
                    handleStepChange(6)
                  }
                }}
              >
                Next
              </button>
            </div>
          </>
        )
      case "6":
        return (
          <>
            <div className="Onboarding_Step_Header">
              <h1>While we’re bringing in your contacts, get to know Relatable</h1>
            </div>
            <div className="Onboarding_Step_Body">
              <p>
                We host fantastic welcome webinars twice a day.
                <br /> Can we sign you up for one of them?
              </p>
              <OnboardingCal />
            </div>
            <div className="Onboarding_Step_Footer">
              <button
                onClick={() => {
                  dispatch(hideOnboardingFlow())
                  history.push("/dashboard")
                }}
              >
                Great! Take me to my dashboard.
              </button>
            </div>
          </>
        )
      default:
        return null
    }
  }

  const renderStepIndicator = () => {
    const totalSteps = 7 // Total number of steps in the onboarding flow

    return (
      <div className="Onboarding_StepNumberInfo">
        {[...Array(totalSteps)].map((_, i) => (
          <div
            key={i}
            className={`Onboarding_StepNumberInfo_Step ${
              i === currentStep ? "Onboarding_StepNumberInfo_Step--active" : ""
            }`}
            // onClick={() => handleStepChange(i)}
          />
        ))}
      </div>
    )
  }

  const renderQuote = () => {
    if (quotes[currentStep]) {
      return (
        <div className="Onboarding_Step_Body_Quote">
          {quotes[currentStep].text} - {quotes[currentStep].author}
        </div>
      )
    }
    return null
  }

  const fetchFadedConversations = async () => {
    setLoading(true)
    try {
      const r = await axios.request({
        url: "/v1/nylas/get_recent_participants",
        method: "GET",
        params: {
          email: userEmail,
        },
      })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => {}}
      visible={true}
      width="100%"
      contentWrapperStyle={{ maxWidth: "100%" }}
      bodyStyle={{ padding: 0 }}
    >
      <div className="Onboarding">
        <div>
          <img
            className="Onboarding_Logo"
            width={300}
            src={`${process.env.PUBLIC_URL}/primary.svg`}
            alt="Relatable"
          />
        </div>
        {renderStepIndicator()}

        <div className="Onboarding_StepContainer">
          <div></div>
          <div className="Onboarding_Step">{renderContent()}</div>
          <div className="Onboarding_Step_Quote">{renderQuote()}</div>
        </div>
      </div>
    </Drawer>
  )
}

export default Onboarding
