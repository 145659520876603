import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal, Select } from "antd"
import "./DuplicatedEmailAndPhoneNumbersModal.styles.scss"
import history from "../../../../history"
import { setContactDrawerMode } from "../../../../redux/App/App.actions"
import { useDispatch, useSelector } from "react-redux"
import { mergeContacts } from "../../../../redux/People/People.actions"
import RelatableLoader from "../../../UI/RelatableLoader/RelatableLoader.component"

const DuplicatedEmailAndPhoneNumbersModal = ({
  visible,
  onCancel,
  duplicatedEmails,
  duplicatedPhoneNumbers,
  currentContact,
  mergeAvailable,
}) => {
  const dispatch = useDispatch()
  const [main_contact_id, setMainContactId] = useState(null)
  const [mergedContacts, setMergedContacts] = useState([])
  const peopleState = useSelector((state) => state.PeopleState) || {}
  const merging = peopleState.merging || false
  const [close, setClose] = useState(true)

  useEffect(() => {
    if (
      currentContact &&
      (duplicatedEmails.length > 0 || duplicatedPhoneNumbers.length > 0)
    ) {
      try {
        const mergedContacts = [
          ...duplicatedEmails,
          ...duplicatedPhoneNumbers,
          currentContact,
        ]
          .filter((contact) => contact && contact.person_id)
          .map((contact) => ({
            value: contact.person_id,
            label: contact.person_full_name || "Unknown Contact",
          }))
        setMergedContacts(mergedContacts)
      } catch (error) {
        console.error("Error processing contact data:", error)
        setMergedContacts([])
      }
    }
  }, [duplicatedEmails, duplicatedPhoneNumbers, currentContact])

  const handleClickMerge = () => {
    try {
      if (!main_contact_id || !mergedContacts.length) {
        console.error("Cannot merge: Missing required data")
        return
      }

      dispatch(
        mergeContacts(
          mergedContacts.map((c) => c.value),
          1,
          [],
          null,
          null,
          null,
          main_contact_id
        )
      )
      setClose(true)
    } catch (error) {
      console.error("Error during merge operation:", error)
    }
  }

  useEffect(() => {
    if (!merging && close) {
      setClose(false)
      onCancel()
    }
  }, [close, merging, onCancel])

  const generateDescription = () => {
    const isFewEmails = duplicatedEmails.length > 1
    const isOneEmail = duplicatedEmails.length === 1
    const isFewPhoneNumbers = duplicatedPhoneNumbers.length > 1
    const isOnePhoneNumber = duplicatedPhoneNumbers.length === 1
    let partOfDescription = ""

    if (isFewEmails && isFewPhoneNumbers) {
      partOfDescription =
        "these email addresses and these phone numbers from the existing contacts"
    } else if (isFewEmails && isOnePhoneNumber) {
      partOfDescription =
        "these email addresses and the phone number from the existing contacts"
    } else if (isOneEmail && isFewPhoneNumbers) {
      partOfDescription =
        "the email address and these phone numbers from the existing contacts"
    } else if (isOneEmail && isOnePhoneNumber) {
      partOfDescription =
        "the email address and the phone number from the existing contacts"
    } else if (isFewPhoneNumbers) {
      partOfDescription = "these phone numbers from the existing contacts"
    } else if (isOnePhoneNumber) {
      partOfDescription = "the phone number from the existing contact"
    } else if (isFewEmails) {
      partOfDescription = "these email addresses from the existing contacts"
    } else if (isOneEmail) {
      partOfDescription = "the email address from the existing contact"
    } else {
      partOfDescription =
        "these email addresses and these phone numbers from the existing contacts"
    }

    return (
      <span>
        Please {mergeAvailable ? "merge contacts or" : ""} remove {partOfDescription}{" "}
        before adding it here
      </span>
    )
  }

  const handleShowPerson = (slug) => {
    if (!slug) return

    dispatch(setContactDrawerMode("view"))
    onCancel()
    history.push(`/database/person/${slug}`)
  }

  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onCancel}
      centered={true}
      width={680}
      className={"DuplicatedEmailAndPhoneNumbersModal__modal"}
    >
      {close && (
        <div className={"DuplicatedEmailAndPhoneNumbersModal_Loading"}>
          <RelatableLoader quote={true} loading={true} />
        </div>
      )}
      <div className="DuplicatedEmailAndPhoneNumbersModal">
        <div className={"DuplicatedEmailAndPhoneNumbersModal_Text"}>
          <span className={"DuplicatedEmailAndPhoneNumbersModal_Text_Title"}>
            Validation failed
          </span>
          <span className="DuplicatedEmailAndPhoneNumbersModal_Text_Description">
            <p>{generateDescription()}</p>
            {duplicatedEmails.length > 0 ? (
              <h4 style={{ marginBottom: "4px" }}>Duplicated emails:</h4>
            ) : null}
            {duplicatedEmails.map((d_e, index) => (
              <p
                key={index}
                className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem"
              >
                {d_e?.email || "Unknown email"} -{" "}
                <a
                  href={`/database/person/${d_e?.person_slug || ""}`}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem_FullName"
                >
                  {d_e?.person_full_name || "Unknown person"}
                </a>
              </p>
            ))}
            {duplicatedPhoneNumbers.length > 0 ? (
              <h4 style={{ margin: "12px 0 4px" }}>Duplicated phone numbers:</h4>
            ) : null}
            {duplicatedPhoneNumbers.map((d_p, index) => (
              <p
                key={index}
                className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem"
              >
                {d_p?.phone_number || "Unknown number"} -{" "}
                <a
                  href={`/database/person/${d_p?.person_slug || ""}`}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="DuplicatedEmailAndPhoneNumbersModal_Text_Description_DuplicateItem_FullName"
                >
                  {d_p?.person_full_name || "Unknown person"}
                </a>
              </p>
            ))}
          </span>
        </div>

        <div className={"DuplicatedEmailAndPhoneNumbersModal_Options"}>
          {mergeAvailable ? (
            <div className="DuplicatedEmailAndPhoneNumbersModal_Buttons">
              <Select
                style={{ width: "100%", marginTop: "6px" }}
                placeholder="Select main contact"
                optionFilterProp="children"
                onChange={setMainContactId}
                options={mergedContacts}
              />
              <div
                role={"button"}
                className={"DuplicatedEmailAndPhoneNumbersModal_YesButton"}
                onClick={handleClickMerge}
              >
                Merge
              </div>
            </div>
          ) : null}
          <div className="DuplicatedEmailAndPhoneNumbersModal_Buttons">
            <div
              role={"button"}
              className="DuplicatedEmailAndPhoneNumbersModal_NoButton"
              onClick={onCancel}
            >
              Back to Editing
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

DuplicatedEmailAndPhoneNumbersModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  duplicatedEmails: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      person_full_name: PropTypes.string,
      person_slug: PropTypes.string,
      person_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  duplicatedPhoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      phone_number: PropTypes.string,
      person_full_name: PropTypes.string,
      person_slug: PropTypes.string,
      person_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  currentContact: PropTypes.shape({
    person_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    person_full_name: PropTypes.string,
  }),
  mergeAvailable: PropTypes.bool,
}

DuplicatedEmailAndPhoneNumbersModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  duplicatedEmails: [],
  duplicatedPhoneNumbers: [],
  currentContact: null,
  mergeAvailable: false,
}

export default DuplicatedEmailAndPhoneNumbersModal
