import * as _ from "lodash"
import {
  APPROVE_DUPLICATE,
  APPROVE_DUPLICATE_SUCCESS,
  APPROVE_ENRICHMENT,
  APPROVE_ENRICHMENT_FAIL,
  CLEAR_REDUX_PEOPLE_STATE,
  CREATE_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACTS,
  DELETE_CONTACTS_FAIL,
  DELETE_CONTACTS_SUCCESS,
  EDIT_CONTACT,
  EDIT_CONTACT_FAIL,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACTS,
  EDIT_CONTACTS_FAIL,
  EDIT_CONTACTS_SUCCESS,
  FETCH_DUPLICATES,
  FETCH_DUPLICATES_SUCCESS,
  FETCH_ENRICHMENT_COUNT_SUCCESS,
  FETCH_ENRICHMENTS,
  FETCH_ENRICHMENTS_FAIL,
  FETCH_ENRICHMENTS_SUCCESS,
  FETCH_PEOPLE,
  FETCH_PEOPLE_FAIL,
  FETCH_PEOPLE_FROM_ALL_PAGES,
  FETCH_PEOPLE_FROM_ALL_PAGES_FAIL,
  FETCH_PEOPLE_FROM_ALL_PAGES_SUCCESS,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PERSON,
  FETCH_PERSON_FAIL,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSONS_EVENTS_SUCCESS,
  FETCH_PERSONS_INTERACTIONS_SUCCESS,
  FETCH_PERSONS_NOTES_SUCCESS,
  FETCH_PERSONS_REMINDERS_SUCCESS,
  FETCH_SELECTED_PEOPLE,
  FETCH_SELECTED_PEOPLE_FAIL,
  FETCH_SELECTED_PEOPLE_SUCCESS,
  IGNORE_DUPLICATE,
  IGNORE_DUPLICATE_SUCCESS,
  IGNORE_ENRICHMENT,
  IGNORE_ENRICHMENT_FAIL,
  MERGE_CONTACTS,
  MERGE_CONTACTS_FAIL,
  MERGE_CONTACTS_SUCCESS,
  SET_FETCHING_STATE,
  SET_INITIAL_REDUX_PEOPLE_STATE,
  UNARCHIVE_CONTACT_FAIL,
  UNARCHIVE_CONTACT_SUCCESS,
  UPDATE_CONTACT_IMAGE,
  UPDATE_CONTACT_IMAGE_FAIL,
  UPDATE_CONTACT_IMAGE_SUCCESS,
  UPDATE_ENABLED_COLUMNS,
  UPDATE_ENABLED_COLUMNS_FAIL,
  UPDATE_ENABLED_COLUMNS_SUCCESS,
  UPDATE_PERSON_SPHERES_FAIL,
  UPDATE_PERSON_SPHERES_SUCCESS,
  CLONE_TEAM_SHARED_PERSON_TO_MY_DB,
  CLONE_TEAM_SHARED_PERSON_TO_MY_DB_SUCCESS,
  CLONE_TEAM_SHARED_PERSON_TO_MY_DB_FAIL,
  UPDATE_TEAM_OWNERSHIP,
  UPDATE_TEAM_OWNERSHIP_SUCCESS,
  UPDATE_TEAM_OWNERSHIP_FAIL,
} from "./People.types"
import { ADD_TO_SPHERES_SUCCESS } from "../Participants/Participants.types"
import {
  ADD_INTERACTION_SUCCESS,
  ADD_NOTE_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_INTERACTION_SUCCESS,
  DELETE_NOTE_SUCCESS,
  UPDATE_NOTE_SUCCESS,
} from "../Interactions/Interactions.types"
import moment from "moment"
import {
  ADD_REMINDER_SUCCESS,
  COMPLETE_REMINDER_SUCCESS,
  DELETE_REMINDER_SUCCESS,
  UPDATE_REMINDER_SUCCESS,
} from "../Reminders/Reminders.types"
import { LOGOUT_SUCCESS } from "../User/User.types"

const INITIAL_STATE = {
  people: [],
  queriedPeople: [],
  total_entries: 0,
  fetching: false,
  fetching_selected: false,
  personLoading: false,
  updatingImage: false,
  editing: false,
  merging: false,
  archiving: false,
  loadingInteractions: false,
  suggested_duplicates: null,
  duplicatesTotalEntries: 0,
  enrichments: [],
  enrichments_total_entries: 0,
  enabled_columns: [],
  suggested_duplicates_loading: false,
  cloningContact: false,
}

const updateTeamOwnership = (people, action) => {
  // find person in state by id and update only the team_ownerships array
  // but keep order of the array
  let updated = people.find((person) => person.id === action.person.id)
  const index = people.indexOf(updated)
  const rest = people.filter((person) => person.id !== action.person.id)
  updated.team_access_info = action.person.team_access_info
  rest.splice(index, 0, updated)
  return [...rest]
}

const updatePerson = (people, action) => {
  let updated = people.find((person) => person.id === action.person.id)
  const index = people.indexOf(updated)
  const rest = people.filter((person) => person.id !== action.person.id)

  if (action.person.spheres.length > 0) {
    rest.splice(index, 0, action.person)
  }

  return [...rest]
}

const updatePersonAfterClone = (people, action) => {
  const old_person = people.find((person) => person.id === action.old_person_id)
  const index = people.indexOf(old_person)
  const rest = people.filter((person) => person.id !== action.old_person_id)

  rest.splice(index, 0, action.person)

  return [...rest]
}

const updatePeople = (people, action) => {
  let rest = people.filter(
    (person) => !action.people.map((p) => +p.id).includes(+person.id)
  )

  action.people.forEach((person) => {
    let updated = people.find((p) => {
      return +person.id === +p.id
    })

    const index = people.indexOf(updated)

    if (index >= 0) {
      rest.splice(index, 0, person)
    }
  })

  return [...rest]
}

const updatePersonNotes = (state, action) => {
  if (action.note.participants.length > 0) {
    let updated = state.people.find((person) =>
      action.note.participants.map((p) => +p.person_id).includes(+person.id)
    )

    if (updated) {
      const index = state.people.indexOf(updated)
      const rest = state.people.filter(
        (person) =>
          !action.note.participants.map((p) => +p.person_id).includes(+person.id)
      )

      if (updated.notes) {
        updated.notes = [
          action.note,
          ...updated.notes.filter((n) => +n.id !== +action.note.id),
        ]
      } else {
        updated.notes = [action.note]
      }

      rest.splice(index, 0, updated)

      return [...rest]
    } else {
      return [...state.people]
    }
  } else {
    return [...state.people]
  }
}

const deletePersonInteraction = (state, action, type = "notes") => {
  let updated = state.people.filter((p) => {
    if (p[type] && p[type].length) {
      return p[type].map((n) => +n.id).includes(+action.id)
    } else {
      return false
    }
  })
  let rest = state.people.filter((p) => !updated.map((u) => +u.id).includes(+p.id))

  if (updated) {
    updated = updated.map((p) => {
      p[type] = [...p[type].filter((n) => +n.id !== +action.id)]
      return p
    })
  }
  return [...updated, ...rest]
}

const updatePersonInteractions = (people, action) => {
  if (action.interaction.participants.length > 0) {
    let updated = people.find(
      (person) => person.id === action.interaction.participants[0].person_id
    )
    const index = people.indexOf(updated)
    const rest = people.filter(
      (person) => person.id !== action.interaction.participants[0].person_id
    )

    if (updated) {
      if (updated.interactions) {
        updated.interactions = [action.interaction, ...updated.interactions]
      } else {
        updated.interactions = [action.interaction]
      }

      if (updated.last_sent) {
        updated.last_sent = moment.max(
          moment(updated.last_sent),
          moment.unix(action.interaction.last_message_timestamp)
        )
      } else {
        let interactionDates = updated.interactions
          .concat(action.interaction)
          .map((i) => moment.unix(i.last_message_timestamp))
        updated.last_sent = moment.max(interactionDates)
      }

      rest.splice(index, 0, updated)

      return [...rest]
    } else {
      return [...people]
    }
  } else {
    return [...people]
  }
}

const updatePersonReminders = (state, action) => {
  if (action.reminder.people.length > 0) {
    let updated = state.people.find((person) => {
      return +person.id === +action.reminder.people[0].id
    })
    const index = state.people.indexOf(updated)
    const rest = state.people.filter(
      (person) => +person.id !== +action.reminder.people[0].id
    )

    if (updated) {
      if (action.type === "ADD_REMINDER_SUCCESS") {
        if (updated.reminders) {
          updated.reminders = _.orderBy(
            [action.reminder, ...updated.reminders],
            ["status"],
            ["desc"]
          )
        } else {
          updated.reminders = _.orderBy([action.reminder], ["status"], ["desc"])
        }
      } else if (action.type === "UPDATE_REMINDER_SUCCESS") {
        updated.reminders = updated.reminders
          ? updated.reminders.map((reminder) => {
              if (reminder.id === action.reminder.id) {
                return action.reminder
              } else {
                return reminder
              }
            })
          : []
      } else if (
        action.type === "DELETE_REMINDER_SUCCESS" ||
        action.type === "COMPLETE_REMINDER_SUCCESS"
      ) {
        updated.reminders = updated.reminders
          ? updated.reminders.filter(
              (reminder) => reminder.id !== action.reminder.id
            )
          : []
      }

      rest.splice(index, 0, updated)

      return [...rest]
    } else {
      return [...state.people]
    }
  } else {
    return [...state.people]
  }
}

const fillContactInfo = (state, action) => {
  let updated = state.people.find((person) => {
    return +person.id === +action.person.id
  })

  if (updated) {
    const index = state.people.indexOf(updated)
    const rest = state.people.filter((person) => +person.id !== +action.person.id)
    rest.splice(index, 0, {
      ...action.person,
      interactions: action.person.interactions,
      notes: action.person.notes,
    })

    return [...rest]
  } else {
    return [...state.people.concat(action.person)]
  }
}

const updateContactNotes = (state, action) => {
  let person = state.people.find((p) => +p.id === +action.person_id)

  if (person) {
    const index = state.people.indexOf(person)
    const rest = state.people.filter((p) => +p.id !== +action.person_id)
    rest.splice(index, 0, {
      ...person,
      notes: action.notes,
      notes_total_entries: action.total_entries,
    })
    return [...rest]
  }
}

const updateContactReminders = (state, action) => {
  let person = state.people.find((p) => +p.id === +action.person_id)

  if (person) {
    const index = state.people.indexOf(person)
    const rest = state.people.filter((p) => +p.id !== +action.person_id)
    rest.splice(index, 0, {
      ...person,
      reminders: action.reminders,
      reminders_total_entries: action.total_entries,
    })

    return [...rest]
  }
}

const updateContactInteractions = (state, action) => {
  let person = state.people.find((p) => +p.id === +action.person_id)

  if (person) {
    const index = state.people.indexOf(person)
    const rest = state.people.filter((p) => +p.id !== +action.person_id)
    rest.splice(index, 0, {
      ...person,
      interactions: action.interactions,
      interactions_total_entries: action.total_entries,
      interactions_page_token: action.page_token,
    })

    return [...rest]
  } else {
    return [...state.people]
  }
}

const updateContactEvents = (state, action) => {
  let person = state.people.find((p) => +p.id === +action.person_id)

  if (person) {
    const index = state.people.indexOf(person)
    const rest = state.people.filter((p) => +p.id !== +action.person_id)
    rest.splice(index, 0, {
      ...person,
      events: action.events,
      events_total_entries: action.total_entries,
      events_page_token: action.page_token,
    })

    return [...rest]
  } else {
    return [...state.people]
  }
}

export const peopleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PEOPLE:
      return {
        ...state,
        fetching: true,
      }
    case FETCH_PEOPLE_SUCCESS:
      return {
        ...state,
        people:
          state.people && !action.clearStore
            ? _.uniqBy(state.people.concat(action.people), "id")
            : [...action.people],
        queriedPeople: action.people,
        enabled_columns: action.enabled_columns,
        total_entries: action.total_entries,
        fetching: false,
      }
    case FETCH_PEOPLE_FAIL:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    case FETCH_SELECTED_PEOPLE:
      return {
        ...state,
        fetching_selected: true,
      }
    case FETCH_SELECTED_PEOPLE_SUCCESS:
      return {
        ...state,
        fetching_selected: false,
      }
    case FETCH_SELECTED_PEOPLE_FAIL:
      return {
        ...state,
        error: action.error,
        fetching_selected: false,
      }
    case FETCH_PEOPLE_FROM_ALL_PAGES:
      return {
        ...state,
        fetching: true,
      }
    case FETCH_PEOPLE_FROM_ALL_PAGES_SUCCESS:
      return {
        ...state,
        fetching: false,
      }
    case FETCH_PEOPLE_FROM_ALL_PAGES_FAIL:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    case FETCH_PERSON:
      return {
        ...state,
        personLoading: true,
      }
    case FETCH_PERSONS_INTERACTIONS_SUCCESS:
      return {
        ...state,
        people: updateContactInteractions(state, action),
      }
    case FETCH_PERSONS_EVENTS_SUCCESS:
      return {
        ...state,
        people: updateContactEvents(state, action),
      }
    case FETCH_PERSONS_NOTES_SUCCESS:
      return {
        ...state,
        people: updateContactNotes(state, action),
      }
    case FETCH_PERSONS_REMINDERS_SUCCESS:
      return {
        ...state,
        people: updateContactReminders(state, action),
      }
    case FETCH_PERSON_SUCCESS:
      return {
        ...state,
        people: fillContactInfo(state, action),
        queriedPeople: state.queriedPeople.map((person) => {
          if (person.id === action.person.id) {
            return action.person
          } else {
            return person
          }
        }),
        personLoading: false,
      }
    case FETCH_PERSON_FAIL:
      return {
        ...state,
        personLoading: false,
      }
    case ADD_TO_SPHERES_SUCCESS:
      return {
        ...state,
        people: [...updatePerson(state.people, action)],
        queriedPeople: [...updatePerson(state.queriedPeople, action)],
      }
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        people: [action.person, ...state.people],
        queriedPeople: [action.person, ...state.queriedPeople],
      }
    case EDIT_CONTACT:
      return {
        ...state,
        fetching: true,
      }
    case UPDATE_PERSON_SPHERES_SUCCESS:
      return {
        ...state,
        people: [...updatePerson(state.people, action)],
        queriedPeople: [...updatePerson(state.queriedPeople, action)],
        fetching: false,
      }
    case UPDATE_PERSON_SPHERES_FAIL:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    case EDIT_CONTACT_SUCCESS:
      return {
        ...state,
        people: [...updatePerson(state.people, action)],
        queriedPeople: [...updatePerson(state.queriedPeople, action)],
        fetching: false,
      }
    case EDIT_CONTACT_FAIL:
      return {
        ...state,
        error: action.error,
        fetching: false,
      }
    case DELETE_CONTACT:
      return {
        ...state,
        fetching: true,
      }
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        people: [...state.people.filter((person) => person.id !== action.personId)],
        queriedPeople: [
          ...state.queriedPeople.filter((person) => person.id !== action.personId),
        ],
        fetching: false,
      }
    case DELETE_CONTACT_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case DELETE_CONTACTS:
      return {
        ...state,
        archiving: true,
      }
    case DELETE_CONTACTS_SUCCESS:
      return {
        ...state,
        people: [
          ...state.people.filter(
            (person) => !action.people.map((p) => p.id).includes(person.id)
          ),
        ],
        queriedPeople: [
          ...state.queriedPeople.filter(
            (person) => !action.people.map((p) => p.id).includes(person.id)
          ),
        ],
        archiving: false,
      }
    case DELETE_CONTACTS_FAIL:
      return {
        ...state,
        archiving: false,
      }
    case EDIT_CONTACTS:
      return {
        ...state,
        editing: true,
      }
    case EDIT_CONTACTS_SUCCESS:
      return {
        ...state,
        people: [...updatePeople(state.people, action)],
        queriedPeople: [...updatePeople(state.queriedPeople, action)],
        editing: false,
      }
    case EDIT_CONTACTS_FAIL:
      return {
        ...state,
        error: action.error,
        editing: false,
      }
    case UPDATE_CONTACT_IMAGE:
      return {
        ...state,
        updatingImage: true,
        fetching: true,
      }
    case UPDATE_CONTACT_IMAGE_SUCCESS:
      return {
        ...state,
        people: [...updatePerson(state.people, action)],
        queriedPeople: [...updatePerson(state.queriedPeople, action)],
        updatingImage: false,
        fetching: false,
      }
    case UPDATE_CONTACT_IMAGE_FAIL:
      return {
        ...state,
        updatingImage: false,
        fetching: false,
      }
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        people: updatePersonNotes(state, action),
      }
    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        people: updatePersonNotes(state, action),
      }
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        people: deletePersonInteraction(state, action),
      }
    case ADD_INTERACTION_SUCCESS:
      return {
        ...state,
        people: updatePersonInteractions(state.people, action),
        queriedPeople: updatePersonInteractions(state.queriedPeople, action),
      }
    case ADD_REMINDER_SUCCESS:
      return {
        ...state,
        people: [...updatePersonReminders(state, action)],
      }
    case COMPLETE_REMINDER_SUCCESS:
      return {
        ...state,
        people: [...updatePersonReminders(state, action)],
      }
    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        people: [...updatePersonReminders(state, action)],
      }
    case MERGE_CONTACTS:
      return {
        ...state,
        merging: true,
      }
    case MERGE_CONTACTS_SUCCESS:
      return {
        ...state,
        merging: false,
      }
    case MERGE_CONTACTS_FAIL:
      return {
        ...state,
        merging: false,
      }
    case UNARCHIVE_CONTACT_SUCCESS:
      return {
        ...state,
        people: [...updatePerson(state.people, action)],
        queriedPeople: [...updatePerson(state.queriedPeople, action)],
      }
    case UNARCHIVE_CONTACT_FAIL:
      return {
        ...state,
      }
    case DELETE_INTERACTION_SUCCESS:
      return {
        ...state,
        people: deletePersonInteraction(state, action, "interactions"),
      }
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        people: deletePersonInteraction(state, action, "events"),
      }
    case FETCH_DUPLICATES:
      return {
        ...state,
        loading: true,
      }
    case FETCH_DUPLICATES_SUCCESS:
      return {
        ...state,
        suggested_duplicates: action.duplicates,
        duplicatesTotalEntries: action.total_entries,
        suggested_duplicates_loading: false,
        loading: false,
      }
    case APPROVE_DUPLICATE:
      return {
        ...state,
        suggested_duplicates_loading: true,
      }
    case IGNORE_DUPLICATE:
      return {
        ...state,
        suggested_duplicates_loading: true,
      }
    case APPROVE_DUPLICATE_SUCCESS:
      return {
        ...state,
        suggested_duplicates: state.suggested_duplicates.filter(
          (dup) => dup.id !== action.duplicate_id
        ),
      }
    case IGNORE_DUPLICATE_SUCCESS:
      return {
        ...state,
        suggested_duplicates: state.suggested_duplicates.filter(
          (dup) => dup.id !== action.duplicate_id
        ),
      }
    case FETCH_ENRICHMENT_COUNT_SUCCESS:
      return {
        ...state,
        enrichments_total_entries: action.total_entries,
      }
    case FETCH_ENRICHMENTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ENRICHMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        enrichments: action.enrichments,
        enrichments_total_entries: action.total_entries,
      }
    case FETCH_ENRICHMENTS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case APPROVE_ENRICHMENT:
      return {
        ...state,
        loading: true,
      }
    case APPROVE_ENRICHMENT_FAIL:
      return {
        ...state,
        loading: false,
      }
    case IGNORE_ENRICHMENT:
      return {
        ...state,
        loading: true,
      }
    case IGNORE_ENRICHMENT_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_ENABLED_COLUMNS:
      return {
        ...state,
        fetching: true,
      }
    case UPDATE_ENABLED_COLUMNS_SUCCESS:
      return {
        ...state,
        enabled_columns: action.enabled_columns,
        fetching: false,
      }
    case UPDATE_ENABLED_COLUMNS_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        people: [...updatePersonReminders(state, action)],
      }
    case SET_FETCHING_STATE:
      return {
        ...state,
        fetching: action.fetching,
      }
    case CLONE_TEAM_SHARED_PERSON_TO_MY_DB:
      return {
        ...state,
        cloningContact: true,
      }
    case CLONE_TEAM_SHARED_PERSON_TO_MY_DB_SUCCESS:
      return {
        ...state,
        people: [...updatePersonAfterClone(state.people, action)],
        queriedPeople: [...updatePersonAfterClone(state.queriedPeople, action)],
        cloningContact: false,
      }
    case CLONE_TEAM_SHARED_PERSON_TO_MY_DB_FAIL:
      return {
        ...state,
        cloningContact: false,
      }
    case UPDATE_TEAM_OWNERSHIP:
      return {
        ...state,
        fetching: true,
      }
    case UPDATE_TEAM_OWNERSHIP_SUCCESS:
      return {
        ...state,
        people: updateTeamOwnership(state.people, action),
        queriedPeople: updateTeamOwnership(state.queriedPeople, action),
        fetching: false,
      }
    case UPDATE_TEAM_OWNERSHIP_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_PEOPLE_STATE:
      return {}
    case SET_INITIAL_REDUX_PEOPLE_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
