import React, { useEffect, useState } from "react"
import axios from "axios"
import * as _ from "lodash"
import "./Import.styles.scss"
import { useLocation } from "react-router"
import {
  Tooltip,
  Button,
  Checkbox,
  Collapse,
  Form,
  Modal,
  notification,
  Select,
  Table,
} from "antd"
import Icon, { RightOutlined, StopOutlined } from "@ant-design/icons"
import CollectionSelector from "../../../components/Database/CollectionSelector/CollectionSelector.component"
import history from "../../../history"
import {
  fetchExpertises,
  fetchSpheres,
  fetchTopics,
} from "../../../redux/Collections/Collections.actions"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import RelatableLoader from "../../../components/UI/RelatableLoader/RelatableLoader.component"
import "../../../styles/RelatableModal.styles.scss"

const { Panel } = Collapse

// Sphere Warning Modal Component
const SphereWarningModal = ({ visible, onCancel, onContinue }) => {
  return (
    <Modal
      closable={false}
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onCancel}
      centered={true}
      width={400}
      className={"RelatableModal__modal"}
    >
      <div className="RelatableModal">
        <div className={"RelatableModal_Text"}>
          <span className={"RelatableModal_Text_Title"}>Importing Contacts</span>
          <span className={"RelatableModal_Text_Description"}>
            It doesn't look like you are importing these contacts into Spheres.
            <br />
            <br />
            Spheres are our primary way of organizing contacts.
            <br />
            <br />
            We recommend selecting{" "}
            <strong>"Put everyone into the following sphere(s)"</strong> and choosing
            a Sphere or two, or mapping a column in your CSV to Spheres.
          </span>
        </div>

        <Collapse bordered={false} className={"RelatableModal_Configure"}>
          <Panel
            key={1}
            expandIcon={({ isActive }) => (
              <RightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="RelatableModal_Configure_Panel"
            header={
              <span className="RelatableModal_Configure_Header">
                What happens if I don't map spheres?
              </span>
            }
          >
            <div className="RelatableModal_Configure_Content">
              <p>
                If you don't map spheres, we'll put everyone into the "Uncategorized"
                table. You'll be able to manually add spheres later.
              </p>
            </div>
          </Panel>
        </Collapse>

        <div className={"RelatableModal_Buttons"}>
          <div
            className="RelatableModal_PrimaryButton"
            onClick={() => {
              onContinue()
              onCancel()
            }}
          >
            Continue Anyway
          </div>
          <div className="RelatableModal_SecondaryButton" onClick={onCancel}>
            Go Back
          </div>
        </div>
      </div>
    </Modal>
  )
}

const ImportPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [parsing, setParsing] = useState(false)
  const [firstRowAsHeader, setFirstRowAsHeader] = useState(false)
  const [rows, setRows] = useState([])
  const [mapping, setMapping] = useState([])
  const [spheres, setSpheres] = useState(null)
  const [topics, setTopics] = useState(null)
  const [expertises, setExpertises] = useState(null)
  const [importId, setImportId] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [showSphereWarning, setShowSphereWarning] = useState(false)
  const params = useParams()

  const formatCell = (index, record) => {
    const mapped = mapping.find((col) => +col.id === +index)
    const labeled_values = [
      "email_addresses",
      "phone_numbers",
      "addresses",
      "profile_fields",
      "special_dates",
      "links",
    ]
    const collection_values = ["spheres", "topics", "expertises", "companies"]

    if (mapped && labeled_values.includes(mapped.value)) {
      return (
        <div>
          {formatLabeled(record, mapped.value).map((v) => (
            <>
              <span className={"ImportPage_Table_Cell-value"}>{v.value}</span>
              {v.name.length > 0 && (
                <span className={"ImportPage_Table_Cell-label"}>({v.name})</span>
              )}
              <br />
            </>
          ))}
        </div>
      )
    } else if (mapped && collection_values.includes(mapped.value)) {
      return (
        <>
          {formatCollections(record).map((v) => (
            <span className={"ImportPage_Table_Cell-sphere"}>{v.title}</span>
          ))}
        </>
      )
    } else {
      if (typeof record === "string") {
        return record
      } else {
        return null
      }
    }
  }

  const previewCSV = async () => {
    const id = location?.state?.id || params.id
    setImportId(id)
    const { data } = await axios.request({
      url: `/v1/people/import/${id}`,
    })
    const fileName = location?.state?.name || data.filename
    setFileName(fileName)
    setRows(data.preview)
  }

  useEffect(() => {
    previewCSV()

    if (!topics) {
      dispatch(fetchTopics())
    }
    if (!expertises) {
      dispatch(fetchExpertises())
    }
    if (!spheres) {
      dispatch(fetchSpheres())
    }
    // eslint-disable-next-line
  }, [])

  // Add a new useEffect to automatically detect headers when rows are loaded
  useEffect(() => {
    if (rows && rows.length > 0) {
      const firstRowIsHeader = determineIfFirstRowIsHeader()
      guessMapping(firstRowIsHeader)
    }
    // eslint-disable-next-line
  }, [rows])

  const guessMapping = (firstRowIsHeader) => {
    if (firstRowIsHeader) {
      const firstRow = rows[0]
      const newMapping = []

      // Map of keywords to column types
      const columnMappings = {
        // Names
        first: "first_name",
        firstname: "first_name",
        "first name": "first_name",
        last: "last_name",
        lastname: "last_name",
        "last name": "last_name",
        fullname: "full_name",
        "full name": "full_name",
        name: "full_name",

        // Contact info
        email: "email_addresses",
        mail: "email_addresses",
        phone: "phone_numbers",
        mobile: "phone_numbers",
        cell: "phone_numbers",
        telephone: "phone_numbers",
        address: "addresses",
        street: "addresses",
        city: "addresses",
        state: "addresses",
        country: "addresses",
        zip: "addresses",
        location: "location",

        // Collections
        company: "companies",
        organization: "companies",
        employer: "companies",
        sphere: "spheres",
        topic: "topics",
        tags: "topics",
        expertise: "expertises",
        skill: "expertises",

        // Other
        bio: "one_liner",
        headline: "one_liner",
        about: "one_liner",
        summary: "one_liner",
        birthday: "birthday",
        "birth date": "birthday",
        link: "links",
        url: "links",
        website: "links",
        "web site": "links",
        "web-site": "links",
        profile: "profile_fields",
        date: "special_dates",
        "job title": "profile_fields",
        title: "profile_fields",
        position: "profile_fields",
        role: "profile_fields",
      }

      // Check each column header
      firstRow.forEach((header, index) => {
        if (typeof header === "string") {
          const headerLower = header.toLowerCase().trim()

          // Find matching column type
          for (const [keyword, columnType] of Object.entries(columnMappings)) {
            if (headerLower === keyword || headerLower.includes(keyword)) {
              newMapping.push({ id: index, value: columnType })
              break
            }
          }
        }
      })

      // Only update if we found mappings
      if (newMapping.length > 0) {
        setMapping(newMapping)
      }
    }
  }

  const determineIfFirstRowIsHeader = () => {
    // If rows exist and there's at least one row
    if (rows && rows.length > 0) {
      // Check if the first row contains common header keywords
      const firstRow = rows[0]
      const headerKeywords = [
        "name",
        "email",
        "phone",
        "address",
        "company",
        "title",
        "date",
        "location",
      ]

      // Check if any cell in the first row contains a header keyword
      const containsHeaderKeywords = firstRow.some((cell) => {
        if (typeof cell === "string") {
          const lowerCell = cell.toLowerCase()
          return headerKeywords.some((keyword) => lowerCell.includes(keyword))
        }
        return false
      })

      if (containsHeaderKeywords) {
        // Automatically set firstRowAsHeader to true if keywords are found
        setFirstRowAsHeader(true)
        return true
      }
    }

    return firstRowAsHeader
  }

  const mapColumn = (index, value) => {
    const newMapping = _.reverse(
      _.uniqBy(_.reverse([...mapping, { id: index, value: value }]), "id")
    )
    setMapping(newMapping)
  }

  const renderDefaultHeader = (rows) => {
    let head = []

    head.push({
      title: () => {
        return (
          <div className={"ImportPage_ResetButton-container"}>
            <Tooltip title="Reset all column mappings">
              <Button
                className={"ImportPage_ResetButton"}
                onClick={resetMapping}
                type="link"
              >
                <Icon component={StopOutlined} />
              </Button>
            </Tooltip>
          </div>
        )
      },
      dataIndex: "header",
      key: "header",
      className: "ImportPage_ResetButton-container",
    })

    for (let i = 0; i < rows[0].length; i++) {
      head.push({
        title: () => {
          return (
            <div className={"ImportPage_Table_Header-container"}>
              <Form.Item validateStatus="success" help={[]}>
                <Select
                  onClear={() => {
                    const newMapping = mapping.filter((m) => m.id !== i)
                    setMapping(newMapping)
                  }}
                  value={mapping.find((m) => m.id === i)?.value}
                  allowClear={true}
                  onSelect={(value) => mapColumn(i, value)}
                  placeholder={"Import as..."}
                  className={"ImportPage_Table_Header-select"}
                >
                  <Select.Option label={"First name"} value={"first_name"}>
                    First name
                  </Select.Option>
                  <Select.Option label={"Last name"} value={"last_name"}>
                    Last name
                  </Select.Option>
                  <Select.Option label={"Full Name"} value={"full_name"}>
                    Full Name
                  </Select.Option>
                  <Select.Option label={"One liner"} value={"one_liner"}>
                    One liner
                  </Select.Option>
                  <Select.Option label={"Location"} value={"location"}>
                    Location
                  </Select.Option>
                  <Select.Option label={"Profile"} value={"profile_fields"}>
                    Profile Fields
                  </Select.Option>
                  <Select.Option label={"Links"} value={"links"}>
                    Links
                  </Select.Option>
                  <Select.Option label={"Birthday"} value={"birthday"}>
                    Birthday
                  </Select.Option>
                  <Select.Option label={"Special Dates"} value={"special_dates"}>
                    Special Dates
                  </Select.Option>
                  <Select.Option label={"Email"} value={"email_addresses"}>
                    Email Addresses
                  </Select.Option>
                  <Select.Option label={"Phone"} value={"phone_numbers"}>
                    Phone Numbers
                  </Select.Option>
                  <Select.Option label={"Addresses"} value={"addresses"}>
                    Addresses
                  </Select.Option>
                  <Select.Option label={"Spheres"} value={"spheres"}>
                    Spheres
                  </Select.Option>
                  <Select.Option label={"Topics"} value={"topics"}>
                    Topics
                  </Select.Option>
                  <Select.Option label={"Expertise"} value={"expertises"}>
                    Expertise
                  </Select.Option>
                  <Select.Option label={"Companies"} value={"companies"}>
                    Companies
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          )
        },
        dataIndex: i,
        key: i,
        className: "ImportPage_Table_Cell",
        render: (record) => formatCell(i, record),
      })
    }

    return head
  }

  const formatLinks = (v) => {
    const splitted = v.split(":")
    let label = null
    let record = null

    if (splitted.length >= 2) {
      label =
        splitted[0].includes("http") || splitted[0].includes("https")
          ? ""
          : splitted[0]

      splitted.every((s, index) => {
        if (s.includes("https")) {
          record = "https:" + splitted[index + 1]
          return false
        } else if (s.includes("http")) {
          record = "http:" + splitted[index + 1]
          return false
        } else {
          record = splitted[1]
          return true
        }
      })

      return { name: label.trim(), value: record.trim() }
    } else {
      record = splitted[0]
      return { name: "", value: record.trim() }
    }
  }

  const formatLabeled = (value, mapped_label) => {
    if (value) {
      const singular = value.split(",").map((v) => {
        if (mapped_label === "links") {
          return formatLinks(v)
        }
        const splitted = v.split(":")
        let label = null
        let record = null

        if (splitted.length === 2) {
          label = splitted[0]
          record = splitted[1]
          return { name: label.trim(), value: record.trim() }
        } else {
          record = splitted[0]
          return { name: "", value: record.trim() }
        }
      })
      return singular
    } else {
      return [{ name: "", value: "" }]
    }
  }
  const formatCollections = (value) => {
    if (value) {
      const singular = value.split(",")

      return singular.map((collection) => ({ title: collection.trim() }))
    } else {
      return []
    }
  }

  const [confirmImport, setConfirmImport] = useState(null)

  const resetMapping = () => {
    setMapping([])
  }

  const checkImport = () => {
    // Check if no spheres are selected and no columns are mapped to spheres
    if (
      (!spheres || spheres.length === 0) &&
      !mapping.some((m) => m.value === "spheres")
    ) {
      // Show warning popup
      setShowSphereWarning(true)
    } else {
      importCSV()
    }
  }

  const importCSV = async () => {
    setParsing(true)
    const { data } = await axios.request({
      url: "/v1/people/import/confirm",
      data: {
        import_id: importId,
        spheres: spheres,
        topics: topics,
        expertises: expertises,
        mapping: mapping,
        first_row_as_header: firstRowAsHeader,
      },
      method: "POST",
    })
    setParsing(false)
    if (data.success) {
      notification.success({
        message: "Import CSV",
        description:
          "Successfully queued contacts for import. This may take some time depending on the file size.",
        className: "Notification-success",
      })
      setTimeout(() => {
        history.push("/database")
      }, 500)
    } else {
      notification.error({
        message: "Import CSV",
        description:
          data.error ||
          "Something went wrong. Please try again or contact us if the problem persists.",
        className: "Notification-error",
      })
    }
  }

  return (
    rows.length > 0 && (
      <div className={"ImportPage"}>
        <SphereWarningModal
          visible={showSphereWarning}
          onCancel={() => setShowSphereWarning(false)}
          onContinue={() => {
            setShowSphereWarning(false)
            importCSV()
          }}
        />
        <div className={"ImportPage_Header-container"}>
          <h2>
            Importing File <strong>{fileName}</strong>
          </h2>
          <div className={"ImportPage_HeaderCheckbox-container"}>
            <Checkbox
              className={"ImportPage_HeaderCheckbox-checkbox"}
              checked={firstRowAsHeader}
              onChange={(e) => setFirstRowAsHeader(e.target.checked)}
            >
              The first line of this file is the header
            </Checkbox>
            <span className={"ImportPage_HeaderCheckbox-label"}>
              If so, we won't import the first line in the file. This is common.{" "}
            </span>
          </div>
          <div className={"ImportPage_TopicsSelector-container"}>
            <span className={"ImportPage_TopicsSelector-label"}>
              Tag all imported people as...
            </span>
            <div style={{ flex: 1 }}>
              <CollectionSelector
                collectionType={"topics"}
                handleSubmit={(existingTopics, newTopics) => {
                  setTopics([
                    ...existingTopics,
                    ...newTopics.map((title) => ({ title: title })),
                  ])
                }}
              />
            </div>
          </div>
          <div className={"ImportPage_ExpertisesSelector-container"}>
            <span className={"ImportPage_ExpertisesSelector-label"}>
              Add all imported people to...
            </span>
            <div style={{ flex: 1 }}>
              <CollectionSelector
                collectionType={"expertises"}
                handleSubmit={(existingExpertises, newExpertises) => {
                  setExpertises([
                    ...existingExpertises,
                    ...newExpertises.map((title) => ({ title: title })),
                  ])
                }}
              />
            </div>
          </div>
          <div className={"ImportPage_SpheresSelector-container"}>
            <span className={"ImportPage_SpheresSelector-label"}>
              Put everyone into the following sphere(s):
            </span>
            <div style={{ flex: 1 }}>
              <CollectionSelector
                collectionType={"spheres"}
                handleSubmit={(existingSpheres, newSpheres) => {
                  setSpheres([
                    ...existingSpheres,
                    ...newSpheres.map((title) => ({ title: title })),
                  ])
                }}
              />
            </div>
          </div>
        </div>
        <div className={"ImportPage_Table-container"}>
          <Table
            className={"ImportPage_Table"}
            rowClassName={"ImportPage_Table_Row"}
            loading={{
              spinning: parsing,
              indicator: <RelatableLoader quote={true} loading={parsing} />,
            }}
            pagination={false}
            columns={renderDefaultHeader(rows)}
            dataSource={rows}
            scroll={{ x: true }}
          />
        </div>
        <div className={"ImportPage_SubmitButton-container"}>
          <span className={"ImportPage_SubmitButton-label"}>
            Review before importing, as this cannot be undone!
          </span>
          <Button
            disabled={
              !mapping ||
              (mapping &&
                !mapping.some(
                  (m) =>
                    m.value === "email_addresses" ||
                    m.value === "first_name" ||
                    m.value === "last_name"
                ))
            }
            className={"ImportPage_SubmitButton-button"}
            onClick={() => checkImport()}
          >
            Import
          </Button>
        </div>
      </div>
    )
  )
}

export default ImportPage
