import React from "react"
import "./OnboardingScreens.styles.scss"
import { Drawer } from "antd"
import { nylasAuthenticationURI } from "../../../config/nylas"
import axios from "axios"
import { useHistory } from "react-router"
import OnboardingCal from "./OnboardingEWebinar/OnboardingEWebinar.component"
import { ReactComponent as ConquerPeak } from "../../../images/ConquerPeak.svg"
import { ReactComponent as SurpriseEmail } from "../../../images/SurpriseEmail.svg"
import { ReactComponent as ContactCard } from "../../../images/ContactCard.svg"
import { ReactComponent as PeopleWithMagnifyingGlass2 } from "../../../images/PeopleWithMagnifyingGlass2.svg"
import { ReactComponent as Timer } from "../../../images/Timer.svg"
import { useDispatch, useSelector } from "react-redux"
import { hideOnboardingFlow } from "../../../redux/User/User.actions"

const OnboardingScreens = ({ defaultStep = "0" }) => {
  const user = useSelector((state) => state.UserState)
  const history = useHistory()

  const dispatch = useDispatch()

  const connectAccount = () => {
    nylasAuthenticationURI(null, null, (url) => {
      window.location.replace(url)
    })
  }

  const updateChoices = async (choices) => {
    await axios.request({
      url: "/v1/users/update_choices",
      data: {
        choices: choices,
      },
      method: "PATCH",
    })

    history.push("/dashboard?onboarding_step=5")
  }

  const options = [
    {
      label: "I’m looking to generate more repeat and referral business",
      value: ["i_m_looking_to_generate_more_repeat_and_referral_business"],
    },
    {
      label: "I need to do a lot of business development",
      value: ["i_need_to_do_a_lot_of_business_development"],
    },
    {
      label: "My network is really important to me",
      value: ["my_network_is_really_important_to_me"],
    },
    {
      label: "Honestly, all of the above!",
      value: [
        "i_m_looking_to_generate_more_repeat_and_referral_business",
        "i_need_to_do_a_lot_of_business_development",
        "my_network_is_really_important_to_me",
      ],
    },
  ]

  const renderContent = () => {
    switch (defaultStep) {
      case "0":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>Hello {user?.first_name ? user.first_name : ""}!</h1>
              <h1>Welcome to Relatable.</h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <ConquerPeak className="OnboardingScreens_Step_Body_Image" />
              <p>Relatable is here to help you do one thing, really well.</p>
              <p>
                <b>Nurture the relationships that matter.</b>
              </p>
            </div>
            <div className="OnboardingScreens_Step_Footer">
              <button onClick={() => history.push("/dashboard?onboarding_step=1")}>
                Let's do it!
              </button>
            </div>
          </>
        )
      case "1":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>Here’s the real simple overview of Relatable.</h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <SurpriseEmail className="OnboardingScreens_Step_Body_Image" />
              <p>
                It can be hard to keep track of everyone you know, so Relatable make
                it really easy.
              </p>
              <p>First thing is we’ll help you bring in all of your contacts.</p>
            </div>
            <div className="OnboardingScreens_Step_Footer">
              <button onClick={() => history.push("/dashboard?onboarding_step=2")}>
                Sounds good. Then what?
              </button>
            </div>
          </>
        )
      case "2":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>Let’s get your first account connected.</h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <ContactCard className="OnboardingScreens_Step_Body_Image" />
              <p>
                Connecting an email account will also bring in any connected
                calendars and address books. Win!
              </p>
              <p>
                Once you’ve made the first connection, then you can add additional
                email accounts, spreadsheets, even LinkedIn.
              </p>
            </div>
            <div className="OnboardingScreens_Step_Footer">
              <button onClick={connectAccount}>Let’s get connected!</button>
              <button
                className="OnboardingScreens_Step_Footer_DismissButton"
                onClick={() => history.push("/dashboard?onboarding_step=3")}
              >
                I'll do this later
              </button>
            </div>
          </>
        )
      case "3":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>Then, we’ll help you get organized.</h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <PeopleWithMagnifyingGlass2 className="OnboardingScreens_Step_Body_Image" />
              <p>
                You don’t need to stay in touch with everyone, right? So Relatable
                makes it really simple to organize people into spheres.
              </p>
              <p>
                Spheres are like folders of contacts. But they do a lot more than
                folders.
              </p>
            </div>
            <div className="OnboardingScreens_Step_Footer">
              <button onClick={() => history.push("/dashboard?onboarding_step=4")}>
                OK cool, and...
              </button>
            </div>
          </>
        )
      case "4":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>What brings you to Relatable today? </h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <p>We’ll help customize your Relatable experience for this.</p>
              <div className="OnboardingScreens_Step_Body_Choices">
                {options.map((o) => (
                  <button key={o.value} onClick={() => updateChoices(o.value)}>
                    {o.label}
                  </button>
                ))}
              </div>
            </div>
          </>
        )
      case "5":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>We’ll help keep track of who you need to stay in touch with.</h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <Timer className="OnboardingScreens_Step_Body_Image" />
              <p>
                You can barely remember what you had for breakfast, let alone who you
                should talk to.
              </p>
              <br />
              <p>
                Luckily Relatable is there. Every day, we’ll give you suggestions on
                who to reach out to, if it’s been too long, when it’s their birthday
                or anniversary, or they changed jobs.
              </p>
            </div>
            <div className="OnboardingScreens_Step_Footer">
              <button onClick={() => history.push("/dashboard?onboarding_step=6")}>
                OK cool, and...
              </button>
            </div>
          </>
        )
      case "6":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>And we’ll help you stay in touch. </h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <Timer className="OnboardingScreens_Step_Body_Image" />
              <p>
                It’s all about staying in touch. You can call or text right from our
                web or mobile app, or quickly note that you messaged them.
              </p>
              <br />
              <p>
                You can also send an email directly from inside Relatable, using our
                amazing <b>Split Message</b> and <b>AI Autodrafting</b>.
              </p>
            </div>
            <div className="OnboardingScreens_Step_Footer">
              <button onClick={() => history.push("/dashboard?onboarding_step=7")}>
                OK cool, and...
              </button>
            </div>
          </>
        )
      case "7":
        return (
          <>
            <div className="OnboardingScreens_Step_Header">
              <h1>While we’re bringing in your contacts, get to know Relatable</h1>
            </div>
            <div className="OnboardingScreens_Step_Body">
              <p>
                We host fantastic welcome webinars twice a day. Can we sign you up
                for one of them?
              </p>
              <OnboardingCal />
            </div>
            <div className="OnboardingScreens_Step_Footer">
              <button
                onClick={() => {
                  dispatch(hideOnboardingFlow())
                  history.push("/dashboard")
                }}
              >
                Great! Take me to my dashboard.
              </button>
            </div>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Drawer
      title={null}
      visible={true}
      closable={false}
      width="100vw"
      className="OnboardingScreens"
      style={{ zIndex: 5, opacity: 1, backgroundColor: "#E5E5E5" }}
      footer={null}
    >
      <div className="OnboardingScreens">
        <img
          className="OnboardingScreens_Logo"
          width={300}
          src={`${process.env.PUBLIC_URL}/primary.svg`}
          alt="Relatable"
        />
        <div className="OnboardingScreens_Step">
          {renderContent()}
          <div className="OnboardingScreens_StepNumberInfo">
            {[0, 1, 2, 3, 4, 5, 6, 7].map((step) => (
              <div
                key={step}
                className={
                  Number(defaultStep) >= step
                    ? "OnboardingScreens_StepNumberInfo_Step OnboardingScreens_StepNumberInfo_Step--active"
                    : "OnboardingScreens_StepNumberInfo_Step"
                }
              />
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default OnboardingScreens
