import { all, call, put } from "redux-saga/effects"
import {
  APPROVE_DUPLICATE,
  APPROVE_ENRICHMENT,
  CREATE_CONTACT,
  DELETE_CONTACT,
  DELETE_CONTACTS,
  EDIT_CONTACT,
  EDIT_CONTACTS,
  EXPORT_CONTACTS,
  FETCH_DUPLICATES,
  FETCH_DUPLICATES_COUNT,
  FETCH_ENRICHMENT_COUNT,
  FETCH_ENRICHMENTS,
  FETCH_PEOPLE,
  FETCH_PEOPLE_FROM_ALL_PAGES,
  FETCH_PERSON,
  FETCH_PERSONS_EVENTS,
  FETCH_PERSONS_INTERACTIONS,
  FETCH_PERSONS_NOTES,
  FETCH_PERSONS_REMINDERS,
  FETCH_SELECTED_PEOPLE,
  IGNORE_DUPLICATE,
  IGNORE_ENRICHMENT,
  MERGE_CONTACTS,
  SHOW_PEOPLE_BY_IDS,
  UNARCHIVE_CONTACT,
  UPDATE_CONTACT_IMAGE,
  UPDATE_ENABLED_COLUMNS,
  UPDATE_PERSON_SPHERES,
  CLONE_TEAM_SHARED_PERSON_TO_MY_DB,
  FETCH_PERSON_SUCCESS,
  UPDATE_TEAM_OWNERSHIP,
} from "./People.types"
import {
  addNotifyAbout1000Records,
  approveDuplicateFail,
  approveDuplicateSuccess,
  approveEnrichmentFail,
  approveEnrichmentSuccess,
  createContactFail,
  createContactSuccess,
  deleteContactFail,
  deleteContactsFail,
  deleteContactsSuccess,
  deleteContactSuccess,
  editContactFail,
  editContactsFail,
  editContactsSuccess,
  editContactSuccess,
  exportContactsFail,
  exportContactsSuccess,
  fetchDuplicatesAction,
  fetchDuplicatesCount,
  fetchDuplicatesFail,
  fetchDuplicatesSuccess,
  fetchEnrichmentCountFail,
  fetchEnrichmentCountSuccess,
  fetchEnrichments,
  fetchEnrichmentsFail,
  fetchEnrichmentsSuccess,
  fetchPeople,
  fetchPeopleFail,
  fetchPeopleFromAllPagesFail,
  fetchPeopleFromAllPagesSuccess,
  fetchPeopleSuccess,
  fetchPerson,
  fetchPersonFail,
  fetchPersonsEventsSuccess,
  fetchPersonsInteractionsSuccess,
  fetchPersonsNotesSuccess,
  fetchPersonsRemindersSuccess,
  fetchPersonSuccess,
  fetchSelectedPeopleFail,
  fetchSelectedPeopleSuccess,
  ignoreDuplicateFail,
  ignoreDuplicateSuccess,
  ignoreEnrichmentFail,
  ignoreEnrichmentSuccess,
  mergeContactsFail,
  mergeContactsSuccess,
  unarchiveContactFail,
  unarchiveContactSuccess,
  updateContactImageFail,
  updateContactImageSuccess,
  updateEnabledColumnsFail,
  updateEnabledColumnsSuccess,
  updatePersonSpheresFail,
  updatePersonSpheresSuccess,
  cloneTeamSharedPersonToMyDbSuccess,
  cloneTeamSharedPersonToMyDbFail,
  updateTeamOwnershipFail,
  updateTeamOwnershipSuccess,
} from "./People.actions"
import axios from "axios"
import {
  fetchSources,
  fetchSpheres,
  fetchTopics,
  fetchExpertises,
  fetchCompanies,
} from "../Collections/Collections.actions"
import {
  fillDataInSelectedContacts,
  resetSelectedContacts,
  selectContacts,
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
  setSelectedContactId,
  setSplitMessageSenderVisible,
} from "../App/App.actions"
import { takeLatest, take } from "@redux-saga/core/effects"
import _ from "lodash"
import { fetchActivities, fetchPoints } from "../Activities/Activities.actions"
import history from "../../history"

export function* watchPeopleSaga() {
  yield takeLatest(FETCH_PEOPLE, getPeople)
  yield takeLatest(FETCH_PEOPLE_FROM_ALL_PAGES, getPeopleFromAllPages)
  yield takeLatest(FETCH_SELECTED_PEOPLE, getSelectedPeople)
  yield takeLatest(FETCH_PERSON, getPerson)
  yield takeLatest(FETCH_PERSONS_INTERACTIONS, getPersonInteractions)
  yield takeLatest(FETCH_PERSONS_EVENTS, getPersonEvents)
  yield takeLatest(FETCH_PERSONS_NOTES, getPersonNotes)
  yield takeLatest(FETCH_PERSONS_REMINDERS, getPersonReminders)

  yield takeLatest(CREATE_CONTACT, createContact)
  yield takeLatest(EDIT_CONTACT, editContact)
  yield takeLatest(UPDATE_PERSON_SPHERES, updatePersonSpheres)
  yield takeLatest(DELETE_CONTACT, deleteContact)
  yield takeLatest(UPDATE_CONTACT_IMAGE, updateContactImage)
  yield takeLatest(DELETE_CONTACTS, deleteContacts)
  yield takeLatest(EDIT_CONTACTS, editContacts)
  yield takeLatest(MERGE_CONTACTS, mergeContacts)
  yield takeLatest(EXPORT_CONTACTS, exportContacts)
  yield takeLatest(UNARCHIVE_CONTACT, unarchiveContact)
  yield takeLatest(FETCH_DUPLICATES_COUNT, fetchDuplicatesIndex)
  yield takeLatest(FETCH_DUPLICATES, fetchDuplicates)
  yield takeLatest(FETCH_ENRICHMENTS, fetchPeopleEnrichments)
  yield takeLatest(APPROVE_DUPLICATE, approveDuplicate)
  yield takeLatest(IGNORE_DUPLICATE, ignoreDuplicate)
  yield takeLatest(FETCH_ENRICHMENT_COUNT, fetchEnrichmentIndex)
  yield takeLatest(APPROVE_ENRICHMENT, approveEnrichment)
  yield takeLatest(IGNORE_ENRICHMENT, ignoreEnrichment)

  yield takeLatest(SHOW_PEOPLE_BY_IDS, showPeopleByIds)
  yield takeLatest(UPDATE_ENABLED_COLUMNS, updateEnabledColumns)
  yield takeLatest(CLONE_TEAM_SHARED_PERSON_TO_MY_DB, cloneTeamSharedPersonToMyDb)
  yield takeLatest(UPDATE_TEAM_OWNERSHIP, updateTeamOwnershipSaga)
}

function* getPeople(action) {
  try {
    const clearStore =
      action.page === 1 &&
      (!action.query || _.isEmpty(action.query)) &&
      _.isEmpty(action.selectedPeople)

    const { data } = yield call(() =>
      axios.request({
        url: "/v1/people",
        params: {
          page: action.page,
          per_page: action.per_page,
          query: action.query,
          filters: action.filters,
          field: action.sortField,
          order: action.sortOrder,
        },
      })
    )

    yield put(
      fetchPeopleSuccess(
        data.people,
        data.total_entries,
        data.enabled_columns,
        clearStore
      )
    )
  } catch (error) {
    yield put(fetchPeopleFail(error))
  }
}

function* getPeopleFromAllPages(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/people/get_people_from_all_pages",
        method: "GET",
        params: {
          query: action.query,
          filters: action.filters,
        },
      })
    )
    yield put(selectContacts(data.people_from_all_pages))
    yield put(fetchPeopleFromAllPagesSuccess())
    if (data.people_from_all_pages.length === 10000) {
      yield put(addNotifyAbout1000Records())
    }
  } catch (error) {
    yield put(fetchPeopleFromAllPagesFail(error))
  }
}

function* getSelectedPeople(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/people/get_selected_people",
        method: "GET",
        params: {
          selected_ids: action.selected_ids,
        },
      })
    )
    yield put(fillDataInSelectedContacts(data.selected_people))
    yield put(fetchSelectedPeopleSuccess())
  } catch (error) {
    yield put(fetchSelectedPeopleFail(error))
  }
}

function* getPerson(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/person`,
        method: "GET",
        params: { id: action.id },
      })
    )
    yield put(fetchPersonSuccess(data.person))
  } catch (error) {
    yield put(fetchPersonFail(error))
    if (error.response.status === 404) {
      yield put(setVisibleContactID(null))
      yield call(() => history.push("/database/person/404"))
    }
  }
}

function* getPersonInteractions(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.person_id}/get_interactions`,
        params: {
          time_limit: action.time_limit,
        },
        method: "GET",
      })
    )
    yield put(
      fetchPersonsInteractionsSuccess(
        action.person_id,
        data.interactions,
        data.total_entries,
        data.page_token
      )
    )
  } catch (error) {
    yield put(fetchPersonFail(error))
  }
}

function* getPersonEvents(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.person_id}/get_events`,
        params: {
          time_limit: action.time_limit,
        },
        method: "GET",
      })
    )
    yield put(
      fetchPersonsEventsSuccess(action.person_id, data.events, data.total_entries)
    )
  } catch (error) {
    yield put(fetchPersonFail(error))
  }
}

function* getPersonNotes(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.person_id}/get_notes`,
        params: {
          page: action.page,
          per_page: action.per_page,
        },
        method: "GET",
      })
    )
    yield put(
      fetchPersonsNotesSuccess(action.person_id, data.notes, data.total_entries)
    )
  } catch (error) {
    yield put(fetchPersonFail(error))
  }
}

function* getPersonReminders(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.person_id}/get_reminders`,
        params: {
          page: action.page,
          per_page: action.per_page,
        },
        method: "GET",
      })
    )
    yield put(
      fetchPersonsRemindersSuccess(
        action.person_id,
        data.reminders,
        data.total_entries
      )
    )
  } catch (error) {
    yield put(fetchPersonFail(error))
  }
}

function* createContact(action) {
  try {
    const {
      data: { person },
    } = yield call(() =>
      axios.request({
        url: `/v1/people`,
        data: { ...action.values, source: "manual" },
        method: "POST",
      })
    )
    if (action.image) {
      const form_data = new FormData()
      form_data.append("profile_image", action.image, action.image.name)
      const { data } = yield call(() =>
        axios.request({
          url: `/v1/people/${person.id}`,
          data: form_data,
          method: "PATCH",
        })
      )
      yield all([
        put(createContactSuccess(data.person)),
        put(fetchActivities(1, 12)),
        put(fetchPoints()),
      ])
    } else {
      yield put(createContactSuccess(person))
    }
  } catch (error) {
    yield put(createContactFail(error))
  }
}

function* editContact(action) {
  try {
    if (action.related_people?.length) {
      const { data } = yield call(() =>
        axios.request({
          url: `/v1/people/canonical_update`,
          data: { ...action.values, people_ids: action.related_people },
          method: "PUT",
        })
      )
    }

    if (action.values.location) {
      yield put(setActiveTab(""))
      yield put(setContactDrawerMode("view"))
      const { data } = yield call(() =>
        axios.request({
          url: `/v1/people/${action.id}`,
          data: { ...action.values, token: action.sharing_token },
          method: "PUT",
        })
      )

      yield put(editContactSuccess(data.person))
    } else {
      yield put(setActiveTab(""))
      yield put(setContactDrawerMode("view"))
      const {
        data: { person },
      } = yield call(() =>
        axios.request({
          url: `/v1/people/${action.id}`,
          data: { ...action.values },
          method: "PUT",
        })
      )
      yield put(editContactSuccess(person))
    }
    yield put(fetchSpheres())
    yield put(fetchTopics())
    yield put(fetchSources())
  } catch (error) {
    yield put(editContactFail(error))
  }
}

function* updatePersonSpheres(action) {
  try {
    yield put(setActiveTab(""))
    yield put(setContactDrawerMode("view"))
    const {
      data: { person },
    } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.id}/update_person_spheres`,
        data: { ...action.values },
        method: "PUT",
      })
    )
    yield put(updatePersonSpheresSuccess(person))
    yield put(fetchSpheres())
  } catch (error) {
    yield put(updatePersonSpheresFail(error))
  }
}

function* editContacts(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/edit_multiple`,
        data: {
          contact_ids: action.contact_ids,
          values: { ...action.values },
        },
        method: "PUT",
      })
    )
    yield put(setContactDrawerVisible(false))
    yield put(setVisibleContactID(null))
    yield put(editContactsSuccess(data.people))
    yield put(fetchSpheres())
    yield put(fetchTopics())
    yield put(fetchSources())
    yield put(resetSelectedContacts())
  } catch (error) {
    yield put(editContactsFail(error))
  }
}

function* deleteContacts(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/destroy_multiple`,
        data: {
          contact_ids: action.contact_ids,
        },
        method: "DELETE",
      })
    )
    yield put(deleteContactsSuccess(data.people))
    yield put(resetSelectedContacts())
    yield put(fetchSpheres())
    yield put(setContactDrawerVisible(false))
    yield put(setVisibleContactID(null))
  } catch (error) {
    yield put(deleteContactsFail(error))
  }
}

function* mergeContacts(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/merge_multiple`,
        data: {
          contact_ids: action.contact_ids,
          main_contact_id: action.main_contact_id,
        },
        method: "POST",
      })
    )
    yield put(mergeContactsSuccess(data.merged_people, data.main))
    yield put(resetSelectedContacts())
    yield put(fetchDuplicatesCount())
    yield put(setContactDrawerVisible(false))
    yield put(setVisibleContactID(null))
    yield put(
      fetchPeople(action.page, 20, action.q, action.filters, action.by, action.order)
    )
  } catch (error) {
    yield put(mergeContactsFail(error))
  }
}

function* deleteContact(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(fetchSpheres())
    yield put(deleteContactSuccess(data.person.id))
  } catch (error) {
    yield put(deleteContactFail(error))
  }
}

function* updateContactImage(action) {
  try {
    const form_data = new FormData()
    form_data.append("profile_image", action.image, action.image.name)
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.id}.json`,
        data: form_data,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${form_data._boundary}`,
        },
        method: "PATCH",
      })
    )
    yield put(updateContactImageSuccess(data.person))
  } catch (error) {
    yield put(updateContactImageFail(error))
  }
}

function* exportContacts(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/people/export_contacts`,
        data: {
          all: action.all,
          spheres: action.spheres,
          contacts: action.contacts,
        },
        method: "POST",
      })
    )
    yield all([put(exportContactsSuccess())])
  } catch (error) {
    yield put(exportContactsFail(error))
  }
}

function* unarchiveContact(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/unarchive`,
        data: {
          id: action.contact_id,
        },
        method: "POST",
      })
    )
    yield all([put(unarchiveContactSuccess(data.person))])
  } catch (error) {
    yield put(unarchiveContactFail(error))
  }
}

function* fetchDuplicatesIndex() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/suggested_duplicates/index_count`,
        method: "GET",
      })
    )
    yield put(fetchDuplicatesSuccess([], data.total_entries))
  } catch (error) {
    yield put(fetchDuplicatesFail(error))
  }
}

function* fetchDuplicates(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/suggested_duplicates`,
        params: {
          page: action.page,
        },
        method: "GET",
      })
    )
    yield put(fetchDuplicatesSuccess(data.suggested_duplicates, data.total_entries))
  } catch (error) {
    yield put(fetchDuplicatesFail(error))
  }
}

function* ignoreDuplicate(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/suggested_duplicates/ignore`,
        params: {
          person_id_one: action.main,
          person_id_two: action.duplicate,
          participant_id: action.participant_id,
        },
        method: "POST",
      })
    )
    yield all([
      put(ignoreDuplicateSuccess(data.suggested_duplicate_id)),
      put(fetchDuplicatesAction(action.page)),
    ])
  } catch (error) {
    yield put(ignoreDuplicateFail(error))
  }
}

function* approveDuplicate(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/suggested_duplicates/approve`,
        params: {
          person_id_one: action.main,
          person_id_two: action.duplicate,
          participant_id: action.participant_id,
        },
        method: "POST",
      })
    )
    yield all([
      put(approveDuplicateSuccess(data.suggested_duplicate_id)),
      put(fetchDuplicatesAction(action.page)),
    ])
  } catch (error) {
    yield put(approveDuplicateFail(error))
  }
}

function* fetchPeopleEnrichments(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/enrichment_people`,
        params: {
          page: action.page,
        },
        method: "GET",
      })
    )
    yield put(fetchEnrichmentsSuccess(data.enrichment_people, data.total_entries))
  } catch (error) {
    yield put(fetchEnrichmentsFail(error))
  }
}

function* fetchEnrichmentIndex() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/enrichment_people/index_count`,
        method: "GET",
      })
    )
    yield put(fetchEnrichmentCountSuccess(data.total_entries))
  } catch (error) {
    yield put(fetchEnrichmentCountFail(error))
  }
}

function* approveEnrichment(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/enrichment_people/approve`,
        params: {
          id: action.id,
        },
        method: "PUT",
      })
    )
    yield put(approveEnrichmentSuccess())
    yield put(fetchEnrichments(1))
    yield put(fetchPerson(data.person_id))
  } catch (error) {
    yield put(approveEnrichmentFail(error))
  }
}

function* ignoreEnrichment(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/enrichment_people/reject`,
        params: {
          id: action.id,
        },
        method: "PUT",
      })
    )
    yield put(ignoreEnrichmentSuccess())
    yield put(fetchEnrichments(1))
  } catch (error) {
    yield put(ignoreEnrichmentFail(error))
  }
}

function* updateEnabledColumns(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/update_enabled_columns`,
        params: {
          enabled_columns: action.enabled_columns,
        },
        method: "PUT",
      })
    )
    const newColumnsEnabled = !action.enabled_columns.every((elem) =>
      action.old_enabled_columns.includes(elem)
    )
    if (newColumnsEnabled) {
      yield put(fetchPeople(1, 20, null, null, "last_sent", "desc"))
    } else {
      yield put(updateEnabledColumnsSuccess(data.enabled_columns))
    }
  } catch (error) {
    yield put(updateEnabledColumnsFail(error))
  }
}

function* showPeopleByIds(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/people/index_by_ids",
        method: "GET",
        params: {
          page: action.page || 1,
          selected_ids: action.people_ids,
        },
      })
    )

    yield put(
      fetchPeopleSuccess(data.people, data.total_count, data.enabled_columns, true)
    )
    yield call(() => history.push("/database?data_provided=1"))
  } catch (error) {
    yield put(fetchSelectedPeopleFail(error))
  }
}

function* cloneTeamSharedPersonToMyDb(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/clone_team_shared_person_to_my_db`,
        params: {
          id: action.person_id,
          token: action.token,
          deep_clone: action.mode === "edit",
        },
        method: "POST",
      })
    )
    yield put(cloneTeamSharedPersonToMyDbSuccess(data.person, action.person_id))
    yield put(setVisibleContactID(data.person.id))

    if (action.mode === "edit") {
      yield all([
        put(fetchSpheres()),
        put(fetchTopics()),
        put(fetchExpertises()),
        put(fetchCompanies()),
      ])
      yield put(fetchPerson(data.person.id))
      yield take(FETCH_PERSON_SUCCESS)
      yield put(setContactDrawerMode("editing"))
    } else if (action.mode === "message") {
      yield put(setSelectedContactId(data.person.id))
      yield put(setSplitMessageSenderVisible(true))
    }

    if (action.callback) {
      yield call(action.callback)
    }
  } catch (error) {
    yield put(cloneTeamSharedPersonToMyDbFail(error))
  }
}

function* updateTeamOwnershipSaga(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/people/${action.personId}/update_team_ownership`,
        method: "PUT",
        data: {
          team_id: action.teamId,
          owner_id: action.ownerId,
        },
      })
    )
    yield put(updateTeamOwnershipSuccess(data.person))
  } catch (error) {
    yield put(updateTeamOwnershipFail(error))
  }
}
