/**
 * Parses a message object, handling both plain text and structured content
 * @param {Object} msg - The message object to parse
 * @returns {Object} The parsed message with additional metadata
 */
export const parseMessage = (msg) => {
  if (!msg) return null;

  // If it's an assistant message with string content, try to parse as JSON
  if (msg.role === "assistant" && typeof msg.content === "string") {
    try {
      const parsed = JSON.parse(msg.content);
      if (parsed.properties && parsed.properties.content) {
        return {
          ...msg,
          parsedContent: parsed.properties,
        };
      }
      else if (parsed.content) {
 
        return {
          ...msg,
          parsedContent: parsed,
        };
      }
      
    } catch (e) {
      console.log("Failed to parse message content as JSON:", e);
    }
  }

  // If parsing failed or it's a user message, return as is
  return msg;
};

/**
 * Extracts mentioned people from a message's parsed content
 * @param {Object} message - The message object
 * @returns {Array} Array of mentioned people
 */
export const getMentionedPeople = (message) => {
  if (!message?.parsedContent?.mentioned_people) return [];
  return message.parsedContent.mentioned_people.filter(
    (person) => person.type === "person" && person.internal_person_id
  );
};

/**
 * Checks if a message contains any errors
 * @param {Object} message - The message object
 * @returns {boolean} Whether the message contains errors
 */
export const hasErrors = (message) => {
  return message?.parsedContent?.error || message?.metadata?.error;
};

/**
 * Gets the error message from a message object
 * @param {Object} message - The message object
 * @returns {string|null} The error message if present, null otherwise
 */
export const getErrorMessage = (message) => {
  return message?.parsedContent?.error || message?.metadata?.error || null;
}; 