/**
 * Test script for WebSocket connection
 *
 * This script can be used to test the WebSocket connection to the Whiz API.
 * It can be run in the browser console to verify that the WebSocket connection works.
 */

const testWebSocketConnection = () => {
  // Get access token
  const accessToken = localStorage.getItem("access_token")
  if (!accessToken) {
    console.log("No access token available")
    return
  }

  // Get API host URL and construct WebSocket URL
  const apiHostUrl = process.env.REACT_APP_API_HOST || window.location.origin
  const protocol = window.location.protocol === "https:" ? "wss:" : "ws:"
  const wsUrl = `${protocol}//${apiHostUrl.replace(
    /^https?:\/\//,
    ""
  )}/cable?token=${accessToken}`

  console.log("Connecting to WebSocket at:", wsUrl)

  const socket = new WebSocket(wsUrl)

  socket.onopen = (event) => {
    console.log("WebSocket connection opened", event)

    // Subscribe to WhizChannel
    socket.send(
      JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({ channel: "WhizChannel" }),
      })
    )
  }

  socket.onmessage = (event) => {
    console.log("Message from server:", event.data)

    try {
      const data = JSON.parse(event.data)

      if (data.type === "confirm_subscription") {
        console.log("Subscription confirmed, sending test message")

        socket.send(
          JSON.stringify({
            command: "message",
            identifier: JSON.stringify({ channel: "WhizChannel" }),
            data: JSON.stringify({ content: "Test message from WebSocket" }),
          })
        )
      }
    } catch (e) {
      console.log("Error parsing message:", e)
    }
  }

  socket.onclose = (event) => {
    console.log("WebSocket connection closed", event)
  }

  socket.onerror = (event) => {
    console.log("WebSocket error:", event)
  }

  return socket
}

// Export the function for use in the browser console
window.testWebSocketConnection = testWebSocketConnection

export default testWebSocketConnection
