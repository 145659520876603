import { useSelector } from 'react-redux'
import { selectIsFeatureEnabled } from '../redux/User/User.selectors'

export const useFeatureFlag = (featureKey) => {
  const isEnabled = useSelector((state) => selectIsFeatureEnabled(state, featureKey));
  return isEnabled
}

export const useWhizFeature = () => {
  return useFeatureFlag('whiz_feature')
} 