export const FETCH_USERS = "FETCH_USERS"
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS"
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL"

export const TAKE_SESSION = "TAKE_SESSION"
export const TAKE_SESSION_SUCCESS = "TAKE_SESSION_SUCCESS"
export const TAKE_SESSION_FAIL = "TAKE_SESSION_FAIL"

export const BACK_TO_ADMIN = "BACK_TO_ADMIN"
export const BACK_TO_ADMIN_SUCCESS = "BACK_TO_ADMIN_SUCCESS"
export const BACK_TO_ADMIN_FAIL = "BACK_TO_ADMIN_FAIL"

export const CLEAR_REDUX_ADMIN_STATE = "CLEAR_REDUX_ADMIN_STATE"
export const SET_INITIAL_REDUX_ADMIN_STATE = "SET_INITIAL_REDUX_ADMIN_STATE"

export const SET_AS_FREE_ACCOUNT = "SET_AS_FREE_ACCOUNT"
export const SET_AS_FREE_ACCOUNT_SUCCESS = "SET_AS_FREE_ACCOUNT_SUCCESS"
export const SET_AS_FREE_ACCOUNT_FAIL = "SET_AS_FREE_ACCOUNT_FAIL"

export const ADD_EXTRA_TRIAL_DAYS = "ADD_EXTRA_TRIAL_DAYS"
export const ADD_EXTRA_TRIAL_DAYS_SUCCESS = "ADD_EXTRA_TRIAL_DAYS_SUCCESS"
export const ADD_EXTRA_TRIAL_DAYS_FAIL = "ADD_EXTRA_TRIAL_DAYS_FAIL"

export const FETCH_CHALLENGES = "FETCH_CHALLENGES"

export const FETCH_CHALLENGES_SUCCESS = "FETCH_CHALLENGES_SUCCESS"

export const FETCH_CHALLENGES_FAIL = "FETCH_CHALLENGES_FAIL"

export const CREATE_CHALLENGE = "CREATE_CHALLENGE"

export const CREATE_CHALLENGE_SUCCESS = "CREATE_CHALLENGE_SUCCESS"

export const CREATE_CHALLENGE_FAIL = "CREATE_CHALLENGE_FAIL"

export const UPDATE_CHALLENGE = "UPDATE_CHALLENGE"

export const UPDATE_CHALLENGE_SUCCESS = "UPDATE_CHALLENGE_SUCCESS"

export const UPDATE_CHALLENGE_FAIL = "UPDATE_CHALLENGE_FAIL"

export const WIPE_ACCOUNTS = "WIPE_ACCOUNTS"
export const WIPE_ACCOUNTS_SUCCESS = "WIPE_ACCOUNTS_SUCCESS"
export const WIPE_ACCOUNTS_FAIL = "WIPE_ACCOUNTS_FAIL"

export const SEND_WARNING_EMAIL = "SEND_WARNING_EMAIL"

export const SEND_WARNING_EMAIL_SUCCESS = "SEND_WARNING_EMAIL_SUCCESS"

export const SEND_WARNING_EMAIL_FAIL = "SEND_WARNING_EMAIL_FAIL"

// Feature Flags
export const TOGGLE_USER_FEATURE = "TOGGLE_USER_FEATURE"
export const TOGGLE_USER_FEATURE_SUCCESS = "TOGGLE_USER_FEATURE_SUCCESS"
export const TOGGLE_USER_FEATURE_FAIL = "TOGGLE_USER_FEATURE_FAIL"
