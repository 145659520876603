import React, { useEffect } from "react"
import "./BulkActionsDrawer.styles.scss"
import { Drawer } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteContacts,
  editContacts,
  fetchSelectedPeople,
  mergeContacts,
} from "../../../redux/People/People.actions"
import LocationFinder from "../LocationFinder/LocationFinder.component"
import { ArrayParam, StringParam, useQueryParam } from "use-query-params"
import {
  setInitCC,
  setSplitMessageSenderVisible,
  showNoAccountNotification,
} from "../../../redux/App/App.actions"
import { fetchAccounts } from "../../../redux/Accounts/Accounts.actions"
import MiniDashboardForm from "../../UI/MiniDashboardForm/MiniDashboardForm.component"
import { editNoteClear } from "../../../redux/Interactions/Interactions.actions"
import DrawerOverlay from "../../UI/DrawerOverlay/DrawerOverlay.component"
import UpdateCollection from "./components/UpdateCollection/UpdateCollection.component"
import NumberOfSelectedRecords from "./components/NumberOfSelectedRecords/NumberOfSelectedRecords.component"
import BulkActionsButtonsGroup from "./components/BulkActionsButtonsGroup/BulkActionsButtonsGroup.component"
import SectionWithFill from "../../UI/SectionWithFill/SectionWithFill.component"
import BulkStrengthAndImportanceSelector from "./components/BulkStrengthAndImportanceSelector/BulkStrengthAndImportanceSelector.component"
import BulkTeamOwnerSelector from "./components/BulkTeamOwnerSelector/BulkTeamOwnerSelector.component"

const SelectedDrawerContent = () => {
  const selectedContactsIds = useSelector(
    (state) => state.AppState.selected_contacts_ids
  )
  const selectedContacts = useSelector((state) => state.AppState.selected_contacts)
  const userAccounts = useSelector((state) => state.AccountsState.accounts)
  const activeTab = useSelector((state) => state.AppState.activeDrawerTab)

  const dispatch = useDispatch()

  const [page] = useQueryParam("people_page", StringParam)
  const [sortField] = useQueryParam("people_by", StringParam)
  const [sortOrder] = useQueryParam("people_order", StringParam)
  const [searchValue] = useQueryParam("q", StringParam)
  const [filters] = useQueryParam("people_filters", ArrayParam)

  useEffect(() => {
    if (activeTab !== "note" && activeTab === "") {
      dispatch(editNoteClear())
    }
    // eslint-disable-next-line
  }, [activeTab])

  const bulkUpdateStrength = (strength) => {
    return selectedContactsIds.length > 0
      ? dispatch(editContacts(selectedContactsIds, { strength: strength }))
      : null
  }

  const bulkUpdateImportance = (importance) => {
    return selectedContactsIds.length > 0
      ? dispatch(editContacts(selectedContactsIds, { importance: importance }))
      : null
  }

  const bulkUpdateLocation = (location) => {
    return selectedContactsIds.length > 0
      ? dispatch(editContacts(selectedContactsIds, { location: location }))
      : null
  }

  const bulkUpdateTeamOwner = (teamId, ownerId) => {
    return selectedContactsIds.length > 0
      ? dispatch(
          editContacts(selectedContactsIds, {
            team_owner: {
              team_id: teamId,
              owner_id: ownerId,
            },
          })
        )
      : null
  }

  const bulkUpdateSpheres = (spheres, remove) => {
    return selectedContactsIds.length > 0
      ? dispatch(
          editContacts(selectedContactsIds, {
            remove: remove,
            spheres: spheres,
          })
        )
      : null
  }

  const bulkUpdateTopics = (topics, remove) => {
    return selectedContactsIds.length > 0
      ? dispatch(
          editContacts(selectedContactsIds, {
            remove: remove,
            topics: topics,
          })
        )
      : null
  }

  const handleClickSplitMessageButton = () => {
    const runningAccounts = userAccounts.filter((a) => a.state === "running")
    if (runningAccounts.length > 0) {
      dispatch(fetchSelectedPeople(selectedContactsIds))
      dispatch(setSplitMessageSenderVisible(true))
    } else {
      dispatch(showNoAccountNotification())
    }
  }

  const onClickSplitMessageCCButton = () => {
    const runningAccounts = userAccounts.filter((a) => a.state === "running")
    if (runningAccounts.length > 0) {
      dispatch(fetchSelectedPeople(selectedContactsIds))
      dispatch(setSplitMessageSenderVisible(true))
      dispatch(setInitCC(selectedContactsIds))
    } else {
      dispatch(showNoAccountNotification())
    }
  }

  const handleClickArchiveButton = () => {
    dispatch(deleteContacts(selectedContactsIds))
  }

  const handleClickMergeButton = (main_contact_id) => {
    dispatch(
      mergeContacts(
        selectedContactsIds,
        page,
        filters,
        searchValue,
        sortField,
        sortOrder,
        main_contact_id
      )
    )
  }

  useEffect(() => {
    dispatch(fetchAccounts())
    // eslint-disable-next-line
  }, [])

  return (
    <div className="BulkActionsDrawer_Content">
      <NumberOfSelectedRecords numberOfRecords={selectedContactsIds.length} />
      <BulkActionsButtonsGroup
        onClickSplitMessageButton={handleClickSplitMessageButton}
        onClickSplitMessageCCButton={onClickSplitMessageCCButton}
        onClickArchiveButton={handleClickArchiveButton}
        onClickMergeButton={handleClickMergeButton}
      />
      <MiniDashboardForm contacts={selectedContacts} defaultActiveTab={activeTab} />
      <div className={"BulkActionsDrawer_Content_Filter"}>
        <UpdateCollection
          title="Spheres"
          fillColor="lightGrey"
          collectionType="spheres"
          onSubmit={bulkUpdateSpheres}
          numberOfRecords={selectedContactsIds.length}
        />
        <UpdateCollection
          title="Topics"
          fillColor="lightYellow"
          collectionType="topics"
          onSubmit={bulkUpdateTopics}
          numberOfRecords={selectedContactsIds.length}
        />
        <BulkTeamOwnerSelector
          onUpdateTeamOwner={bulkUpdateTeamOwner}
          disabled={selectedContactsIds.length <= 0}
        />
        <SectionWithFill title="Location" fillColor="lightGreen">
          <div className="BulkActionsDrawer_Content_Location">
            <LocationFinder
              value={null}
              onSelect={bulkUpdateLocation}
              disabled={selectedContactsIds.length <= 0}
            />
          </div>
        </SectionWithFill>
        <BulkStrengthAndImportanceSelector
          onUpdateStrength={bulkUpdateStrength}
          onUpdateImportance={bulkUpdateImportance}
          disabled={selectedContactsIds.length <= 0}
        />
      </div>
    </div>
  )
}

const SelectedDrawer = ({ visible, handleClose }) => (
  <Drawer
    title="Bulk Actions"
    placement="right"
    visible={visible}
    mask={false}
    className="BulkActionsDrawer"
    closable={false}
    destroyOnClose={true}
    getContainer={true}
    width="430px"
    afterVisibleChange={(visible) => {
      if (!visible) {
        handleClose()
      }
    }}
  >
    <>
      <DrawerOverlay />
      <SelectedDrawerContent />
    </>
  </Drawer>
)

export default SelectedDrawer
