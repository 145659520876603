import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./SectionDetails.styles.scss"
import { Checkbox, DatePicker, Form, Input, Select, Tooltip } from "antd"
import Icon from "@ant-design/icons"
import MapPinIcon from "../../../../Icons/MapPin.icons"
import LocationFinder from "../../../../Database/LocationFinder/LocationFinder.component"
import GlobeIcon from "../../../../Icons/Globe.icons"
import Handshake from "../../../../Icons/Handshake.icons"
import TagIcon from "../../../../Icons/Tag.icons"
import CurrencyCircleDollarIcon from "../../../../Icons/CurrencyCircleDollar.icons"
import CollectionSelector from "../../../../Database/CollectionSelector/CollectionSelector.component"
import * as _ from "lodash"
import GiftIcon from "../../../../Icons/Gift.icons"
import moment from "moment/moment"
import { useHistory } from "react-router-dom"
import WizardIcon from "../../../../Icons/Wizard.icons"
import CalendarCheckIcon from "../../../../Icons/CalendarCheck.icons"
import XCircleIcon from "../../../../Icons/XCircle.icons"
import PlusCircleIcon from "../../../../Icons/PlusCircle.icons"
import ListDashesIcon from "../../../../Icons/ListDashes.icons"
import GitForkIcon from "../../../../Icons/GitFork.icons"
import { urlify } from "../../../../../helpers/helpers"
import getCustomLabels from "../SectionContact/CustomLabels.api"
import RelatedContactsSelector from "./RelatedContactsSelector.component"
import { setVisibleContactID } from "../../../../../redux/App/App.actions"
import { useDispatch, useSelector } from "react-redux"
import { ReactComponent as BackwardIcon } from "./../../../../Icons/BackwardIcon.svg"
import SourceOfRelationshipSelector from "./SourceOfRelationshipSelector/SourceOfRelationshipSelector.component"
import { ReactComponent as UserGroupIcon } from "./../../../../Icons/UserGroup.svg"

const DOMPurify = require("dompurify")(window)
const defaultLabels = [
  { name: "Twitter", value: "Twitter" },
  { name: "Facebook", value: "Facebook" },
  { name: "LinkedIn", value: "LinkedIn" },
]
const SectionDetails = ({ isEditing, formik, mode, contact, shared = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserState)
  const teams = useSelector((state) => state.UserState.teams || [])

  const [linksLabels, setLinksLabels] = useState(defaultLabels)
  const [profileFieldsLabels, setProfileFieldsLabels] = useState([])

  const showCollection = (collection, id) => {
    if (!shared) {
      history.push({
        pathname: "/database",
        search: `?by=last_sent&filters=${collection}.id%3A%20${id}&order=desc&page=1&res=people`,
      })
    }
  }

  const handleChangeBirthday = (e) => {
    if (e === null) {
      formik.setFieldValue("birthday", null)
    } else {
      formik.setFieldValue("birthday", moment(e).format("YYYY-MM-DD"))
    }
  }

  const getScope = () => {
    if (mode !== "view") {
      return formik.values
    } else {
      return contact
    }
  }

  const collectionsTab = shared
    ? [
        ...(Object.values(contact?.team_access_info || {}).some(
          (info) => info.has_access
        )
          ? [
              {
                title: "Team Ownership",
                key: "team_access_info",
                icon: UserGroupIcon,
              },
            ]
          : []),
        {
          title: "Sources",
          key: "sources",
          icon: BackwardIcon,
        },
        { title: "Topics", key: "topics", icon: TagIcon },
        { title: "Expertise", key: "expertises", icon: WizardIcon },
        {
          title: "Companies",
          key: "companies",
          icon: CurrencyCircleDollarIcon,
        },
      ]
    : [
        ...(Object.values(contact?.team_access_info || {}).some(
          (info) => info.has_access
        )
          ? [
              {
                title: "Team Ownership",
                key: "team_access_info",
                icon: UserGroupIcon,
              },
            ]
          : []),
        { title: "Sources", key: "sources", icon: BackwardIcon },
        { title: "Spheres", key: "spheres", icon: GlobeIcon },
        { title: "Topics", key: "topics", icon: TagIcon },
        { title: "Expertise", key: "expertises", icon: WizardIcon },
        {
          title: "Related Contacts",
          key: "connexions",
          icon: Handshake,
        },
        {
          title: "Companies",
          key: "companies",
          icon: CurrencyCircleDollarIcon,
        },
      ]

  const handleCustomLabels = () => {
    getCustomLabels("links", (labels) =>
      setLinksLabels([...defaultLabels, ...labels])
    )
    getCustomLabels("profile_fields", (labels) =>
      setProfileFieldsLabels([{ name: "Other", value: "Other" }, ...labels])
    )
  }

  useEffect(() => {
    handleCustomLabels()
  }, [contact])

  const renderSelector = (field) => {
    if (field.key === "team_access_info") {
      const teamAccessInfo = contact?.team_access_info || {}
      return (
        <div className="SectionDetails_Field_Items">
          {Object.entries(teamAccessInfo).map(([teamName, info]) => {
            if (!info.has_access) return null
            const team = teams.find((t) => t.name === teamName)
            if (!team) return null

            return (
              <div
                key={teamName}
                className="SectionDetails_Field_Items_EditableItem_Inputs"
                style={{ width: "100%" }}
              >
                <div style={{ marginBottom: "8px" }}>
                  <strong>{teamName}:</strong>
                </div>
                <Form.Item>
                  <Select
                    value={info.ownership?.owner_id}
                    onChange={(value) => {
                      const ownership = team.members.find((m) => m.id === value)
                      let newTeamAccessInfo = formik.values.team_access_info
                      newTeamAccessInfo[teamName] = {
                        has_access: info.has_access,
                        team_id: info.team_id,
                        ownership: {
                          owner_id: value,
                          owner_name:
                            ownership.id === user.id
                              ? "This is me"
                              : ownership.first_name && ownership.last_name
                              ? `${ownership.first_name} ${ownership.last_name}`
                              : ownership.email,
                        },
                      }
                      formik.setFieldValue("team_access_info", newTeamAccessInfo)
                    }}
                    style={{ width: "100%" }}
                    placeholder="Select owner"
                  >
                    {team.members?.map((member) => (
                      <Select.Option key={member.id} value={member.id}>
                        {member.id === user.id
                          ? "This is me"
                          : member.first_name && member.last_name
                          ? `${member.first_name} ${member.last_name}`
                          : member.email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )
          })}
        </div>
      )
    } else if (field.key === "connexions") {
      return (
        <RelatedContactsSelector
          collectionTitle={field.title}
          collectionType={field.key}
          currentElements={formik.values[field.key]}
          handleSubmit={(existingCollections, newCollections) => {
            formik.setFieldValue(
              field.key,
              _.uniqBy(
                [
                  ...existingCollections,
                  ...newCollections.map((c) => ({
                    full_name: c.label,
                    person_id: c.value,
                    id: c.id,
                  })),
                ],
                "full_name"
              )
            )
          }}
        />
      )
    } else if (field.key === "sources") {
      return (
        <SourceOfRelationshipSelector
          currentElements={formik.values[field.key]}
          handleSubmit={(selectedSources) => {
            formik.setFieldValue(
              field.key,
              _.uniqBy(
                [
                  ...selectedSources.map((c) => ({
                    id:
                      c.source_id ||
                      (c.value.includes("sourceId_")
                        ? c.value.replace("sourceId_", "")
                        : null),
                    title: c.label,
                    source_person_id:
                      c.source_person_id ||
                      (c.value.includes("sourcePersonId_")
                        ? c.value.replace("sourcePersonId_", "")
                        : null),
                  })),
                ],
                "title"
              )
            )
          }}
        />
      )
    } else {
      return (
        <CollectionSelector
          collectionTitle={field.title}
          searchable={field.searchable}
          submitMode="realtime"
          collectionType={field.key}
          currentElements={formik.values[field.key]}
          handleSubmit={(existingCollections, newCollections) => {
            formik.setFieldValue(
              field.key,
              _.uniqBy(
                [
                  ...existingCollections,
                  ...newCollections.map((title) => ({
                    title: title,
                  })),
                ],
                "title"
              )
            )
          }}
        />
      )
    }
  }

  const renderCollection = (field) => {
    if (field.key === "team_access_info") {
      const teamAccessInfo = contact.team_access_info || {}
      return Object.entries(teamAccessInfo).map(([teamName, info]) => {
        if (!info.ownership) return null
        return (
          <div className="SectionDetails_Field_Items" key={teamName}>
            <span>{teamName}:</span>
            <div className="SectionDetails_Field_Items_Item" key={teamName}>
              <span>
                {info.ownership.owner_id === user.id
                  ? "This is me"
                  : info.ownership.owner_name}
              </span>
            </div>
          </div>
        )
      })
    } else if (field.key === "connexions") {
      return contact[field.key].map((item) => (
        <div
          className="SectionDetails_Field_Items_Item--contact"
          key={item.id}
          onClick={() => dispatch(setVisibleContactID(item.person_id))}
        >
          <span>{item.full_name}</span>
        </div>
      ))
    } else if (["sources"].includes(field.key)) {
      return (
        <>
          {contact[field.key].map((item) => {
            if (item.source_person_id) {
              return (
                <div
                  className="SectionDetails_Field_Items_Item SectionDetails_Field_Items_Item--SourceContact"
                  key={item.id}
                  onClick={() =>
                    dispatch(setVisibleContactID(item.source_person_id))
                  }
                >
                  <span>{item.title}</span>
                </div>
              )
            } else {
              return (
                <div
                  className="SectionDetails_Field_Items_Item"
                  key={item.id}
                  onClick={() => showCollection(field.key, item.id)}
                >
                  <span>{item.title}</span>
                </div>
              )
            }
          })}
          {contact.referred.length > 0 && (
            <div className="SectionDetails_Field_Items SectionDetails_Field_Items--referred">
              <span>Referred:</span>
              {contact.referred.map((item) => (
                <div
                  className="SectionDetails_Field_Items_Item--contact"
                  key={item.person_id}
                  onClick={() => dispatch(setVisibleContactID(item.person_id))}
                >
                  <span>{item.full_name}</span>
                </div>
              ))}
            </div>
          )}
        </>
      )
    } else if (field.key === "spheres") {
      // show team shared spheres icon
      return contact[field.key].map((item) => (
        <div
          className="SectionDetails_Field_Items_Item"
          key={item.id}
          onClick={() => showCollection(field.key, item.id)}
        >
          {item.team_shared && (
            <Icon style={{ marginRight: "8px" }} component={UserGroupIcon} />
          )}
          <span>{item.title}</span>
        </div>
      ))
    } else {
      return contact[field.key].map((item) => (
        <div
          className="SectionDetails_Field_Items_Item"
          key={item.id}
          onClick={() => showCollection(field.key, item.id)}
        >
          <span>
            {["spheres", "expertises"].includes(field.key) ? "" : "#"}
            {item.title}
          </span>
        </div>
      ))
    }
  }

  return (
    <>
      {(contact?.location ||
        contact?.spheres?.length > 0 ||
        contact?.topics?.length > 0 ||
        contact?.expertises?.length > 0 ||
        contact?.connexions?.length > 0 ||
        contact?.sources?.length > 0 ||
        contact?.companies?.length > 0 ||
        contact?.birthday ||
        getScope()?.special_dates?.length > 0 ||
        getScope()?.profile_fields?.length > 0 ||
        getScope()?.links?.length > 0 ||
        isEditing) && (
        <section id="sectionDetails" className="Section">
          {(contact?.location || isEditing) && (
            <div className="SectionDetails_Field">
              <Tooltip title="Location">
                <Icon component={MapPinIcon} />
              </Tooltip>
              {isEditing ? (
                <Form.Item>
                  <LocationFinder
                    value={formik.values.location}
                    onSelect={(city) => formik.setFieldValue("location", city)}
                  />
                </Form.Item>
              ) : (
                <span>{contact.location}</span>
              )}
            </div>
          )}
          {shared && (
            <div className="SectionOverviewShared_Field_Items">
              {contact?.spheres.map((item) => (
                <div
                  className="SectionOverviewShared_Field_Items_Item"
                  key={item.id}
                >
                  {item.team_shared && (
                    <Icon style={{ marginRight: "8px" }} component={UserGroupIcon} />
                  )}
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
          )}

          {collectionsTab.map(
            (field) =>
              ((contact &&
                (field.key === "team_access_info"
                  ? Object.values(contact.team_access_info || {}).some(
                      (info) => info.ownership
                    )
                  : contact[field.key]?.length > 0 ||
                    (field.key === "sources" && contact.referred?.length > 0))) ||
                isEditing) && (
                <div
                  className="SectionDetails_Field SectionDetails_Field--collection"
                  key={field.key}
                >
                  <Tooltip title={field.title}>
                    <Icon
                      className={isEditing ? "Icon--editing" : ""}
                      component={field.icon}
                    />
                  </Tooltip>
                  {isEditing ? (
                    <Form.Item
                      validateStatus={
                        formik.touched[field.key] && formik.errors[field.key]
                          ? "error"
                          : "success"
                      }
                      help={formik.touched[field.key] && formik.errors[field.key]}
                    >
                      {renderSelector(field)}
                    </Form.Item>
                  ) : (
                    <div className="SectionDetails_Field_Items">
                      {renderCollection(field)}
                    </div>
                  )}
                </div>
              )
          )}
          {(contact?.birthday || isEditing) && (
            <div className="SectionDetails_Field">
              <Tooltip title="Birthday">
                <Icon component={GiftIcon} />
              </Tooltip>
              {isEditing ? (
                <Form.Item
                  validateStatus={
                    formik.touched.birthday && formik.errors.birthday
                      ? "error"
                      : "success"
                  }
                  help={formik.touched.birthday && formik.errors.birthday}
                >
                  <div className="SectionDetails_Field_Birthday">
                    <DatePicker
                      placeholder="Birthday"
                      key="birthday"
                      name="birthday"
                      dropdownClassName={
                        formik.values?.birthday_year ? "" : "DatePicker--hideYear"
                      }
                      format={formik.values?.birthday_year ? "MM/DD/YYYY" : "MM/DD"}
                      value={
                        formik.values.birthday
                          ? moment(formik.values.birthday)
                          : null
                      }
                      onChange={handleChangeBirthday}
                      onBlur={() => formik.setFieldTouched("birthday", true)}
                    />
                    <Checkbox
                      key="birthday_year"
                      name="birthday_year"
                      checked={formik.values.birthday_year}
                      onChange={(e) =>
                        formik.setFieldValue("birthday_year", e.target.checked)
                      }
                    >
                      Year
                    </Checkbox>
                  </div>
                </Form.Item>
              ) : (
                <span>
                  Birthday:{" "}
                  {moment(contact.birthday).format(
                    contact?.birthday_year ? "YYYY-MM-DD" : "MM-DD"
                  )}
                </span>
              )}
            </div>
          )}

          {(getScope()?.special_dates?.length > 0 || isEditing) && (
            <div className="SectionDetails_DetailsField">
              <Tooltip title="Special Dates">
                <Icon component={CalendarCheckIcon} />
              </Tooltip>
              <div className="SectionDetails_DetailsField_Items">
                {getScope()?.special_dates?.length > 0 &&
                  getScope().special_dates.map((field, i) =>
                    isEditing ? (
                      <div
                        key={i}
                        className="SectionDetails_DetailsField_Items_EditableItem"
                      >
                        <div className="SectionDetails_DetailsField_Items_EditableItem_Inputs">
                          <Form.Item
                            validateStatus={
                              formik.touched.special_dates &&
                              formik.errors.special_dates &&
                              formik.touched.special_dates[i]?.name &&
                              formik.errors.special_dates[i]?.name
                                ? "error"
                                : "success"
                            }
                            help={
                              formik.touched.special_dates &&
                              formik.errors.special_dates &&
                              formik.touched.special_dates[i]?.name &&
                              formik.errors.special_dates[i]?.name
                            }
                          >
                            <Input
                              placeholder="Name"
                              key={`special_dates[${i}]_Input`}
                              name={`special_dates[${i}]`}
                              value={formik.values.special_dates[i].name}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `special_dates[${i}].name`,
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              formik.touched.special_dates &&
                              formik.errors.special_dates &&
                              formik.touched.special_dates[i]?.date &&
                              formik.errors.special_dates[i]?.date
                                ? "error"
                                : "success"
                            }
                            help={
                              formik.touched.special_dates &&
                              formik.errors.special_dates &&
                              formik.touched.special_dates[i]?.date &&
                              formik.errors.special_dates[i]?.date
                            }
                          >
                            <DatePicker
                              placeholder="Date"
                              key={`special_dates[${i}]_DatePicker`}
                              name={`special_dates[${i}]`}
                              dropdownClassName={
                                formik.values.special_dates[i]?.year
                                  ? ""
                                  : "DatePicker--hideYear"
                              }
                              format={
                                formik.values.special_dates[i]?.year
                                  ? "MM/DD/YYYY"
                                  : "MM/DD"
                              }
                              allowClear={false}
                              value={
                                formik.values.special_dates[i].date
                                  ? moment(formik.values.special_dates[i].date)
                                  : null
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `special_dates[${i}].date`,
                                  moment(e).format("YYYY-MM-DD")
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched(
                                  `special_dates[${i}].date`,
                                  true
                                )
                              }
                            />
                            <Checkbox
                              className="SectionDetails_DetailsField_Items_EditableItem_Inputs_YearCheckbox"
                              key={`special_dates[${i}]_Checkbox`}
                              name={`special_dates[${i}]`}
                              checked={formik.values.special_dates[i]?.year}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `special_dates[${i}].year`,
                                  e.target.checked
                                )
                              }
                            >
                              Year
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div
                          className="SectionDetails_DetailsField_Items_EditableItem_DeleteButton"
                          onClick={() => {
                            formik.setFieldValue(
                              "dates",
                              formik.values.special_dates.splice(i, 1)
                            )
                          }}
                        >
                          <Icon component={XCircleIcon} />
                        </div>
                      </div>
                    ) : (
                      <div
                        key={i}
                        className="SectionDetails_DetailsField_Items_Item"
                      >
                        <span>{field.name}</span>
                        <span>
                          {moment(field.date).format(
                            field?.year ? "YYYY-MM-DD" : "MM-DD"
                          )}
                        </span>
                      </div>
                    )
                  )}
                {isEditing && (
                  <div
                    className="SectionDetails_DetailsField_Items_AddNewButton"
                    onClick={() =>
                      formik.setFieldValue(
                        "special_dates",
                        formik.values.special_dates.concat({
                          date: null,
                          name: "",
                          year: false,
                          date_type: "default",
                        })
                      )
                    }
                  >
                    <Icon component={PlusCircleIcon} />
                    Add new special date
                  </div>
                )}
              </div>
            </div>
          )}

          {(getScope()?.profile_fields?.length > 0 || isEditing) && (
            <div className="SectionDetails_DetailsField">
              <Tooltip title="Custom Fields">
                <Icon component={ListDashesIcon} />
              </Tooltip>
              <div className="SectionDetails_DetailsField_Items">
                {getScope()?.profile_fields?.length > 0 &&
                  getScope().profile_fields.map((field, i) =>
                    isEditing ? (
                      <div
                        key={i}
                        className="SectionDetails_DetailsField_Items_EditableItem"
                      >
                        <div className="SectionDetails_DetailsField_Items_EditableItem_Inputs">
                          <Form.Item
                            validateStatus={
                              formik.touched.profile_fields &&
                              formik.errors.profile_fields &&
                              formik.touched.profile_fields[i]?.name &&
                              formik.errors.profile_fields[i]?.name
                                ? "error"
                                : "success"
                            }
                            help={
                              formik.touched.profile_fields &&
                              formik.errors.profile_fields &&
                              formik.touched.profile_fields[i]?.name &&
                              formik.errors.profile_fields[i]?.name
                            }
                          >
                            <Select
                              key={`profile_fields[${i}]`}
                              name={`profile_fields[${i}]`}
                              value={
                                formik.values.profile_fields[i].name ||
                                profileFieldsLabels[0]?.value ||
                                ""
                              }
                              onSelect={(e) =>
                                formik.setFieldValue(`profile_fields[${i}].name`, e)
                              }
                            >
                              {profileFieldsLabels.map((label, i) => (
                                <Select.Option key={i} value={label.value}>
                                  {label.name}
                                </Select.Option>
                              ))}
                              <Select.Option value={""}>
                                <Input
                                  bordered={false}
                                  placeholder="New label"
                                  key={`profile_fields[${i}].other`}
                                  name={`profile_fields[${i}].other`}
                                  value={
                                    formik.values.profile_fields[i].name
                                      ? formik.values.profile_fields[i].name
                                      : ""
                                  }
                                  onClick={(e) => {
                                    e.target.focus()
                                    e.stopPropagation()
                                  }}
                                  onPressEnter={(e) => {
                                    formik.setFieldValue(
                                      `profile_fields[${i}].name`,
                                      e.target.value
                                    )
                                    e.target.blur()
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `profile_fields[${i}].name`,
                                      e.target.value
                                    )
                                    formik.setFieldValue(
                                      `profile_fields[${i}].is_new_custom_label`,
                                      true
                                    )
                                  }}
                                />
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              formik.touched.profile_fields &&
                              formik.errors.profile_fields &&
                              formik.touched.profile_fields[i]?.value &&
                              formik.errors.profile_fields[i]?.value
                                ? "error"
                                : "success"
                            }
                            help={
                              formik.touched.profile_fields &&
                              formik.errors.profile_fields &&
                              formik.touched.profile_fields[i]?.value &&
                              formik.errors.profile_fields[i]?.value
                            }
                          >
                            <Input
                              placeholder="Content"
                              key={`profile_fields[${i}]`}
                              name={`profile_fields[${i}]`}
                              value={formik.values.profile_fields[i].value}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `profile_fields[${i}].value`,
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                        <div
                          className="SectionDetails_DetailsField_Items_EditableItem_DeleteButton"
                          onClick={() => {
                            formik.setFieldValue(
                              "fields",
                              formik.values.profile_fields.splice(i, 1)
                            )
                          }}
                        >
                          <Icon component={XCircleIcon} />
                        </div>
                      </div>
                    ) : (
                      <div
                        key={i}
                        className="SectionDetails_DetailsField_Items_Item"
                      >
                        <span>{field.name}</span>
                        <span>{field.value}</span>
                      </div>
                    )
                  )}
                {isEditing && (
                  <div
                    className="SectionDetails_DetailsField_Items_AddNewButton"
                    onClick={() =>
                      formik.setFieldValue(
                        "profile_fields",
                        formik.values.profile_fields.concat({
                          value: "",
                          name: "",
                        })
                      )
                    }
                  >
                    <Icon component={PlusCircleIcon} />
                    Add new custom field
                  </div>
                )}
              </div>
            </div>
          )}

          {(getScope()?.links?.length > 0 || isEditing) && (
            <div className="SectionDetails_DetailsField">
              <Tooltip title="Websites and Links">
                <Icon component={GitForkIcon} />
              </Tooltip>
              <div className="SectionDetails_DetailsField_Items">
                {getScope()?.links?.length > 0 &&
                  getScope().links.map((field, i) =>
                    isEditing ? (
                      <div
                        key={i}
                        className="SectionDetails_DetailsField_Items_EditableItem"
                      >
                        <div className="SectionDetails_DetailsField_Items_EditableItem_Inputs">
                          <Form.Item
                            validateStatus={
                              formik.touched.links &&
                              formik.errors.links &&
                              formik.touched.links[i]?.name &&
                              formik.errors.links[i]?.name
                                ? "error"
                                : "success"
                            }
                            help={
                              formik.touched.links &&
                              formik.errors.links &&
                              formik.touched.links[i]?.name &&
                              formik.errors.links[i]?.name
                            }
                          >
                            <Select
                              key={`links[${i}]`}
                              name={`links[${i}]`}
                              value={
                                formik.values.links[i].name ||
                                linksLabels[0]?.value ||
                                ""
                              }
                              onSelect={(e) =>
                                formik.setFieldValue(`links[${i}].name`, e)
                              }
                            >
                              {linksLabels.map((label, i) => (
                                <Select.Option key={i} value={label.value}>
                                  {label.name}
                                </Select.Option>
                              ))}
                              <Select.Option value={""}>
                                <Input
                                  bordered={false}
                                  placeholder="Other"
                                  key={`links[${i}].other`}
                                  name={`links[${i}].other`}
                                  value={
                                    formik.values.links[i].name
                                      ? formik.values.links[i].name
                                      : ""
                                  }
                                  onClick={(e) => {
                                    e.target.focus()
                                    e.stopPropagation()
                                  }}
                                  onPressEnter={(e) => {
                                    formik.setFieldValue(
                                      `links[${i}].name`,
                                      e.target.value
                                    )
                                    e.target.blur()
                                    e.stopPropagation()
                                  }}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `links[${i}].name`,
                                      e.target.value
                                    )
                                    formik.setFieldValue(
                                      `links[${i}].is_new_custom_label`,
                                      true
                                    )
                                  }}
                                />
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            validateStatus={
                              formik.touched.links &&
                              formik.errors.links &&
                              formik.touched.links[i]?.value &&
                              formik.errors.links[i]?.value
                                ? "error"
                                : "success"
                            }
                            help={
                              formik.touched.links &&
                              formik.errors.links &&
                              formik.touched.links[i]?.value &&
                              formik.errors.links[i]?.value
                            }
                          >
                            <Input
                              placeholder="Url"
                              key={`links[${i}]`}
                              name={`links[${i}]`}
                              value={formik.values.links[i].value}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `links[${i}].value`,
                                  e.target.value
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                        <div
                          className="SectionDetails_DetailsField_Items_EditableItem_DeleteButton"
                          onClick={() => {
                            formik.setFieldValue(
                              "websites",
                              formik.values.links.splice(i, 1)
                            )
                          }}
                        >
                          <Icon component={XCircleIcon} />
                        </div>
                      </div>
                    ) : (
                      <div
                        key={i}
                        className="SectionDetails_DetailsField_Items_Item"
                      >
                        <span>{field.name}</span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(urlify(field.value), {
                              ADD_ATTR: ["target"],
                            }),
                          }}
                        ></span>
                      </div>
                    )
                  )}
                {isEditing && (
                  <div
                    className="SectionDetails_DetailsField_Items_AddNewButton"
                    style={{ marginBottom: 0 }}
                    onClick={() =>
                      formik.setFieldValue(
                        "links",
                        formik.values.links.concat({
                          value: "",
                          name: "",
                        })
                      )
                    }
                  >
                    <Icon component={PlusCircleIcon} />
                    Add new links
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
      )}
    </>
  )
}

SectionDetails.propTypes = {
  isEditing: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  contact: PropTypes.object,
  shared: PropTypes.bool,
}

SectionDetails.defaultProps = {
  isEditing: false,
  shared: false,
}

export default SectionDetails
