import React, { useEffect, useRef, useState } from "react"
import "./LeftRail.styles.scss"
import { Divider, Layout, Tooltip } from "antd"
import {
  StepBackwardOutlined,
  UserOutlined,
  MessageOutlined,
} from "@ant-design/icons/lib"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { apiHost } from "../../../config/serverConf"
import { reorderSpheres } from "../../../redux/Collections/Collections.actions"
import { backToAdmin } from "../../../redux/Admin/Admin.actions"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import PropTypes from "prop-types"
import Icon, { PlusOutlined } from "@ant-design/icons"
import TilesIcon from "../../../components/Icons/Tiles.icons"
import DatabaseIcon from "../../../components/Icons/Database.icons"
import FolderNotchOpenIcon from "../../../components/Icons/FolderNotchOpen.icons"
import SettingsIcon from "../../../components/Icons/Settings.icons"
import LifebuoyIcon from "../../../components/Icons/Lifebuoy.icons"
import Avatar from "../../../components/UI/Avatar/Avatar.component"
import {
  setLeftRailCollapsed,
  setShowStartBuildingCard,
  setSphereModalVisible,
} from "../../../redux/App/App.actions"
import { useParams } from "react-router"
import CaretCircleDoubleLeftIcon from "../../../components/Icons/CaretCircleDoubleLeft.icons"
import FeatureCard from "./FeatureCard.component"
import { ReactComponent as BenchAndMan } from "./../../../images/BenchAndMan.svg"
import SphereCreatorModal from "./SphereCreatorModal/SphereCreatorModal.component"
import { ReactComponent as UserGroupIcon } from "./../../../../src/components/Icons/UserGroup.svg"
import { useWhizFeature } from "../../../hooks/useFeatureFlag"

const { Sider } = Layout

const LeftRail = ({ query, setQuery, showInfoBar }) => {
  const user = useSelector((state) => state.UserState)
  const is_impersonating = useSelector((state) => state.AdminState.is_impersonating)
  const admin_refresh_token = useSelector(
    (state) => state.AdminState.admin_refresh_token
  )
  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const sharedSpheres = useSelector((state) => state.CollectionsState.shared_spheres)
  const teamSharedSpheres = useSelector(
    (state) => state.CollectionsState.team_shared_spheres
  )

  const spheres_order = useSelector((state) => state.CollectionsState.spheres_order)
  const leftRailCollapsed = useSelector((state) => state.AppState.leftRailCollapsed)
  const [orderedSpheres, setOrderedSpheres] = useState([])
  const [tooltipVisible, setTooltipVisible] = useState(null)
  const [showHelpScout, setShowHelpScout] = useState(false)
  const [sphereCreatorVisible, setSphereCreatorVisible] = useState(false)

  const firstUpdate = useRef(true)
  const [delayedLeftRailCollapsed, setDelayedLeftRailCollapsed] =
    useState(leftRailCollapsed)
  const history = useHistory()
  const params = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (leftRailCollapsed) {
      setDelayedLeftRailCollapsed(true)
    } else {
      setTimeout(() => setDelayedLeftRailCollapsed(false), 100)
    }
  }, [leftRailCollapsed])

  const AddOrderToSphere = (
    my_spheres_order = null,
    my_spheres,
    team_spheres = []
  ) => {
    if (my_spheres_order) {
      return my_spheres?.concat(team_spheres).map((item) => {
        item.order = my_spheres_order.findIndex((o) => o === item.id)
        if (item.order === -1) {
          item.order = my_spheres_order.length + item.id
        }
        return item
      })
    } else {
      return my_spheres?.concat(team_spheres).map((item, index) => {
        item.order = index
        return item
      })
    }
  }

  useEffect(() => {
    if (spheres || teamSharedSpheres) {
      setOrderedSpheres(AddOrderToSphere(spheres_order, spheres, teamSharedSpheres))
    }
    // eslint-disable-next-line
  }, [spheres, teamSharedSpheres])

  const goToSphere = (sphere) => {
    dispatch(setShowStartBuildingCard(false))
    if (sphere?.sphere_type && sphere?.sphere_type !== "team_sphere") {
      history.push(`/${sphere.sphere_type}-sphere/${sphere.friendly_id}`)
    } else {
      history.push(
        "/database?people_by=last_sent&people_order=desc&people_page=1&participants_by=last_sent&participants_order=desc&participants_page=1&res=people"
      )
      if (sphere?.id) {
        setQuery({
          people_page: 1,
          res: "people",
          people_by: "last_sent",
          people_order: "desc",
          people_filters: [`spheres.id: ${sphere.id.toString()}`],
          participants_page: 1,
          participants_by: "last_sent",
          participants_order: "desc",
        })
      } else {
        setQuery({
          people_page: 1,
          res: "people",
          people_by: "last_sent",
          people_order: "desc",
          participants_page: 1,
          participants_by: "last_sent",
          participants_order: "desc",
        })
      }
    }
  }

  const handleOnReorderingSphereEnd = (result) => {
    if (!result.destination) return

    const items = Array.from(orderedSpheres, (s) => s.id)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    dispatch(reorderSpheres(items))
    setOrderedSpheres(AddOrderToSphere(items, spheres, teamSharedSpheres))
  }

  const handleToggleHelpScout = () => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      window.Beacon("init", process.env.REACT_APP_BEACON_ID)
      window.Beacon("identify", {
        name: user.first_name,
        email: user.email,
        user_id: user.id,
      })
    }

    setShowHelpScout(!showHelpScout)
    window.Beacon("toggle")
  }

  window.Beacon("on", "open", () => setShowHelpScout(true))

  window.Beacon("on", "close", () => setShowHelpScout(false))

  return (
    <Sider
      breakpoint="lg"
      width={250}
      collapsedWidth="54"
      trigger={null}
      collapsible
      collapsed={leftRailCollapsed}
      className={`LeftRail ${showInfoBar && "LeftRail--ShowInfoBar"}`}
    >
      <div
        className="LeftRail_Header"
        style={{ height: user.partner_logo ? "150px" : "75px" }}
      >
        {!leftRailCollapsed && (
          <div>
            <img
              className="LeftRail_Header_Logo"
              width={210}
              src={`${process.env.PUBLIC_URL}/primary.svg`}
              alt="Relatable"
            />
            {user.partner_logo && (
              <img
                className="LeftRail_Header_Logo"
                width={210}
                style={{ maxHeight: "74px", padding: "16px" }}
                src={apiHost() + user.partner_logo}
                alt="Partner's logo"
              />
            )}
          </div>
        )}
        <Tooltip
          placement="left"
          mouseLeaveDelay={0}
          mouseEnterDelay={0.4}
          title={delayedLeftRailCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
        >
          <button
            className={
              leftRailCollapsed
                ? "LeftRail_Header-openButton"
                : "LeftRail_Header-closeButton"
            }
            onClick={() => dispatch(setLeftRailCollapsed(!leftRailCollapsed))}
          >
            <Icon component={CaretCircleDoubleLeftIcon} />
          </button>
        </Tooltip>
      </div>
      <div className={"LeftRail_Buttons"}>
        <div
          className={`LeftRail_List ${
            leftRailCollapsed ? "LeftRail_List-collapsed" : ""
          }`}
        >
          {is_impersonating && admin_refresh_token && (
            <div className="LeftRail_List_Item">
              <button
                className={"LeftRail_DatabaseLink"}
                onClick={() => {
                  dispatch(backToAdmin(admin_refresh_token))
                }}
              >
                <StepBackwardOutlined />
                <span>Back to Admin</span>
              </button>
            </div>
          )}

          <div
            className={`LeftRail_List_Item ${
              history.location.pathname.match(/.*dashboard.*/)
                ? "LeftRail_List_Item--active"
                : ""
            }`}
          >
            <Tooltip
              placement="left"
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              title={leftRailCollapsed ? "Dashboard" : ""}
            >
              <Link to="/dashboard">
                <Icon component={TilesIcon} />
                <span>Dashboard</span>
              </Link>
            </Tooltip>
          </div>
          {useWhizFeature() && (
            <div
              className={`LeftRail_List_Item ${
                history.location.pathname.match(/.*whiz.*/)
                  ? "LeftRail_List_Item--active"
                  : ""
              }`}
            >
              <Tooltip
                placement="left"
                mouseLeaveDelay={0}
                mouseEnterDelay={0}
                title={leftRailCollapsed ? "Whiz" : ""}
              >
                <Link to="/whiz">
                  <MessageOutlined />
                  <span>Whiz</span>
                </Link>
              </Tooltip>
            </div>
          )}
          <div
            className={`LeftRail_List_Item ${
              history.location.pathname.match(/.*database.*/)
                ? "LeftRail_List_Item--active"
                : ""
            }`}
          >
            <Tooltip
              placement="left"
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              title={leftRailCollapsed ? "Database" : ""}
            >
              <button
                className={"LeftRail_DatabaseLink"}
                onClick={() => goToSphere(null)}
              >
                <Icon component={DatabaseIcon} />
                <span>Database</span>
                {!leftRailCollapsed && (
                  <span
                    className={"LeftRail_AddSphere"}
                    onClick={(e) => {
                      setSphereCreatorVisible(true)
                      e.stopPropagation()
                    }}
                  >
                    <Icon
                      className={"LeftRail_AddSphere_Icon"}
                      component={PlusOutlined}
                    />
                  </span>
                )}
              </button>
            </Tooltip>
          </div>
          {orderedSpheres?.length > 0 && !leftRailCollapsed && (
            <DragDropContext onDragEnd={handleOnReorderingSphereEnd}>
              <Droppable droppableId="LeftRail_Spheres">
                {(provided) => (
                  <div
                    className={`LeftRail_Spheres ${
                      !user.has_push_registration ? "MaxHeight200" : ""
                    }`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {orderedSpheres
                      .sort((a, b) => a.order - b.order)
                      .map((sphere, index) => {
                        return (
                          <Draggable
                            key={sphere.id}
                            draggableId={`${sphere.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <Tooltip
                                placement="right"
                                color="blue"
                                mouseEnterDelay={1}
                                title="Click and drag to reorder your spheres here!"
                                visible={tooltipVisible === sphere.id}
                                onVisibleChange={(v) =>
                                  v
                                    ? setTooltipVisible(sphere.id)
                                    : setTooltipVisible(null)
                                }
                              >
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`LeftRail_Spheres_Item ${
                                    query?.people_filters?.includes(
                                      `spheres.id: ${sphere.id.toString()}`
                                    ) ||
                                    (sphere.sphere_type === "team_sphere" &&
                                      sphere.token === params.token)
                                      ? "LeftRail_Spheres_Item--active"
                                      : ""
                                  }
                                  `}
                                >
                                  <div onClick={() => goToSphere(sphere)}>
                                    {sphere.sphere_type === "team_sphere" && (
                                      <Icon
                                        style={{ marginRight: "8px" }}
                                        component={UserGroupIcon}
                                      />
                                    )}
                                    <span>{sphere.title}</span>
                                  </div>
                                  {sphere.title &&
                                    sphere.sphere_type !== "team_sphere" && (
                                      <Icon
                                        component={SettingsIcon}
                                        onClick={() => {
                                          if (sphere.sphere_type) {
                                            history.push(
                                              `/${sphere.sphere_type}-sphere/${sphere.friendly_id}`
                                            )
                                          } else {
                                            dispatch(
                                              setSphereModalVisible(true, sphere.id)
                                            )
                                          }
                                        }}
                                        className="LeftRail_Spheres_Item_EditIcon"
                                      />
                                    )}
                                </div>
                              </Tooltip>
                            )}
                          </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {!leftRailCollapsed && sharedSpheres?.length ? <Divider /> : null}
          {!leftRailCollapsed &&
            sharedSpheres?.map((ss) => {
              return (
                <div
                  className={`LeftRail_Spheres_Item ${
                    ss.token === params.token ? "LeftRail_Spheres_Item--active" : ""
                  }`}
                >
                  <Link to={`/database/shared/${ss.token}`}>
                    <span>{ss.title}</span>
                  </Link>
                  <Tooltip title={`Shared by ${ss.shared_by}`}>
                    <UserOutlined />
                  </Tooltip>
                </div>
              )
            })}
          <div
            className={`LeftRail_List_Item ${
              history.location.pathname.match(/.*uncategorized.*/)
                ? "LeftRail_List_Item--active"
                : ""
            }`}
          >
            <Tooltip
              placement="left"
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              title={leftRailCollapsed ? "Uncatgorized" : ""}
            >
              <Link to="/uncategorized">
                <Icon component={FolderNotchOpenIcon} />
                <span>Uncategorized</span>
              </Link>
            </Tooltip>
          </div>
          {user?.partner && (
            <div
              className={`LeftRail_List_Item ${
                history.location.pathname.match(/.*partner.*/)
                  ? "LeftRail_List_Item--active"
                  : ""
              }`}
            >
              <Tooltip
                placement="left"
                mouseLeaveDelay={0}
                mouseEnterDelay={0}
                title={leftRailCollapsed ? "Admin Panel" : ""}
              >
                <Link to="/partner">
                  <Icon component={SettingsIcon} />
                  <span>Partner Panel</span>
                </Link>
              </Tooltip>
            </div>
          )}
          {user && user.role && user.role === "admin" && (
            <div
              className={`LeftRail_List_Item ${
                history.location.pathname.match(/.*admin.*/)
                  ? "LeftRail_List_Item--active"
                  : ""
              }`}
            >
              <Tooltip
                placement="left"
                mouseLeaveDelay={0}
                mouseEnterDelay={0}
                title={leftRailCollapsed ? "Admin Panel" : ""}
              >
                <Link to="/admin">
                  <Icon component={SettingsIcon} />
                  <span>Admin Panel</span>
                </Link>
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className={`LeftRail_Settings ${
            leftRailCollapsed ? "LeftRail_Settings-collapsed" : ""
          }`}
        >
          <Tooltip
            placement="left"
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            title={leftRailCollapsed ? "Support" : ""}
          >
            <div
              className={`LeftRail_List_Item ${
                showHelpScout ? "LeftRail_List_Item--active" : ""
              }`}
              onClick={handleToggleHelpScout}
            >
              <Icon component={LifebuoyIcon} />
              <span>Support</span>
            </div>
          </Tooltip>
          <div
            className={`LeftRail_List_Item ${
              history.location.pathname.match(/.*account.*/)
                ? "LeftRail_List_Item--active"
                : ""
            }`}
          >
            <Tooltip
              placement="left"
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              title={leftRailCollapsed ? "Settings" : ""}
            >
              <Link to="/account/settings">
                <Icon component={SettingsIcon} />
                <span>Settings</span>
              </Link>
            </Tooltip>
          </div>
          {!leftRailCollapsed && !user.has_push_registration && (
            <FeatureCard
              title="Relatable for Mobile is here"
              description={
                <span>
                  Download for{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/relatable-crm/id1668247614"
                  >
                    iPhone
                  </a>{" "}
                  or{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.relatable.crm"
                  >
                    Android
                  </a>{" "}
                  today.
                </span>
              }
              image={<BenchAndMan />}
            ></FeatureCard>
          )}
        </div>
      </div>
      <div
        className={`LeftRail_Footer ${
          leftRailCollapsed ? "LeftRail_Footer-collapsed" : ""
        }`}
      >
        <Avatar
          avatar={user?.avatar !== "" ? apiHost() + user.avatar : null}
          firstName={user?.first_name}
          lastName={user?.last_name}
          email={user?.email}
          colorNumber={user?.id}
        />
        {!delayedLeftRailCollapsed && (
          <div className="LeftRail_Footer_Name">
            <Link to="/account/settings">
              <span>{`${user?.first_name} ${user?.last_name}`}</span>
            </Link>
            <span>{user?.email}</span>
          </div>
        )}
      </div>
      <SphereCreatorModal
        handleClose={() => setSphereCreatorVisible(false)}
        visible={sphereCreatorVisible}
      />
    </Sider>
  )
}

LeftRail.propTypes = {
  query: PropTypes.object,
  setQuery: PropTypes.func,
  showInfoBar: PropTypes.bool,
}

export default LeftRail
