import React from "react"
import { useSelector } from "react-redux"
import SectionWithFill from "../../../../UI/SectionWithFill/SectionWithFill.component"
import TeamOwnerSelector from "../../../TeamOwnerSelector/TeamOwnerSelector.component"
import "./BulkTeamOwnerSelector.styles.scss"

const BulkTeamOwnerSelector = ({ onUpdateTeamOwner, disabled }) => {
  const userTeams = useSelector((state) => state.UserState.teams || [])
  const user = useSelector((state) => state.UserState)

  return (
    <SectionWithFill title="Team Owners" fillColor="white">
      <div className="BulkTeamOwnerSelector">
        {userTeams.map((team) => (
          <div key={team.id} className="BulkTeamOwnerSelector_Team">
            <span className="BulkTeamOwnerSelector_Team_Name">{team.name}</span>
            <TeamOwnerSelector
              team={team}
              currentUser={user}
              ownership={null}
              disabled={disabled}
              onOwnerChange={(ownerId) => onUpdateTeamOwner(team.id, ownerId)}
            />
          </div>
        ))}
      </div>
    </SectionWithFill>
  )
}

export default BulkTeamOwnerSelector
