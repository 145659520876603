import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchMessages,
  fetchMessagesOpenRate,
  fetchSplitMessageDetails,
  resendMessage,
} from "../../../../redux/Messages/Messages.actions"
import { List, Tooltip } from "antd"
import "./MessagesTab.styles.scss"
import SplitMessage from "./MessageItems/SplitMessage.component"
import SimpleMessage from "./MessageItems/SimpleMessage.component"
import { showNoAccountNotification } from "../../../../redux/App/App.actions"
import EmailComposerModal from "../../../../components/UI/EmailComposerModal/EmailComposerModal.component"
import CustomPagination from "../../../../components/Database/CustomPagination/CustomPagination.component"
import MessageCreator from "../../../../components/UI/EmailComposerModal/MessageCreator/MessageCreator.component"
import RelatableLoader from "../../../../components/UI/RelatableLoader/RelatableLoader.component"
import EmptyState from "../../../../components/UI/EmptyState/EmptyState.ui"
const MessagesTab = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const messages = useSelector((state) => state.Messages.messages)
  const total_entries = useSelector((state) => state.Messages.total_entries)
  const open_rate_all_time = useSelector(
    (state) => state.Messages.open_rate_all_time
  )
  const open_rate_30_days = useSelector((state) => state.Messages.open_rate_30_days)
  const loading = useSelector((state) => state.Messages.loading)
  const loading_open_rate = useSelector((state) => state.Messages.loading_open_rate)
  const userAccounts = useSelector((state) => state.AccountsState.accounts)
  const track_opened = useSelector((state) => state.UserState.track_opened)
  const visible = useSelector(
    (state) => state.AppState.scheduledSplitMessageSenderVisible
  )
  const fetchingSelectedPeople = useSelector(
    (state) => state.PeopleState.fetching_selected
  )
  const [selectedScheduledSplitMessageId, setSelectedScheduledSplitMessageId] =
    useState(null)

  const perPage = 20

  useEffect(() => {
    dispatch(fetchMessagesOpenRate())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(fetchMessages(page, perPage))
    // eslint-disable-next-line
  }, [page])

  const renderItem = (item) => {
    if (item.type === "simple_message") {
      return <SimpleMessage message={item} />
    } else if (item) {
      return (
        <SplitMessage
          onClickResendButton={() => {
            const runningAccounts = userAccounts.filter((a) => a.state === "running")

            if (runningAccounts.length > 0) {
              dispatch(resendMessage(item.type, item.id))
            } else {
              dispatch(showNoAccountNotification())
            }
          }}
          splitMessage={item}
          onUpdateSplitMessage={(splitMessage) => {
            const runningAccounts = userAccounts.filter((a) => a.state === "running")

            if (runningAccounts.length > 0) {
              dispatch(fetchSplitMessageDetails(splitMessage.id))
              setSelectedScheduledSplitMessageId(splitMessage.id)
            } else {
              dispatch(showNoAccountNotification())
            }
          }}
        />
      )
    }
  }

  const emptyMessageText = (
    <EmptyState
      instructions="You can send individual messages by clicking on a contact or create a split message by clicking on a group of contacts in the Database."
      quote="Doubt can only be removed by action."
      quoteFrom="Ted Lasso"
      size="medium"
      show={true}
    />
  )

  return (
    <div className="MessagesTab">
      <div className="MessagesTab_OpenRate">
        <Tooltip
          className="MessagesTab_OpenRate_Last30"
          title="Turn on open tracking in settings to see how many of your messages are opened"
          trigger={!track_opened ? "hover" : "none"}
          placement="bottom"
        >
          <h3>
            {track_opened && !loading_open_rate && open_rate_30_days >= 0
              ? open_rate_30_days + "%"
              : "?"}
          </h3>
          <div className="MessagesTab_OpenRate_Last30_Description">
            <span>Open Rate</span>
            <span>Last 30 days</span>
          </div>
        </Tooltip>
        <Tooltip
          className="MessagesTab_OpenRate_AllTime"
          title="Turn on open tracking in settings to see how many of your messages are opened"
          trigger={!track_opened ? "hover" : "none"}
          placement="bottom"
        >
          <h3>
            {track_opened && !loading_open_rate && open_rate_all_time >= 0
              ? open_rate_all_time + "%"
              : "?"}
          </h3>
          <div className="MessagesTab_OpenRate_AllTime_Description">
            <span>Open Rate</span>
            <span>All time</span>
          </div>
        </Tooltip>
      </div>
      <List
        loading={{
          spinning: loading,
          indicator: <RelatableLoader loading={loading} />,
        }}
        renderItem={renderItem}
        dataSource={messages ? messages : []}
        locale={{ emptyText: emptyMessageText }}
      />

      <div className="MessagesTab_Pagination">
        <CustomPagination
          perPage={perPage}
          currentPage={page}
          onPageChange={setPage}
          hideTextButton={true}
          hideOnSinglePage={!messages?.length > 0}
          total={total_entries}
          small={true}
        />
      </div>
      {visible && selectedScheduledSplitMessageId && !fetchingSelectedPeople && (
        <EmailComposerModal visible={visible}>
          <MessageCreator
            onCancel={() => {
              setSelectedScheduledSplitMessageId(null)
            }}
            splitMessage={messages.find(
              (m) => m.id === selectedScheduledSplitMessageId
            )}
            onSubmit={() => setPage(1)}
          />
        </EmailComposerModal>
      )}
    </div>
  )
}

export default MessagesTab
