import { all, call, put, select, takeLatest } from "redux-saga/effects"
import axios from "axios"
import {
  DELETE_SCHEDULED_SPLIT_MESSAGE,
  DELETE_TEMPLATE,
  FETCH_AI_DRAFT,
  FETCH_MESSAGES,
  FETCH_MESSAGES_OPEN_RATE,
  FETCH_SPLIT_MESSAGE,
  FETCH_SPLIT_MESSAGE_DETAILS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATES,
  NEW_TEMPLATE,
  RESEND_MESSAGE,
  SAVE_TEMPLATE,
  SEND_SPLIT_MESSAGE,
  UPDATE_SCHEDULED_SPLIT_MESSAGE,
} from "./Messages.types"
import {
  deleteScheduledSplitMessageFail,
  deleteScheduledSplitMessageSuccess,
  deleteTemplateFail,
  deleteTemplateSuccess,
  fetchAiDraftFail,
  fetchAiDraftSuccess,
  fetchMessages as fetchMessagesAction,
  fetchMessagesFail,
  fetchMessagesOpenRateFail,
  fetchMessagesOpenRateSuccess,
  fetchMessagesSuccess,
  fetchSplitMessageDetailsFail,
  fetchSplitMessageDetailsSuccess,
  fetchSplitMessageFail,
  fetchSplitMessageSuccess,
  fetchTemplateFail,
  fetchTemplatesFail,
  fetchTemplatesSuccess,
  fetchTemplateSuccess,
  newTemplateFail,
  newTemplateSuccess,
  resendMessageSuccess,
  saveTemplateFail,
  saveTemplateSuccess,
  scheduledSplitMessageFail,
  scheduledSplitMessageSuccess,
  sendSplitMessageFail,
  sendSplitMessageSuccess,
  setLoading,
  setMessageDraft,
  updateScheduledSplitMessageFail,
  updateScheduledSplitMessageSuccess,
} from "./Messages.actions"
import { fetchActivities, fetchPoints } from "../Activities/Activities.actions"
import {
  deselectContacts,
  fillDataInSelectedContacts,
  setScheduledSplitMessageSenderVisible,
  setSelectedContactId,
  setSplitMessageSenderVisible,
  showNoAccountNotification,
} from "../App/App.actions"
import { fetchAccounts } from "../Accounts/Accounts.actions"

export function* watchMessagesSaga() {
  yield takeLatest(FETCH_MESSAGES, fetchMessages)
  yield takeLatest(FETCH_MESSAGES_OPEN_RATE, fetchMessagesOpenRate)
  yield takeLatest(FETCH_SPLIT_MESSAGE, fetchSplitMessage)
  yield takeLatest(FETCH_SPLIT_MESSAGE_DETAILS, fetchSplitMessageDetails)
  yield takeLatest(SEND_SPLIT_MESSAGE, sendSplitMessage)
  yield takeLatest(UPDATE_SCHEDULED_SPLIT_MESSAGE, updateScheduledSplitMessage)
  yield takeLatest(DELETE_SCHEDULED_SPLIT_MESSAGE, deleteScheduledSplitMessage)
  yield takeLatest(FETCH_TEMPLATES, fetchTemplates)
  yield takeLatest(SAVE_TEMPLATE, saveTemplate)
  yield takeLatest(DELETE_TEMPLATE, deleteTemplate)
  yield takeLatest(NEW_TEMPLATE, newTemplate)
  yield takeLatest(FETCH_TEMPLATE, fetchTemplate)
  yield takeLatest(RESEND_MESSAGE, resendMessage)
  yield takeLatest(FETCH_AI_DRAFT, fetchAIDraft)
}

function* fetchMessages(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/messages",
        params: {
          page: action.page,
          per: action.per_page,
        },
        method: "GET",
      })
    )
    yield put(fetchMessagesSuccess(data.messages, data.total_entries))
  } catch (e) {
    yield put(fetchMessagesFail(e))
  }
}

function* fetchMessagesOpenRate(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/messages/count_open_rate",
        method: "GET",
      })
    )
    yield put(
      fetchMessagesOpenRateSuccess(data.open_rate_30_days, data.open_rate_all_time)
    )
  } catch (e) {
    yield put(fetchMessagesOpenRateFail(e))
  }
}

function* fetchSplitMessage(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/messages/show_split_message`,
        params: {
          split_message_id: action.id,
        },
        method: "GET",
      })
    )
    yield put(fetchSplitMessageSuccess(data))
  } catch (e) {
    yield put(fetchSplitMessageFail(e))
  }
}

function* fetchSplitMessageDetails(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/messages/show_split_message_details`,
        params: {
          split_message_id: action.id,
        },
        method: "GET",
      })
    )
    yield put(deselectContacts())
    yield put(fetchSplitMessageDetailsSuccess(data.split_message))
    yield put(fillDataInSelectedContacts(data.recipients))
    yield put(setScheduledSplitMessageSenderVisible(true))
  } catch (e) {
    yield put(fetchSplitMessageDetailsFail(e))
  }
}

function* sendSplitMessage(action) {
  const formData = new FormData()

  if (action.scheduledDate) {
    formData.append("scheduledDate", JSON.stringify(action.scheduledDate))
  }
  if (action.ai_draft) {
    formData.append("ai_draft", JSON.stringify(action.ai_draft))
  }
  formData.append("messages", JSON.stringify(action.messages))

  // Add debugging for attachments
  if (action.attachments && action.attachments.length > 0) {
    console.log("Processing attachments:", action.attachments.length)
    action.attachments.forEach((file, index) => {
      console.log(`Processing attachment ${index}:`, file)
      if (file.id) {
        formData.append(`existing_attachments[]`, JSON.stringify(file))
        console.log(`Added existing attachment ${index}`)
      } else {
        formData.append("new_attachments[]", file, file.name)
        console.log(`Added new attachment ${index}: ${file.name}`)
      }
    })
  } else {
    console.log("No attachments to process")
  }

  try {
    console.log("Sending request with formData")
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/messages/split_message",
        data: formData,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    )

    console.log("Request successful:", data)
    if (action.scheduledDate) {
      yield put(scheduledSplitMessageSuccess(data.messages))
    } else {
      yield put(sendSplitMessageSuccess(data.messages))
    }

    yield all([
      put(setSplitMessageSenderVisible(false)),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
    ])
    yield put(setSelectedContactId(null))
  } catch (error) {
    console.error("Request failed:", error)
    if (action.scheduledDate) {
      yield put(scheduledSplitMessageFail(error))
    } else {
      yield put(sendSplitMessageFail(error))
      yield put(setSplitMessageSenderVisible(false))
    }
    yield put(setSelectedContactId(null))
  }
}

function* updateScheduledSplitMessage(action) {
  const formData = new FormData()
  yield call(async () => {
    if (action.scheduledDate) {
      formData.append("scheduledDate", JSON.stringify(action.scheduledDate))
    }
    formData.append("messages", JSON.stringify(action.messages))

    action.attachments.forEach((file) => {
      if (file.id) {
        formData.append(`existing_attachments[]`, JSON.stringify(file))
      } else {
        formData.append("new_attachments[]", file, file.name)
      }
    })
  })

  try {
    yield call(() =>
      axios.request({
        url: "/v1/messages/update_scheduled_split_message",
        data: formData,
        method: "POST",
      })
    )

    yield all([
      put(updateScheduledSplitMessageSuccess()),
      put(setScheduledSplitMessageSenderVisible(false)),
      put(fetchMessagesAction(1, 20)),
      put(setSplitMessageSenderVisible(false)),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
    ])
  } catch (e) {
    yield put(updateScheduledSplitMessageFail(e))
  }
}

function* deleteScheduledSplitMessage(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/messages/delete_scheduled_split_message`,
        data: {
          split_message_id: action.split_message_id,
          from: action.from,
        },
        method: "DELETE",
      })
    )
    yield all([
      put(setScheduledSplitMessageSenderVisible(false)),
      put(fetchMessagesAction(1, 20)),
      put(fetchActivities(1, 12)),
      put(fetchPoints()),
      put(deleteScheduledSplitMessageSuccess()),
    ])
  } catch (error) {
    if (error.message === "Request failed with status code 423") {
      yield put(showNoAccountNotification())
      yield put(setLoading(false))
    }
    yield put(fetchAccounts())
    yield put(deleteScheduledSplitMessageFail(error))
  }
}

function* fetchTemplates(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/email_templates",
        method: "GET",
      })
    )
    yield put(fetchTemplatesSuccess(data.templates))
  } catch (e) {
    yield put(fetchTemplatesFail(e))
  }
}

function* fetchTemplate(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/email_templates/${action.id}`,
        method: "GET",
      })
    )
    yield put(fetchTemplateSuccess(data))
  } catch (e) {
    yield put(fetchTemplateFail(e))
  }
}

function* newTemplate(action) {
  const formData = new FormData()
  yield call(async () => {
    formData.append("subject", action.template.subject)
    formData.append("body", action.template.body)
    formData.append("name", action.template.name)
    action.template.deleted_files.forEach((file) => {
      formData.append("deleted[]", file.id)
    })

    action.template.new_files.forEach((file) => {
      formData.append("files[]", file, file.name)
    })
  })

  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/email_templates",
        method: "POST",
        data: formData,
      })
    )
    yield put(newTemplateSuccess(data))
  } catch (e) {
    yield put(newTemplateFail(e))
  }
}

function* saveTemplate(action) {
  const formData = new FormData()
  yield call(async () => {
    formData.append("subject", action.template.subject)
    formData.append("body", action.template.body)
    formData.append("name", action.template.name)
    action.template.deleted_files.forEach((file) => {
      formData.append("deleted[]", file.id)
    })

    action.template.new_files.forEach((file) => {
      formData.append("files[]", file, file.name)
    })
  })

  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/email_templates/${action.template.id}`,
        data: formData,
        method: "PUT",
      })
    )
    yield put(saveTemplateSuccess(data))
  } catch (e) {
    yield put(saveTemplateFail(e))
  }
}

function* deleteTemplate(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/email_templates/${action.template_id}`,
        method: "DELETE",
      })
    )
    yield put(deleteTemplateSuccess(action.template_id))
  } catch (e) {
    yield put(deleteTemplateFail(e))
  }
}

function* resendMessage(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/messages/resend_message",
        data: { message_id: action.id, message_type: action.messageType },
        method: "POST",
      })
    )
    yield put(fetchMessagesAction(1, 20))
    yield put(resendMessageSuccess())
  } catch (e) {
    yield put(deleteTemplateFail(e))
  }
}

function* fetchAIDraft(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/messages/craft_ai_draft`,
        method: "GET",
        params: {
          person_id: action.person_id,
          tone: action.tone,
          general_subject: action.subject,
          preset_template_id: action.preset_template_id,
        },
      })
    )
    const messageSenderVisible = yield select(
      (state) => state.AppState.messageSenderVisible
    )

    const splitMessageSenderVisible = yield select(
      (state) => state.AppState.splitMessageSenderVisible
    )

    const visibleContactId = yield select((state) => state.AppState.visibleContactID)

    if (
      splitMessageSenderVisible ||
      (messageSenderVisible && visibleContactId === action.person_id)
    ) {
      yield put(
        setMessageDraft({
          subject: data.subject,
          body: data.body,
          preset_template_id: action.preset_template_id,
        })
      )
    } else {
      yield put(fetchAiDraftSuccess(data, action.person_id))
    }
  } catch (e) {
    yield put(fetchAiDraftFail(e))
  }
}
