/**
 * WhizWebSocketService
 *
 * This service handles WebSocket communication with the Whiz API.
 */

import { store } from "../redux/store"
import { apiHost } from "../config/serverConf"

class WhizWebSocketService {
  constructor() {
    this.socket = null
    this.connected = false
    this.subscribed = false
    this.listeners = {
      message: [],
      connected: [],
      disconnected: [],
      error: [],
    }
    this.reconnectAttempts = 0
    this.maxReconnectAttempts = 5
    this.reconnectInterval = 3000
    this.authenticated = false
    this.baseUrl = null
    this.messageCallbacks = []
    this.statusCallbacks = []
    this.reconnectDelay = 1000
    this.initializeBaseUrl()
  }

  initializeBaseUrl() {
    const apiHostUrl = apiHost()
    const protocol = apiHostUrl.startsWith("https") ? "wss://" : "ws://"
    const host = apiHostUrl.replace(/^https?:\/\//, "")
    this.baseUrl = `${protocol}${host}/cable`
  }

  connect() {
    // Close existing connection if any
    if (this.socket) {
      this.socket.close()
    }

    // Get the access token - try multiple sources
    let accessToken = this.getAccessToken()

    if (!accessToken) {
      console.error("No access token available for WebSocket connection")
      this.notifyListeners("error", {
        type: "auth_error",
        message: "No access token available",
      })
      return
    }

    // Get the WebSocket URL and add the token
    const url = `${this.baseUrl}?token=${accessToken}`

    console.log(`Creating WebSocket connection to: ${url}`)

    try {
      // Create new WebSocket connection
      this.socket = new WebSocket(url)

      // Set up event handlers
      this.socket.onopen = this.handleOpen.bind(this)
      this.socket.onclose = this.handleClose.bind(this)
      this.socket.onerror = this.handleError.bind(this)
      this.socket.onmessage = this.handleMessage.bind(this)
    } catch (error) {
      console.error("Error creating WebSocket connection:", error)
      this.notifyListeners("error", {
        type: "connection_error",
        message: "Failed to create WebSocket connection",
        error,
      })
    }
  }

  getAccessToken() {
    // Get token from Redux store (primary source)
    const reduxState = store.getState()
    if (reduxState && reduxState.UserState && reduxState.UserState.access_token) {
      return reduxState.UserState.access_token
    }

    // Try to get token from localStorage
    const localStorageToken = localStorage.getItem("access_token")
    if (localStorageToken) return localStorageToken

    // Try to get token from sessionStorage
    const sessionStorageToken = sessionStorage.getItem("access_token")
    if (sessionStorageToken) return sessionStorageToken

    // Try to get token from cookies
    const cookies = document.cookie.split(";")
    for (const cookie of cookies) {
      if (cookie.includes("access_token=")) {
        return cookie.substring("access_token=".length, cookie.length)
      }
    }

    // Try to get token from Authorization header
    const authHeader = localStorage.getItem("authHeader")
    if (authHeader && authHeader.startsWith("Bearer ")) {
      return authHeader.substring("Bearer ".length)
    }

    return null
  }

  handleOpen(event) {
    console.log("WebSocket connection established")
    this.connected = true
    this.reconnectAttempts = 0
    this.authenticated = true // Connection success means we're authenticated

    // Subscribe to the Whiz channel immediately
    this.subscribeToWhizChannel()

    // Notify listeners
    this.notifyListeners("connected", { event })
  }

  handleClose(event) {
    console.log("WebSocket connection closed", event)
    this.connected = false
    this.subscribed = false

    // Notify listeners
    this.notifyListeners("disconnected", { event })

    // Attempt to reconnect
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++
      console.log(
        `Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`
      )

      setTimeout(() => {
        this.connect()
      }, this.reconnectDelay * this.reconnectAttempts)
    } else {
      console.error("Maximum reconnection attempts reached")
      this.notifyListeners("error", {
        type: "reconnect_failed",
        message: "Maximum reconnection attempts reached",
      })
    }
  }

  handleError(error) {
    console.error("WebSocket error:", error)
    this.notifyListeners("error", { error })
  }

  handleMessage(event) {
    const { data } = event
    console.log("WebSocket message received:", data)

    try {
      const parsedData = JSON.parse(data)

      // Ignore ping messages
      if (parsedData.type === "ping") {
        return
      }

      // Handle welcome message
      if (parsedData.type === "welcome") {
        console.log("Connected to ActionCable")
        return
      }

      // Handle connection_established message
      if (parsedData.type === "connection_established") {
        console.log("Connection established to WhizChannel")
        this.subscribed = true
        this.notifyListeners("connected", { subscribed: true })
        return
      }

      // Handle confirmation message
      if (parsedData.type === "confirm_subscription") {
        try {
          const identifier = parsedData.identifier
            ? JSON.parse(parsedData.identifier)
            : null
          console.log("Subscription confirmed for channel:", identifier?.channel)

          if (identifier && identifier.channel === "WhizChannel") {
            this.subscribed = true
            this.notifyListeners("connected", { subscribed: true })
          }
        } catch (parseError) {
          console.error("Error parsing subscription identifier:", parseError)
        }
        return
      }

      // Handle reject_subscription
      if (parsedData.type === "reject_subscription") {
        try {
          const identifier = parsedData.identifier
            ? JSON.parse(parsedData.identifier)
            : null
          console.error("Subscription rejected for channel:", identifier?.channel)

          // If WhizChannel was rejected, this is an error
          if (identifier && identifier.channel === "WhizChannel") {
            this.notifyListeners("error", {
              type: "subscription_rejected",
              message: "WhizChannel subscription was rejected",
            })
          }
        } catch (parseError) {
          console.error(
            "Error parsing rejected subscription identifier:",
            parseError
          )
        }
        return
      }

      // Handle regular messages
      if (parsedData.message) {
        this.notifyListeners("message", parsedData.message)
      }
    } catch (error) {
      console.error("Error parsing WebSocket message:", error)
    }
  }

  subscribeToWhizChannel() {
    this.sendMessage({
      command: "subscribe",
      identifier: JSON.stringify({ channel: "WhizChannel" }),
    })
  }

  sendMessage(message) {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.error(
        "WebSocket is not connected. ReadyState:",
        this.socket ? this.socket.readyState : "No socket"
      )
      this.notifyListeners("error", {
        type: "not_connected",
        message: "WebSocket is not connected",
      })
      return
    }

    if (!this.subscribed && typeof message !== "object") {
      console.error("Cannot send message: Not subscribed to WhizChannel")
      this.notifyListeners("error", {
        type: "not_subscribed",
        message: "Not subscribed to WhizChannel",
      })
      return
    }

    // If message is a string, assume it's a chat message for WhizChannel
    let messageObj
    if (typeof message === "string") {
      messageObj = {
        command: "message",
        identifier: JSON.stringify({ channel: "WhizChannel" }),
        data: JSON.stringify({ content: message }),
      }
    } else {
      // Otherwise, use the message object as is
      messageObj = message
    }

    const data = JSON.stringify(messageObj)

    try {
      this.socket.send(data)
      console.log("Sending WebSocket message:", data)
    } catch (error) {
      console.error(
        "WebSocket is not connected. ReadyState:",
        this.socket ? this.socket.readyState : "No socket"
      )
      this.notifyListeners("error", {
        type: "not_connected",
        message: "WebSocket is not connected",
      })
    }
  }

  on(event, callback) {
    if (this.listeners[event]) {
      this.listeners[event].push(callback)
    }
    return this
  }

  off(event, callback) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter((cb) => cb !== callback)
    }
    return this
  }

  notifyListeners(event, data) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((callback) => {
        try {
          callback(data)
        } catch (error) {
          console.error(`Error in ${event} listener:`, error)
        }
      })
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close()
      this.connected = false
      this.subscribed = false
    }
  }

  isConnected() {
    return this.connected && this.subscribed
  }

  getApiHostUrl() {
    return this.baseUrl
  }
}

// Create a singleton instance
const whizWebSocketService = new WhizWebSocketService()

export default whizWebSocketService
