import { call, delay, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import history from "../../history"
import {
  ADD_TEAM_MEMBER,
  AUTHENTICATE_USER,
  CANCEL_TEAM_INVITE,
  CREATE_API_KEY,
  CREATE_PARTNER,
  CREATE_TEAM,
  DELETE_API_KEY,
  DELETE_TEAM,
  FETCH_PARTNER,
  FETCH_TEAM,
  GET_API_KEYS,
  GET_STATUS,
  GET_TRIAL_INFO,
  GET_USER_DETAILS,
  GET_USER_FINISHED_TASKS,
  HIDE_ONBOARDING_FLOW,
  JOIN_TO_TEAM,
  LEAVE_TEAM,
  LOGOUT,
  LOGOUT_ALL_DEVICES,
  PASSWORD_RESET,
  REGENERATE_API_KEY,
  REGISTER_USER,
  REMOVE_MEMBER_TEAM,
  REMOVE_TEAM_INVITATION,
  RESEND_TEAM_INVITATION,
  SAVE_PASSWORD,
  SEND_FOCUS_MODE_EVENT,
  UPDATE_AVATAR,
  UPDATE_PARTNER,
  UPDATE_PARTNER_LOGO,
  UPDATE_PROFILE,
  UPDATE_SHOW_FOCUS_MODE,
  UPDATE_TEAM,
  UPDATE_TEAM_SETTINGS,
  UPDATE_TIMEZONE,
} from "./User.types"
import {
  addTeamMemberFail,
  addTeamMemberSuccess,
  authenticateUserFail,
  authenticateUserSuccess,
  cancelTeamInviteFail,
  cancelTeamInviteSuccess,
  createAPIKeyFail,
  createAPIKeySuccess,
  createPartnerFail,
  createPartnerSuccess,
  createTeamFail,
  createTeamSuccess,
  deleteAPIKeyFail,
  deleteAPIKeySuccess,
  deleteTeamFail,
  deleteTeamSuccess,
  fetchPartnerFail,
  fetchPartnerSuccess,
  fetchTeamFail,
  fetchTeamSuccess,
  getAPIKeysFail,
  getAPIKeysSuccess,
  getStatusFail,
  getStatusSuccess,
  getTrialInfoFail,
  getUserDetailsFail,
  getUserDetailsSuccess,
  getUserFinishedTasksFail,
  getUserFinishedTasksSuccess,
  joinToTeamFail,
  joinToTeamSuccess,
  leaveTeamFail,
  leaveTeamSuccess,
  logoutAllDevicesFail,
  logoutAllDevicesSuccess,
  logoutFail,
  logoutStart,
  logoutSuccess,
  regenerateAPIKeyFail,
  regenerateAPIKeySuccess,
  registerUserFail,
  registerUserSuccess,
  removeMemberTeamFail,
  removeMemberTeamSuccess,
  removeTeamInvitationFail,
  removeTeamInvitationSuccess,
  resendTeamInvitationFail,
  resendTeamInvitationSuccess,
  resetPasswordFail,
  resetPasswordSuccess,
  savePasswordFail,
  savePasswordSuccess,
  updateAvatarFail,
  updateAvatarSuccess,
  updatePartnerFail,
  updatePartnerLogoFail,
  updatePartnerLogoSuccess,
  updatePartnerSuccess,
  updateProfileFail,
  updateProfileSuccess,
  UpdateTeamFail,
  updateTeamSettingsFail,
  updateTeamSettingsSuccess,
  UpdateTeamSuccess,
} from "./User.actions"
import { fetchPoints } from "../Activities/Activities.actions"

export function* watchUserSaga() {
  yield takeLatest(AUTHENTICATE_USER, authenticateUser)

  yield takeLatest(REGISTER_USER, registerUser)
  yield takeLatest(UPDATE_PROFILE, updateProfile)
  yield takeLatest(CREATE_TEAM, createTeam)
  yield takeLatest(DELETE_TEAM, deleteTeam)
  yield takeLatest(LEAVE_TEAM, leaveTeam)
  yield takeLatest(REMOVE_MEMBER_TEAM, removeMemberTeam)
  yield takeLatest(RESEND_TEAM_INVITATION, resendTeamInvitation)
  yield takeLatest(ADD_TEAM_MEMBER, addTeamMember)
  yield takeLatest(CREATE_PARTNER, createPartner)
  yield takeLatest(JOIN_TO_TEAM, joinToTeam)
  yield takeLatest(REMOVE_TEAM_INVITATION, removeTeamInvitation)
  yield takeLatest(CANCEL_TEAM_INVITE, cancelTeamInvitation)
  yield takeLatest(UPDATE_TEAM, updateTeam)
  yield takeLatest(FETCH_TEAM, fetchTeam)
  yield takeLatest(UPDATE_TEAM_SETTINGS, updateTeamSettings)
  yield takeLatest(UPDATE_AVATAR, updateAvatar)
  yield takeLatest(LOGOUT, logout)

  yield takeLatest(GET_STATUS, getStatus)

  yield takeLatest(PASSWORD_RESET, resetPassword)
  yield takeLatest(SAVE_PASSWORD, savePassword)

  yield takeLatest(GET_TRIAL_INFO, getTrialInfo)

  yield takeLatest(GET_USER_DETAILS, getUserDetails)

  yield takeLatest(GET_USER_FINISHED_TASKS, getUserFinishedTasks)

  yield takeLatest(UPDATE_TIMEZONE, updateTimezone)

  yield takeLatest(LOGOUT_ALL_DEVICES, logoutDevices)

  yield takeLatest(GET_API_KEYS, getAPIKeys)

  yield takeLatest(CREATE_API_KEY, createAPIKey)

  yield takeLatest(REGENERATE_API_KEY, regenerateAPIKey)

  yield takeLatest(DELETE_API_KEY, deleteAPIKey)

  yield takeLatest(UPDATE_SHOW_FOCUS_MODE, updateShowFocusMode)

  yield takeLatest(SEND_FOCUS_MODE_EVENT, sendFocusModeEvent)

  yield takeLatest(HIDE_ONBOARDING_FLOW, hideOnboardingFlow)

  yield takeLatest(UPDATE_PARTNER, updatePartner)

  yield takeLatest(FETCH_PARTNER, getPartner)
  yield takeLatest(UPDATE_PARTNER_LOGO, updatePartnerLogo)
}

function* authenticateUser(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/oauth/token",
        data: {
          username: action.email,
          password: action.password,
          otp: action.code,
          assertion: action.token,
          grant_type: action.token ? "assertion" : "password",
          provider: action.provider,
          nonce: action.nonce,
        },
        method: "POST",
      })
    )
    yield put(
      authenticateUserSuccess(
        data.id,
        data.email,
        data.first_name,
        data.last_name,
        data.avatar,
        data.access_token,
        data.refresh_token,
        data.role,
        data.phone_number,
        data.daily_recommendations,
        data.track_opened,
        data.bio,
        data.leaderboard_name,
        data.trial_days_remaining
      )
    )
    console.log(data)
    if (history.state?.from) {
      history.push(history.state.from)
    }

    if (data.show_onboarding_flow) {
      history.push("/dashboard?onboarding_step=0")
    } else {
      history.push("/dashboard")
    }
  } catch (error) {
    yield put(authenticateUserFail(error))
  }
}

function* registerUser(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/users/signup",
        data: {
          user: {
            first_name: action.first_name,
            last_name: action.last_name,
            email: action.email,
            password: action.password,
            referral: action.referral,
            referral_string: action.referral_string,
          },
        },
        method: "POST",
      })
    )
    yield put(
      registerUserSuccess(
        data.user.id,
        data.user.email,
        data.user.first_name,
        data.user.last_name,
        data.user.avatar
      )
    )
    yield* authenticateUser({ email: data.user.email, password: action.password })
  } catch (error) {
    yield put(registerUserFail(error))
  }
}

function* updateProfile(action) {
  const phone_number =
    action.values.number || action.values.code || action.values.short
      ? {
          number: action.values.number,
          code: action.values.code,
          short: action.values.short,
        }
      : undefined
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/users.json",
        data: {
          first_name: action.values.first_name,
          last_name: action.values.last_name,
          email: action.values.email,
          bio: action.values.bio,
          time_zone: action.values.time_zone,
          password_required: action.values.passwordRequired,
          current_password: action.values.current_password,
          password: action.values.password,
          phone_number: phone_number,
          hide_leaderboard: action.values.hide_leaderboard,
          daily_recommendations: action.values.daily_recommendations,
          track_opened: action.values.track_opened,
          leaderboard_name: action.values.leaderboard_name,
          public_username: action.values.public_username,
          trial_days_remaining: action.values.trial_days_remaining,
          profile_filler_sphere_id: action.values.profile_filler_sphere_id,
          onboarding_choices: action.values.onboarding_choices,
          hide_welcome_tab: action.values.hide_welcome_tab,
        },
        method: "PATCH",
      })
    )
    yield put(updateProfileSuccess(data.user))
    yield put(fetchPoints())
  } catch (error) {
    yield put(updateProfileFail(error))
  }
}

function* createTeam(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/teams",
        data: {
          name: action.name,
        },
        method: "POST",
      })
    )
    yield put(createTeamSuccess(data.team))
  } catch (error) {
    yield put(createTeamFail(error))
  }
}

function* deleteTeam(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(deleteTeamSuccess(action.id))
  } catch (error) {
    yield put(deleteTeamFail(error))
  }
}

function* leaveTeam(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/leave/${action.id}`,
        data: {
          member_id: action.member_id,
        },
        method: "DELETE",
      })
    )
    yield put(leaveTeamSuccess(action.id))
  } catch (error) {
    yield put(leaveTeamFail(error))
  }
}

function* removeMemberTeam(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/leave/${action.team_id}`,
        data: {
          member_id: action.member_id,
        },
        method: "DELETE",
      })
    )
    yield put(removeMemberTeamSuccess(action.team_id, action.member_id))
  } catch (error) {
    yield put(removeMemberTeamFail(error))
  }
}

function* resendTeamInvitation(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/resend_invitation/${action.team_id}`,
        params: {
          email: action.member_email,
        },
        method: "GET",
      })
    )
    yield put(resendTeamInvitationSuccess())
  } catch (error) {
    yield put(resendTeamInvitationFail(error))
  }
}

function* addTeamMember(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/add_member/${action.id}`,
        data: {
          email: action.email,
        },
        method: "POST",
      })
    )
    yield put(addTeamMemberSuccess(data.team))
  } catch (error) {
    yield put(addTeamMemberFail(error))
  }
}

function* createPartner(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/partners`,
        method: "POST",
        params: {
          user_id: action.user_id,
        },
      })
    )
    yield put(createPartnerSuccess(data.partner))
  } catch (error) {
    yield put(createPartnerFail(error))
  }
}

function* joinToTeam(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/join/${action.team_id}`,
        method: "PUT",
      })
    )
    yield put(joinToTeamSuccess(data.teams, data.team_invitations))
  } catch (error) {
    yield put(joinToTeamFail(error))
  }
}

function* removeTeamInvitation(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/remove_invitation/${action.team_id}`,
        method: "DELETE",
      })
    )
    yield put(removeTeamInvitationSuccess(data.team_invitations))
  } catch (error) {
    yield put(removeTeamInvitationFail(error))
  }
}

function* cancelTeamInvitation(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/cancel_invitation/${action.team_id}`,
        data: {
          email: action.member_email,
        },
        method: "DELETE",
      })
    )
    yield put(cancelTeamInviteSuccess(action.team_id, action.member_email))
  } catch (error) {
    yield put(cancelTeamInviteFail(error))
  }
}

function* updateTeamSettings(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/update_settings/${action.team_id}`,
        data: {
          new_settings: action.new_settings,
        },
        method: "PUT",
      })
    )
    yield put(updateTeamSettingsSuccess(action.team_id, action.new_settings))
  } catch (error) {
    yield put(updateTeamSettingsFail(error))
  }
}

function* fetchTeam(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/teams",
        method: "GET",
      })
    )
    yield put(fetchTeamSuccess(data.teams, data.team_invitations))
  } catch (error) {
    yield put(fetchTeamFail(error))
  }
}

function* updateTeam(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/teams/${action.id}`,
        data: {
          name: action.name,
        },
        method: "PATCH",
      })
    )
    yield put(UpdateTeamSuccess(data.teams))
  } catch (error) {
    yield put(UpdateTeamFail(error))
  }
}

function* updateAvatar(action) {
  try {
    const form_data = new FormData()
    form_data.append("avatar", action.avatar, action.avatar.name)
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/users.json",
        data: form_data,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${form_data._boundary}`,
        },
        method: "PATCH",
      })
    )
    yield put(updateAvatarSuccess(data.user))
  } catch (error) {
    yield put(updateAvatarFail(error))
  }
}

function* updatePartnerLogo(action) {
  try {
    const form_data = new FormData()
    form_data.append("logo", action.logo, action.logo.name)
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/partners/${action.partner_id}`,
        data: form_data,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${form_data._boundary}`,
        },
        method: "PATCH",
      })
    )
    yield put(updatePartnerLogoSuccess(data.partner))
  } catch (error) {
    yield put(updatePartnerLogoFail(error))
  }
}

function* logout(action) {
  try {
    yield delay(1500)
    if (action.force) {
      yield call(() => history.push("/login", { expired: true }))
    } else {
      yield call(() => history.push("/login"))
    }
    yield delay(1500)
    yield put(logoutStart())
    yield put(logoutSuccess())
  } catch (error) {
    yield put(logoutFail(error))
  }
}

function* getStatus() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "v1/users/status",
        method: "GET",
      })
    )
    yield put(
      getStatusSuccess(
        data.status,
        data.role,
        data.created_at,
        data.is_trialing,
        data.trial_days_remaining,
        data.billing_account_status,
        data.has_push_registration,
        data.partner,
        data.partner_logo,
        data.partner_welcome_videos
      )
    )
  } catch (error) {
    yield put(getStatusFail(error))
  }
}

function* updatePartner(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `v1/partners/${action.partner_id}`,
        method: "PATCH",
        data: action.data,
      })
    )
    yield put(updatePartnerSuccess(data.partner))
  } catch (error) {
    yield put(updatePartnerFail(error))
  }
}

function* getPartner(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `v1/partners/${action.partner_id}/get_partner_users`,
        method: "GET",
      })
    )
    yield put(fetchPartnerSuccess(data.partner_users))
  } catch (error) {
    yield put(fetchPartnerFail(error))
  }
}

function* updateShowFocusMode() {
  yield call(() =>
    axios.request({
      url: "v1/users/update_show_focus_mode",
      method: "PATCH",
      data: { show_focus_mode: false },
    })
  )
}

function* hideOnboardingFlow() {
  yield call(() =>
    axios.request({
      url: "v1/users/hide_onboarding_flow",
      method: "PATCH",
    })
  )
}

function* sendFocusModeEvent(action) {
  yield call(() =>
    axios.request({
      url: "v1/users/send_focus_mode_event",
      method: "POST",
      data: {
        focus_mode_event: {
          name: action.event_name,
          type: action.type_of_event,
        },
      },
    })
  )
}

function* resetPassword(action) {
  try {
    yield call(() =>
      axios.request({
        url: "users/password",
        method: "POST",
        data: { user: { email: action.email } },
      })
    )
    yield put(resetPasswordSuccess())
  } catch (error) {
    yield put(resetPasswordFail(error))
  }
}

function* savePassword(action) {
  try {
    yield call(() =>
      axios.request({
        url: `users/password`,
        method: "PUT",
        data: {
          user: { password: action.password, reset_password_token: action.token },
        },
      })
    )
    yield put(savePasswordSuccess())
    history.push("/login")
  } catch (error) {
    yield put(savePasswordFail(error))
  }
}

function* getTrialInfo(callback) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "v1/users/trial_extensions",
        method: "GET",
      })
    )
    callback(data)
  } catch (error) {
    yield put(getTrialInfoFail(error))
  }
}

function* getUserDetails() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "v1/users/my_details",
        method: "GET",
      })
    )
    yield put(
      getUserDetailsSuccess(
        data.id,
        data.email,
        data.first_name,
        data.last_name,
        data.avatar,
        data.phone_number,
        data.daily_recommendations,
        data.track_opened,
        data.bio,
        data.leaderboard_name,
        data.public_username,
        data.trial_days_remaining,
        data.time_zone,
        data.qr_code,
        data.profile_filler_sphere,
        data.api_key,
        data.show_focus_mode,
        data.partner,
        data.partner_logo,
        data.partner_welcome_videos,
        data.feature_flags,
        data.hide_welcome_tab
      )
    )
  } catch (error) {
    yield put(getUserDetailsFail(error))
  }
}

function* getUserFinishedTasks() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "v1/users/get_finished_tasks",
        method: "GET",
      })
    )
    yield put(getUserFinishedTasksSuccess(data.finished_tasks))
  } catch (error) {
    yield put(getUserFinishedTasksFail(error))
  }
}

function* updateTimezone(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/users/update_time_zone",
        data: {
          time_zone: action.time_zone,
        },
        method: "PATCH",
      })
    )
  } catch (error) {}
}

function* logoutDevices(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/users/logout_all_devices",
        method: "POST",
      })
    )
    yield put(logoutAllDevicesSuccess())
    yield call(() => history.push("/login"))
  } catch (error) {
    yield put(logoutAllDevicesFail(error))
  }
}

function* getAPIKeys() {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "v1/api_keys",
        method: "GET",
      })
    )
    yield put(getAPIKeysSuccess(data.api_keys))
  } catch (error) {
    yield put(getAPIKeysFail())
  }
}

function* createAPIKey(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "v1/api_keys",
        data: {
          key_name: action.key_name,
        },
        method: "POST",
      })
    )
    yield put(createAPIKeySuccess(data.api_keys))
  } catch (error) {
    yield put(createAPIKeyFail())
  }
}

function* regenerateAPIKey(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `v1/api_keys/regenerate/${action.id}`,
        method: "PATCH",
      })
    )
    yield put(regenerateAPIKeySuccess(data.api_keys))
  } catch (error) {
    yield put(regenerateAPIKeyFail())
  }
}

function* deleteAPIKey(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `v1/api_keys/${action.id}`,
        method: "DELETE",
      })
    )
    yield put(deleteAPIKeySuccess(data.api_keys))
  } catch (error) {
    yield put(deleteAPIKeyFail())
  }
}
