import React, { useEffect, useState } from "react"
import "./Forms.styles.scss"
import { useDispatch } from "react-redux"
import {
  authenticateUser,
  resetLoggingOutState,
  setRedirecting,
} from "../../../redux/User/User.actions"
import axios from "axios"
import { StringParam, useQueryParam } from "use-query-params"
import { nylasSignInURI } from "../../../config/nylas"
import GoogleIcon from "../../Icons/GoogleIcon.icons"
import Icon from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import MicrosoftIcon from "../../Icons/MicrosoftIcon.icons"
import { useLocation } from "react-router"
import { Drawer, Input, notification, Spin } from "antd"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const NylasAuthProvider = ({ action }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [code] = useQueryParam("code", StringParam)
  const [state] = useQueryParam("state", StringParam)
  const [microsoftLoginModalOpen, setMicrosoftLoginModalOpen] = useState(false)
  // const [outlookLogin, setOutlookLogin] = useState(false)
  // const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [provider, setProvider] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const detectProvider = async (email) => {
    const response = await axios.request({
      url: "/v1/nylas/detect_provider",
      method: "GET",
      params: {
        email,
      },
    })

    setProvider(response.data.provider)

    return {
      provider: response.data.provider,
      needs_reconnect: response.data.needs_reconnect,
    }
  }
  const signInWithNylas = async (payload) => {
    setLoading(true)
    dispatch(setRedirecting(true))

    await axios
      .request({
        url: "/v1/nylas/sign_in_and_connect",
        method: "GET",
        params: {
          provider: payload.provider,
          // email: email, // for outlook
          // password: password, // for outlook
          code: payload.credential,
          id_token: payload.id_token, // for microsoft
        },
      })
      .catch((error) => {
        dispatch(setRedirecting(false))
        setLoading(false)
        if (error.response?.status === 401) {
          notification.error({
            message: "Login",
            description:
              "Invalid credentials. Please try again or contact us if the problem persists.",
            className: "Notification-error",
          })
        } else {
          notification.error({
            message: "Login",
            description:
              "Something went wrong. Please try again or  contact us if the problem persists.",
            className: "Notification-error",
          })
          history.push("/login")
        }
      })
      .then((response) => {
        const data = response?.data
        if (data) {
          if (data?.id_token) {
            dispatch(authenticateUser(null, null, null, data.id_token, "google"))
          } else {
            if (payload.id_token) {
              dispatch(
                authenticateUser(null, null, null, payload.id_token, "microsoft")
              )
            }
          }
        }
      })
  }

  const getMicrosoftData = () => {
    const hash = new URLSearchParams(location.hash.replace("#", ""))

    const microsoft_code = hash.get("code")
    const microsoft_state = hash.get("state")
    const id_token = hash.get("id_token")

    setProvider(microsoft_state)

    return { microsoft_code, id_token, microsoft_state }
  }

  useEffect(() => {
    if (code && state) {
      signInWithNylas({ credential: code, provider: state })
    } else {
      const { microsoft_code, id_token, microsoft_state } = getMicrosoftData()

      if (microsoft_code && microsoft_state) {
        const needs_reconnect = localStorage.getItem("needs_reconnect")
        localStorage.removeItem("needs_reconnect")

        if (needs_reconnect === "false") {
          history.push("/login")
          dispatch(authenticateUser(null, null, null, id_token, "microsoft"))
          return
        }

        signInWithNylas({
          credential: microsoft_code,
          provider: microsoft_state,
          id_token: id_token,
        })
      }
    }
  }, [code, location.hash])

  const handleSubmit = () => {
    setLoading(true)
    detectProvider(email).then((response) => {
      nylasSignInURI(response.provider, null, (url) => {
        setLoading(false)
        window.location.href = url
      })
    })
  }

  return (
    <div className="LoginForm_AuthProvider">
      <Drawer
        title={null}
        visible={microsoftLoginModalOpen}
        closable={true}
        onClose={() => setMicrosoftLoginModalOpen(false)}
        width={"100vw"}
        style={{ zIndex: 5, opacity: 0.95 }}
        footer={null}
      >
        <RelatableLoader loading={loading}>
          <div className={"LoginForm_AuthProvider_DrawerContainer"}>
            <div className={"LoginForm_AuthProvider_DrawerContainer_InputWrapper"}>
              <h2>Please provide your email address</h2>
              <Input
                name={"email"}
                id={"email"}
                type={"email"}
                placeholder={"Email address"}
                onPressEnter={() => {
                  handleSubmit()
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/*{outlookLogin && (*/}
            {/*  <div className={"LoginForm_AuthProvider_DrawerContainer_InputWrapper"}>*/}
            {/*    <div*/}
            {/*      className={*/}
            {/*        "LoginForm_AuthProvider_DrawerContainer_InputWrapper_Description"*/}
            {/*      }*/}
            {/*    >*/}
            {/*      In order to connect <b>Relatable</b> to your inbox, we need an app*/}
            {/*      specific password. This is a random text generated in your*/}
            {/*      Microsoft settings page. Your main password won't work.*/}
            {/*    </div>*/}
            {/*    <h2>Please provide an app specific password.</h2>*/}

            {/*    <Input*/}
            {/*      type={"password"}*/}
            {/*      placeholder={"App specific password"}*/}
            {/*      value={password}*/}
            {/*      onChange={(e) => setPassword(e.target.value)}*/}
            {/*    />*/}
            {/*    <a*/}
            {/*      target={"_blank"}*/}
            {/*      rel={"noopener noreferrer"}*/}
            {/*      href={*/}
            {/*        "https://support.microsoft.com/en-us/account-billing/using-app-passwords-with-apps-that-don-t-support-two-step-verification-5896ed9b-4263-e681-128a-a6f2979a7944"*/}
            {/*      }*/}
            {/*    >*/}
            {/*      Learn how to set up an app specific password.*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*outlookLogin*/}
            {/*  <div>*/}
            {/*    <button*/}
            {/*      type={"button"}*/}
            {/*      disabled={email === ""}*/}
            {/*      className="LoginForm_Button LoginForm_Button--Provider"*/}
            {/*      onClick={() => {*/}
            {/*        setLoading(true)*/}
            {/*        const { microsoft_code, id_token } = getMicrosoftData()*/}
            {/*        signInWithNylas({*/}
            {/*          provider: provider,*/}
            {/*          email,*/}
            {/*          credential: microsoft_code,*/}
            {/*          id_token,*/}
            {/*        })*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Continue*/}
            {/*    </button>*/}
            {/*    <button*/}
            {/*      type={"button"}*/}
            {/*      className="LoginForm_Button LoginForm_Button--Link"*/}
            {/*      onClick={() => {*/}
            {/*        setLoading(true)*/}
            {/*        const { id_token } = getMicrosoftData()*/}
            {/*        history.push("/login")*/}

            {/*        dispatch(*/}
            {/*          authenticateUser(null, null, null, id_token, "microsoft")*/}
            {/*        )*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Skip for now*/}
            {/*    </button>*/}
            {/*  </div>*/}
            <button
              type={"button"}
              disabled={email === ""}
              className="LoginForm_Button LoginForm_Button--Provider"
              onClick={() => {
                handleSubmit()
              }}
            >
              Continue
            </button>
          </div>
        </RelatableLoader>
      </Drawer>

      <button
        type={"button"}
        className="LoginForm_Button LoginForm_Button--Provider"
        onClick={() =>
          nylasSignInURI("google", null, (url) => {
            window.location.href = url
          })
        }
      >
        <Icon component={GoogleIcon} />
        <span>{action} with Google</span>
      </button>
      <button
        type={"button"}
        className="LoginForm_Button LoginForm_Button--Provider"
        onClick={() => {
          nylasSignInURI("microsoft", null, (url) => {
            window.location.href = url
          })
        }}
      >
        <Icon component={MicrosoftIcon} />
        <span>{action} with Microsoft</span>
      </button>
    </div>
  )
}

export default NylasAuthProvider
