import React, { useEffect, useRef, useState } from "react"
import "./Spheralizer.styles.scss"
import { Button, Col, Row } from "antd"
import { animated, interpolate, useSprings } from "@react-spring/web"
import { CloseCircleOutlined } from "@ant-design/icons"
import moment from "moment"
import getUncategorized from "../api/getUncategorized.api"
import dismissContact from "../api/dismissContact.api"
import addToSpheres from "../api/addToSpheres.api"
import { useDispatch, useSelector } from "react-redux"
import { fetchSpheres } from "../../../redux/Collections/Collections.actions"

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
})
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`

const SpheralizerPage = () => {
  const loadingSpheres = useSelector((state) => state.CollectionsState.loading)
  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const [queue, setQueue] = useState([])
  const [currentPosition, setCurrentPosition] = useState(-1)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [filterTerm, setFilterTerm] = useState("")
  const [highlightedSphereIndex, setHighlightedSphereIndex] = useState(0)
  const [showNewSphereInput, setShowNewSphereInput] = useState(false)
  const nextButton = useRef()
  const dismissButton = useRef()
  const filterTermInput = useRef()
  const upButton = useRef()
  const downButton = useRef()
  const highlightedButton = useRef()
  const newSphereInput = useRef()
  const newSphereButton = useRef()
  const user = useSelector((state) => state.UserState)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSpheres())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (queue && queue.length > 0 && currentPosition < 0) {
      console.log("setting queue to ", currentPosition + 1)
      setCurrentPosition(currentPosition + 1)
    }
    // eslint-disable-next-line
  }, [queue])

  useEffect(() => {
    console.log("getting more uncategorized")
    const sources = "source: threads, sms and calls, phone book, contacts, linkedin"
    getUncategorized(currentPage, [sources], (data) => {
      console.log("Got the uncategorized!", data)
      setQueue([...queue, ...data.participants])
    })
    // eslint-disable-next-line
  }, [currentPage])

  const playAgain = () => {
    setQueue([])
    setCurrentPage(currentPage + 1)
    setCurrentPosition(-1)
  }

  const currentRecord =
    currentPosition > -1 && queue.length > currentPosition
      ? queue[queue.length - currentPosition - 1]
      : null

  const goBack = () => {
    if (currentPosition !== 0) {
      let index = queue.length - currentPosition
      setCurrentPosition(currentPosition - 1)
      console.log(
        "ASKED TO GO BACK ",
        currentPosition,
        queue.length - currentPosition - 1
      )
      api.start((i) => {
        console.log(index, i)
        if (index !== i) return // We're only interested in changing spring-data for the current spring
        return {
          ...to(i),
          delay: 100,
        }
      })
    }
  }

  const goNext = () => {
    setFilterTerm("")
    setHighlightedSphereIndex(0)
    setCurrentPosition(currentPosition + 1)

    let index = queue.length - currentPosition - 1
    api.start((i) => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring
      const x = window.innerWidth + 100 * Math.random()
      const rot = 360 * Math.random()
      const scale = 1.3
      return {
        x,
        rot,
        scale,
        delay: 100,
        config: { friction: 50, tension: 300 },
      }
    })
  }

  const dismiss = () => {
    if (currentRecord) {
      dismissContact(currentRecord.id, (data) => {
        console.log("Dimissed", data)
      })
    }
    goNext()
  }

  const addToSphereAndNext = (sphereId, newSphereName) => {
    if (newSphereName) {
      setLoading(true)
    }
    const spheres = newSphereName ? [{ title: newSphereName }] : [{ id: sphereId }]
    addToSpheres(currentRecord.id, spheres, (data) => {
      if (newSphereName) {
        dispatch(fetchSpheres())
        setLoading(false)
      }
    })
    goNext()
  }

  const downHandler = ({ key, code }) => {
    if (document.activeElement !== newSphereInput.current) {
      if (/^[a-z]+$/.test(key)) {
        filterTermInput.current.value = filterTermInput.current.value + "" + key
        setFilterTerm(filterTermInput.current.value)
        setHighlightedSphereIndex(0)
      } else if (code === "Backspace") {
        filterTermInput.current.value = filterTermInput.current.value.slice(0, -1)
        setFilterTerm(filterTermInput.current.value)
      } else if (code === "Space") {
        nextButton.current.click()
      } else if (code === "ShiftRight" || code === "ShiftLeft") {
        dismissButton.current.click()
      } else if (code === "ArrowUp") {
        downButton.current.click()
      } else if (code === "ArrowDown") {
        upButton.current.click()
      } else if (code === "Enter") {
        highlightedButton.current.click()
      }
    } else if (code === "Enter") {
      newSphereButton.current.click()
    }
  }

  const selectUp = () => {
    const newHSI = highlightedSphereIndex + 1
    setHighlightedSphereIndex(newHSI)
  }

  const selectDown = () => {
    setHighlightedSphereIndex(Math.max(highlightedSphereIndex - 1, 0))
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  const [props_, api] = useSprings(queue.length, (i) => ({
    ...to(i),
    from: from(i),
  })) // Create a bunch of springs using the helpers above

  console.log("Current record is ", currentRecord)

  const addNewSphere = () => {
    const newSphereName = newSphereInput.current.value
    addToSphereAndNext(null, newSphereName)

    newSphereInput.current.value = ""
    setShowNewSphereInput(false)
  }

  return (
    <div className="game game-spheralizer">
      <Row className="nav">
        <a className="closeButton" href="/database">
          <CloseCircleOutlined />
        </a>
        <img
          src={`${process.env.PUBLIC_URL}/primary.svg`}
          alt="Relatable"
          className="logo"
        />
      </Row>
      <Row>
        <Col span="16">
          <Row>
            <div id="game-area">
              <div id="card-area">
                {currentPosition >= queue.length && (
                  <div className="restart-button">
                    <div onClick={playAgain} className="restart-button-button">
                      All done! Play again?
                    </div>
                  </div>
                )}
                {props_.map(({ x, y, rot, scale }, i) => (
                  <animated.div className="card" key={i} style={{ x, y }}>
                    {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
                    <animated.div
                      className="card-inner"
                      style={{
                        transform: interpolate([rot, scale], trans),
                      }}
                    >
                      <div className="card-body">
                        <div class="avatar-placeholder"></div>
                        {queue[i].full_name ? (
                          <h3>
                            {queue[i].full_name}
                            <span>{queue[i].email}</span>
                          </h3>
                        ) : (
                          <h3>{queue[i].email}</h3>
                        )}
                        <hr />
                        <div class="conversation">
                          <h4>
                            {moment(queue[i].last_sent)
                              .tz(user.time_zone)
                              .format("MMM Do, YYYY")}
                          </h4>
                          <div className="subject">
                            {" "}
                            {queue[i].subject &&
                              queue[i].subject
                                .replace(/<\/?[^>]+(>|$)/g, "")
                                .substring(0, 50)}{" "}
                          </div>
                          <div className="body">
                            {" "}
                            {queue[i].body &&
                              queue[i].body
                                .replace(/<\/?[^>]+(>|$)/g, "")
                                .substring(0, 100)}
                            ...{" "}
                          </div>
                        </div>
                      </div>
                    </animated.div>
                  </animated.div>
                ))}
              </div>
            </div>
          </Row>
          <Row>
            <div className="controls">
              <Button onClick={goBack}>
                &lt; Go Back<small>&nbsp;</small>
              </Button>
              <Button onClick={dismiss} ref={dismissButton}>
                Dismiss<small>shift</small>
              </Button>
              <Button onClick={goNext} ref={nextButton}>
                Next &gt;<small>spacebar</small>
              </Button>
            </div>
          </Row>
        </Col>
        <Col span="8">
          <Row className="filterRow">
            <label className="ant-col-10">type to filter: </label>
            <input
              type="text"
              name="filterTerm"
              className="ant-col-14"
              ref={filterTermInput}
              value={filterTerm}
              onChange={(e) => console.log(e)}
            />
          </Row>
          <small className="general-instructions">
            arrow keys up/down to move, enter/click to select
          </small>

          <Button onClick={selectUp} ref={upButton} className="hidden">
            selectUp
          </Button>
          <Button onClick={selectDown} ref={downButton} className="hidden">
            selectDown
          </Button>
          {!loadingSpheres && !loading && spheres && spheres.length > 0 ? (
            <>
              <div className="spheresSection">
                {spheres
                  .filter(
                    (sphere) =>
                      filterTerm.length === 0 ||
                      sphere.title.match(new RegExp(`${filterTerm}`, "i"))
                  )
                  .map((sphere, index) => (
                    <>
                      <Button
                        className="option-button"
                        onClick={() => addToSphereAndNext(sphere.id)}
                        ref={
                          index === highlightedSphereIndex ? highlightedButton : null
                        }
                        type={
                          index === highlightedSphereIndex ? "primary" : "default"
                        }
                      >
                        <div className="element">
                          <div className="truncate">{sphere.title}</div>
                        </div>
                      </Button>
                    </>
                  ))}
                {showNewSphereInput ? (
                  <div className="addNewSphere">
                    <input
                      placeholder="New Sphere"
                      className="addNewSphere_Input option-button"
                      ref={newSphereInput}
                      autoFocus={true}
                    />
                    <Button
                      className="addNewSphere_Button option-button"
                      onClick={addNewSphere}
                      ref={newSphereButton}
                    >
                      Add
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="option-button"
                    onClick={() => setShowNewSphereInput(true)}
                    ref={
                      spheres.filter(
                        (sphere) =>
                          filterTerm.length === 0 ||
                          sphere.title.match(new RegExp(`${filterTerm}`, "i"))
                      ).length === highlightedSphereIndex
                        ? highlightedButton
                        : null
                    }
                    type={
                      spheres.filter(
                        (sphere) =>
                          filterTerm.length === 0 ||
                          sphere.title.match(new RegExp(`${filterTerm}`, "i"))
                      ).length === highlightedSphereIndex
                        ? "primary"
                        : "default"
                    }
                  >
                    Add New Sphere
                  </Button>
                )}
              </div>
            </>
          ) : (
            <h3 style={{ marginLeft: "20px" }}>Loading Spheres...</h3>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default SpheralizerPage
