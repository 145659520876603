import React, { useState, useCallback, useEffect } from "react"
import { Table, Typography, Empty, Skeleton, Popover } from "antd"
import moment from "moment"
import axios from "axios"
import Avatar from "../../UI/Avatar/Avatar.component"
import SpheresSelector from "../../Database/SpheresSelector/SpheresSelector.component"
import MdClose from "react-ionicons/lib/MdClose"
import * as _ from "lodash"
import "./FadedConvos.styles.scss"
import {
  addParticipantsToSpheres,
  archiveParticipants,
} from "../../../redux/Participants/Participants.actions"
import { useDispatch, useSelector } from "react-redux"

const { Text, Paragraph } = Typography

const LoadingSkeleton = () => (
  <div>
    <Skeleton.Input style={{ width: "100%" }} active size="small" />
    <Skeleton.Input style={{ width: "100%" }} active size="small" />
  </div>
)

const MessageCell = ({ details, loading, expanded }) => {
  if (loading) {
    return <LoadingSkeleton />
  }

  return (
    <div style={{ marginLeft: "12px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          maxWidth: "150px",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div className="FadedConvos_MessageColumn_Body">
          {details?.last_message?.subject && (
            <span className="FadedConvos_MessageColumn_Subject">
              {details.last_message.subject}
            </span>
          )}
          <span className="FadedConvos_MessageColumn_Body_Text">
            {_.truncate(details?.last_message?.body, {
              length: expanded ? 9999 : 100,
            })}
          </span>
        </div>
        <span className="FadedConvos_MessageColumns_Date">
          {moment
            .unix(details?.last_message?.timestamp || details.last_message_at)
            .format("D MMM YYYY")}
        </span>
      </div>
    </div>
  )
}

const ContactDetails = ({ record, userEmail, children }) => {
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false)

  const fetchDetails = useCallback(async () => {
    if (hasAttemptedLoad) return

    setLoading(true)
    setHasAttemptedLoad(true)

    try {
      const response = await axios.request({
        url: "/v1/nylas/get_faded_contact_details",
        method: "GET",
        params: {
          participant_id: record.participant_id,
          email: userEmail,
          contact_email: record.contact.email,
        },
      })
      setDetails(response.data)
    } catch (error) {
      console.error("Failed to fetch contact details:", error)
    } finally {
      setLoading(false)
    }
  }, [userEmail, record.contact.email, hasAttemptedLoad])

  useEffect(() => {
    fetchDetails()
  }, [fetchDetails])

  return children(details, loading)
}

const FadedConvos = ({ participants, userEmail }) => {
  const [expandedRows, setExpandedRows] = useState(new Set())
  const [tableData, setTableData] = useState([])
  const dispatch = useDispatch()
  const { fetching } = useSelector((state) => state.ParticipantsState)

  useEffect(() => {
    setTableData(
      participants?.map((p) => ({
        ...p,
        expandable: p.body?.length > 70,
        expanded: false,
      })) || []
    )
  }, [participants])

  const handleRowClick = (record) => {
    if (record.expandable) {
      setExpandedRows((prev) => {
        const newSet = new Set(prev)
        if (newSet.has(record.contact.email)) {
          newSet.delete(record.contact.email)
        } else {
          newSet.add(record.contact.email)
        }
        return newSet
      })
    }
  }

  const handleAddToSphere = async (record, existingSpheres, newSpheres) => {
    const existing = existingSpheres.filter((el) => el.id !== null)
    const created = newSpheres.filter((el) => el.title !== null)
    if (existing.length || created.length) {
      dispatch(addParticipantsToSpheres([record.participant_id], existing, created))
      setTableData((prev) =>
        prev.filter((p) => p.contact.email !== record.contact.email)
      )
    }
  }

  const handleDismiss = async (record) => {
    dispatch(archiveParticipants([record.participant_id]))
    setTableData((prev) =>
      prev.filter((p) => p.contact.email !== record.contact.email)
    )
  }

  if (!participants?.length) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Empty
          description="No dormant contacts found"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </div>
    )
  }

  const columns = [
    {
      className: "name",
      key: "full_name",
      render: (record) => (
        <ContactDetails record={record} userEmail={userEmail}>
          {(details, loading) => (
            <div className="FadedConvos_Name">
              <div>
                <div>
                  <span className="FadedConvos_Name_FullName">
                    {details?.contact?.full_name ||
                      record.contact?.full_name ||
                      record.contact.email}
                  </span>
                  <span className="FadedConvos_Name_Email">
                    {record.contact.email}
                  </span>
                </div>
              </div>
            </div>
          )}
        </ContactDetails>
      ),
    },
    {
      className: "FadedConvos_MessageColumn",
      key: "message",
      render: (record) => (
        <ContactDetails record={record} userEmail={userEmail}>
          {(details, loading) =>
            loading ? (
              <LoadingSkeleton />
            ) : details?.last_message?.body || details?.last_message?.subject ? (
              <MessageCell
                details={details}
                loading={loading}
                expanded={expandedRows.has(record.contact.email)}
              />
            ) : (
              <Empty
                description={"Message has no subject or body."}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ height: 30 }}
              />
            )
          }
        </ContactDetails>
      ),
    },
    {
      width: "50%",
      className: "actions",
      key: "actions",
      render: (record) => (
        <div className="FadedConvos_ActionsColumn">
          <SpheresSelector
            collapsible
            clearAfterSubmit
            alreadySelected={[]}
            drawerVisible={true}
            handleSubmit={(existingSpheres, newSpheres) =>
              handleAddToSphere(record, existingSpheres, newSpheres)
            }
          />
          <Popover
            title={
              <div className="DismissPopover_Title">
                <span>What does Dismiss do?</span>
              </div>
            }
            overlayClassName="DismissPopover"
            content={
              <div className="DismissPopover_Content">
                <span>
                  We'll hide this unknown person from your list. Don't worry, their
                  information doesn't go away - if you want to add this person to
                  your database, just create a contact with their email address and
                  we'll bring this back.
                </span>
              </div>
            }
          >
            <div
              className="FadedConvos_ArchiveButton"
              onClick={() => handleDismiss(record)}
            >
              <MdClose fontSize="19px" color="#A1AAC8" />
              <span>Dismiss</span>
            </div>
          </Popover>
        </div>
      ),
    },
  ]

  return (
    <div className="FadedConvos">
      <Table
        loading={fetching}
        rowKey={(record) => record.contact.email}
        className="FadedConvos_Table"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          className: expandedRows.has(record.contact.email) ? "expanded" : "",
        })}
      />
    </div>
  )
}

export default React.memo(FadedConvos)
