import React, { useEffect, useState } from "react"
import "./SphereModal.styles.scss"
import { Button, Input, Modal, Tabs } from "antd"
import { useDispatch, useSelector } from "react-redux"

import SphereSharing from "./SphereSharing/SphereSharing.component"
import SphereReminders from "./SphereReminders/SphereReminders.component"
import { setSphereModalVisible } from "../../../redux/App/App.actions"
import {
  createSphere,
  deleteSphere,
  fetchCollection,
  fetchCollectionSuccess,
  renameSphere,
} from "../../../redux/Collections/Collections.actions"
import { EditOutlined } from "@ant-design/icons"
import ConfirmModal from "../BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"
import { fetchTeam } from "../../../redux/User/User.actions"

const SphereModal = () => {
  const dispatch = useDispatch()

  const sphereModalVisible = useSelector(
    (state) => state.AppState.sphereModalVisible
  )
  const activeSphereId = useSelector((state) => state.AppState.activeSphereId)
  const defaultSphereModalTab = useSelector((state) => state.AppState.sphereModalTab)

  const sphere = useSelector((state) => state.CollectionsState.collection)

  const [sphereNameEditing, setSphereNameEditing] = useState(null)

  useEffect(() => {
    if (sphereModalVisible && activeSphereId) {
      dispatch(fetchTeam())
      dispatch(fetchCollection("sphere", activeSphereId))
      setCurrentTab(defaultSphereModalTab)
    }
    if (sphereModalVisible && !activeSphereId) {
      dispatch(fetchCollectionSuccess(null, null))
      setSphereName("Simple Sphere")
    }
    // eslint-disable-next-line
  }, [sphereModalVisible])

  useEffect(() => {
    if (sphere) {
      setSphereName(sphere.title)
    }
  }, [sphere])

  const [currentTab, setCurrentTab] = useState(defaultSphereModalTab)
  const [sphereName, setSphereName] = useState(null)
  const [sphereSharing, setSphereSharing] = useState({})
  const [deleteSphereConfirmVisible, setDeleteSphereConfirmVisible] = useState(false)

  const handleRenameSphere = () => {
    if (sphere?.id) {
      dispatch(renameSphere(sphere.id, sphereName))
      dispatch(setSphereModalVisible(false, null))
    }
    setSphereNameEditing(false)
  }

  const renderFooter = () => {
    return (
      <div className={"SphereModal_FooterContainer"}>
        {activeSphereId ? (
          <span
            className={"SphereModal_DeleteButton"}
            onClick={() => setDeleteSphereConfirmVisible(true)}
          >
            Delete Sphere
          </span>
        ) : (
          <span
            className={"SphereModal_CreateButton"}
            onClick={() => {
              dispatch(createSphere(null, sphereName))
            }}
          >
            Create Sphere
          </span>
        )}
      </div>
    )
  }

  return (
    <Modal
      width={"30vw"}
      className={"SphereModal"}
      footer={renderFooter()}
      onCancel={() => dispatch(setSphereModalVisible(false, null))}
      visible={!!sphereModalVisible}
      destroyOnClose={true}
    >
      <RelatableLoader loading={!sphere && activeSphereId}>
        <div className={"SphereModal_Content"}>
          <div className={"SphereModal_TitleContainer"}>
            {sphereNameEditing ? (
              <>
                <Input
                  autoFocus={true}
                  className={"SphereModal_TitleInput"}
                  value={sphereName}
                  onChange={(e) => setSphereName(e.target.value)}
                />
                <Button
                  className={
                    sphereName === sphere?.title
                      ? "SphereModal_SaveButton--disabled"
                      : "SphereModal_SaveButton"
                  }
                  onClick={handleRenameSphere}
                  disabled={sphereName === sphere?.title}
                >
                  Save
                </Button>
                <Button
                  className={"SphereModal_CancelButton"}
                  onClick={() => setSphereNameEditing(false)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <span className="SphereModal_Title">{sphereName}</span>
                <EditOutlined
                  className="SphereModal_EditIcon"
                  onClick={() => setSphereNameEditing(true)}
                />
              </>
            )}
          </div>
          <div className={"SphereModal_TabsContainer"}>
            {sphere && (
              <Tabs
                className={"SphereModal_Tabs"}
                activeKey={currentTab}
                defaultActiveKey={"reminders"}
                onTabClick={(e) => {
                  setCurrentTab(e)
                }}
              >
                <Tabs.TabPane tab={<span>Reminders</span>} key="reminders">
                  <SphereReminders />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>Sharing</span>} key="sharing">
                  <SphereSharing
                    onUpdate={(data) =>
                      setSphereSharing({ ...sphereSharing, ...data })
                    }
                    sphere={sphere}
                  />
                </Tabs.TabPane>
              </Tabs>
            )}
          </div>
        </div>
        <ConfirmModal
          title={`Are you sure you want to delete sphere?`}
          description={`Removing ${sphereName} will archive all the contacts that don't belong to any sphere.`}
          visible={deleteSphereConfirmVisible}
          onCancel={() => setDeleteSphereConfirmVisible(false)}
          onConfirm={() => dispatch(deleteSphere(sphere.id))}
        />
      </RelatableLoader>
    </Modal>
  )
}

export default SphereModal
