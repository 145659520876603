import { call, delay, put, takeLatest } from "redux-saga/effects"
import axios from "axios"
import {
  ADD_EXTRA_TRIAL_DAYS,
  BACK_TO_ADMIN,
  CREATE_CHALLENGE,
  FETCH_CHALLENGES,
  FETCH_USERS,
  SEND_WARNING_EMAIL,
  SET_AS_FREE_ACCOUNT,
  TAKE_SESSION,
  UPDATE_CHALLENGE,
  WIPE_ACCOUNTS,
  TOGGLE_USER_FEATURE,
  TOGGLE_USER_FEATURE_SUCCESS,
  TOGGLE_USER_FEATURE_FAIL,
} from "./Admin.types"
import {
  addExtraTrialDaysFail,
  addExtraTrialDaysSuccess,
  backToAdminFail,
  backToAdminSuccess,
  createChallengeFail,
  createChallengeSuccess,
  fetchChallengesFail,
  fetchChallengesSuccess,
  fetchUsersFail,
  fetchUsersSuccess,
  sendWarningEmailFail,
  sendWarningEmailSuccess,
  setAsFreeAccountFail,
  setAsFreeAccountSuccess,
  takeSessionFail,
  takeSessionSuccess,
  updateChallengeFail,
  updateChallengeSuccess,
  wipeAccountsFail,
  wipeAccountsSuccess,
  fetchUsers,
  toggleUserFeatureSuccess,
  toggleUserFeatureFail,
} from "./Admin.actions"
import {
  authenticateUserSuccess,
  logoutStart,
  logoutSuccess,
  setRedirecting,
} from "../User/User.actions"
import history from "../../history"

export function* watchAdminSaga() {
  yield takeLatest(FETCH_USERS, _fetchUsers)
  yield takeLatest(TAKE_SESSION, takeSession)
  yield takeLatest(BACK_TO_ADMIN, backToAdmin)
  yield takeLatest(SET_AS_FREE_ACCOUNT, setAsFreeAccount)
  yield takeLatest(ADD_EXTRA_TRIAL_DAYS, addExtraTrialDays)
  yield takeLatest(FETCH_CHALLENGES, fetchChallenges)
  yield takeLatest(CREATE_CHALLENGE, createChallenge)
  yield takeLatest(UPDATE_CHALLENGE, updateChallenge)
  yield takeLatest(WIPE_ACCOUNTS, wipeAccounts)
  yield takeLatest(SEND_WARNING_EMAIL, sendWarningEmail)
  yield takeLatest(TOGGLE_USER_FEATURE, toggleUserFeature)
}

function* _fetchUsers(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/admin",
        params: {
          page: action.page,
          per_page: action.per_page,
          query: action.query,
          scope: "admin",
          filters: action.filters,
          order: action.order,
          sort: action.sort_field,
        },
        method: "GET",
      })
    )
    yield put(fetchUsersSuccess(data.users, data.total_entries))
  } catch (e) {
    yield put(fetchUsersFail(e))
  }
}

function* setAsFreeAccount(action) {
  try {
    yield call(() =>
      axios.request({
        url: `/v1/users/${action.id}/set_as_free_account`,
        method: "POST",
      })
    )
    yield put(setAsFreeAccountSuccess())
  } catch (e) {
    yield put(setAsFreeAccountFail(e))
  }
}

function* takeSession(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "v1/admin/take_session",
        data: {
          username: action.email,
        },
        method: "POST",
      })
    )
    yield put(logoutStart())
    yield delay(1500)
    yield call(() => history.push("/redirect"))
    yield put(setRedirecting(true))

    yield put(logoutSuccess())
    yield put(
      authenticateUserSuccess(
        data.id,
        data.email,
        data.first_name,
        data.last_name,
        data.avatar,
        data.access_token,
        data.refresh_token,
        data.role,
        data.phone_number,
        data.daily_recommendations,
        data.track_opened,
        data.bio,
        data.leaderboard_name
      )
    )
    yield call(() => history.push("/dashboard"))
    yield put(takeSessionSuccess(action.admin_refresh_token, true))
  } catch (e) {
    yield put(takeSessionFail(e))
  }
}

function* backToAdmin(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/oauth/token",
        params: {
          refresh_token: action.admin_refresh_token,
          grant_type: "refresh_token",
        },
        method: "POST",
      })
    )
    yield put(logoutStart())
    yield delay(1500)
    yield call(() => history.push("/redirect"))
    yield put(setRedirecting(true))

    yield put(logoutSuccess())
    yield put(
      authenticateUserSuccess(
        data.id,
        data.email,
        data.first_name,
        data.last_name,
        data.avatar,
        data.access_token,
        data.refresh_token,
        data.role,
        data.phone_number,
        data.daily_recommendations,
        data.track_opened,
        data.bio,
        data.leaderboard_name
      )
    )
    yield call(() => history.push("/admin"))
    yield put(backToAdminSuccess())
  } catch (e) {
    yield put(backToAdminFail(e))
  }
}

function* addExtraTrialDays(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/trial_extensions/add_extra_trial_days",
        params: {
          user_id: action.user_id,
          number_of_days: action.number_of_days,
        },
        method: "POST",
      })
    )
    yield put(
      addExtraTrialDaysSuccess(
        action.user_id,
        data.trial_days_remaining,
        data.user_status
      )
    )
  } catch (e) {
    yield put(addExtraTrialDaysFail(e))
  }
}

function* fetchChallenges(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/challenges",
        method: "GET",
      })
    )
    yield put(fetchChallengesSuccess(data.challenges))
  } catch (e) {
    yield put(fetchChallengesFail(e))
  }
}

function* createChallenge(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: "/v1/challenges",
        data: action.challenge,
        method: "POST",
      })
    )
    yield put(createChallengeSuccess(data.challenge))
  } catch (e) {
    yield put(createChallengeFail(e))
  }
}

function* updateChallenge(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/challenges/${action.id}`,
        data: action.challenge,
        method: "PUT",
      })
    )
    yield put(updateChallengeSuccess(data.challenge))
  } catch (e) {
    yield put(updateChallengeFail(e))
  }
}

function* wipeAccounts(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/admin/wipe_accounts",
        method: "POST",
        data: {
          ids: action.ids,
        },
      })
    )
    yield put(wipeAccountsSuccess())
    yield put(fetchUsers(1, 10, "", "admin"))
  } catch (e) {
    yield put(wipeAccountsFail(e))
  }
}

function* sendWarningEmail(action) {
  try {
    yield call(() =>
      axios.request({
        url: "/v1/admin/send_warning_email",
        method: "POST",
        data: action.ids,
      })
    )
    yield put(sendWarningEmailSuccess())
    yield put(fetchUsers(1, 10, "", "admin"))
  } catch (e) {
    yield put(sendWarningEmailFail(e))
  }
}

function* toggleUserFeature(action) {
  try {
    const { data } = yield call(() =>
      axios.request({
        url: `/v1/admin/feature_flags/toggle_feature`,
        method: "POST",
        params: {
          user_id: action.user_id,
          feature_key: action.feature_key,
          enabled: action.enabled,
        },
      })
    )
    yield put(toggleUserFeatureSuccess(action.user_id, action.feature_key, data.enabled))
  } catch (e) {
    yield put(toggleUserFeatureFail(e))
  }
}
