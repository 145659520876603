import React, { useEffect, useState } from "react"
import * as _ from "lodash"
import "./FiltersDrawer.styles.scss"
import { Drawer } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params"
import {
  fetchCompanies,
  fetchExpertises,
  fetchLocations,
  fetchPopularCompanies,
  fetchPopularExpertises,
  fetchPopularLocations,
  fetchPopularSources,
  fetchPopularTopics,
  fetchSources,
  fetchSpheres,
  fetchTopics,
} from "../../../redux/Collections/Collections.actions"
import MultiSelectWithList from "./components/MultiSelectWithList/MultiSelectWithList.component"
import ListWithCheckbox from "./components/ListWithCheckbox/ListWithCheckbox.component"
import RangeDatePicker from "./components/RangeDatePicker/RangeDatePicker.component"
import SectionWithFill from "../../UI/SectionWithFill/SectionWithFill.component"

const importanceArray = [
  { title: "Very important", id: "very_important", level: "mighty" },
  {
    title: "Important",
    id: "important",
    level: "strong",
  },
  { title: "Medium", id: "medium", level: "average" },
  {
    title: "Not important",
    id: "not_important",
    level: "weak",
  },
  { title: "N/A", id: "na", level: "na" },
]

const strengthArray = [
  { title: "Very strong", id: "very_strong", level: "mighty" },
  {
    title: "Strong",
    id: "strong",
    level: "strong",
  },
  { title: "Average", id: "average", level: "average" },
  { title: "Weak", id: "weak", level: "weak" },
  {
    title: "N/A",
    id: "na",
    level: "na",
  },
]

const engagementArray = [
  { title: "Recently contacted", id: "hide_good", level: "strong" },
  { title: "Potentially fading relationship", id: "hide_fade", level: "average" },
  { title: "Frequency not set", id: "hide_none", level: "na" },
]

const optionsArray = [
  {
    title: "Has active tasks",
    id: "true",
    field: "have_active_task",
    level: "weak",
  },
  {
    title: "Include archived contacts",
    id: "active,archived",
    field: "status",
    level: "na",
  },
]

const FiltersDrawerContent = () => {
  const dispatch = useDispatch()
  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const teamSharedSpheres = useSelector(
    (state) => state.CollectionsState.team_shared_spheres
  )
  const topics = useSelector((state) => state.CollectionsState.topics)
  const popular_topics = useSelector(
    (state) => state.CollectionsState.popular_topics
  )
  const expertises = useSelector((state) => state.CollectionsState.expertises)
  const popular_expertises = useSelector(
    (state) => state.CollectionsState.popular_expertises
  )
  const teams = useSelector((state) => state.UserState.teams || [])
  const user = useSelector((state) => state.UserState)

  const sources = useSelector((state) => state.CollectionsState.sources)
  const popular_sources = useSelector(
    (state) => state.CollectionsState.popular_sources
  )

  const companies = useSelector((state) => state.CollectionsState.companies)
  const popular_companies = useSelector(
    (state) => state.CollectionsState.popular_companies
  )
  const locations = useSelector((state) => state.CollectionsState.locations)
  const popular_locations = useSelector(
    (state) => state.CollectionsState.popular_locations
  )

  const [query, setQuery] = useQueryParams({
    res: StringParam,
    people_page: NumberParam,
    people_filters: ArrayParam,
    participants_page: NumberParam,
    participants_filters: ArrayParam,
  })
  const [filters, setFilters] = useState([])

  const parseFilters = (filters) => {
    if (filters) {
      return filters.map((f) => {
        const field = f.match(/.*?(?=:)/)[0]
        if (field === "status") {
          return {
            field: field,
            values: f.match(/:(.*)/g)[0].substr(1).trim(),
          }
        } else {
          return {
            field: field,
            values: f.match(/:(.*)/g)[0].substr(1).trim().split(","),
          }
        }
      })
    } else {
      return []
    }
  }

  const applyFilter = (checked, filter) => {
    const current = filters.find((f) => f.field === filter.field)
    const others = filters.filter((f) => f.field !== filter.field)

    if (checked) {
      if (current) {
        current.values.push(filter.value)
        current.values = _.uniq(current.values)
        setFilters([...others, current])
      } else {
        setFilters([...others, { field: filter.field, values: [filter.value] }])
      }
    } else {
      if (current.field === "status") {
        setFilters(others)
      }
      if (current) {
        current.values = current.values.filter((f) => f !== filter.value)
        if (current.values.length === 0) {
          setFilters(others)
        } else {
          setFilters([...others, current])
        }
      } else {
        setFilters([])
      }
    }
  }

  const applyRangeFilter = (enabled, filter) => {
    const current = filters.find((f) => f.field === filter.field)
    const others = filters.filter((f) => f.field !== filter.field)

    if (enabled) {
      if (current) {
        current.values.push(filter.value)
        current.values = _.uniqWith(current.values.reverse(), (a, b) => {
          if (a.match(/gt:.*/) && b.match(/gt:.*/)) {
            return a.match(/gt:.*/).length === b.match(/gt:.*/).length
          }
          if (a.match(/lt:.*/) && b.match(/lt:.*/)) {
            return a.match(/lt:.*/).length === b.match(/lt:.*/).length
          }
        })
        setFilters([...others, current])
      } else {
        setFilters([...others, { field: filter.field, values: [filter.value] }])
      }
    } else {
      if (current) {
        const dir = filter.value.slice(0, 2)
        const reg = new RegExp(`${dir}:.*`)
        current.values = current.values.filter((v) => !v.match(reg))
        if (current.values.length === 0) {
          setFilters(others)
        } else {
          setFilters([...others, current])
        }
      } else {
        setFilters([])
      }
    }
  }

  const updatePopularArrays = (include_archived) => {
    if (include_archived) {
      dispatch(fetchPopularCompanies([0, 1, 2]))
      dispatch(fetchPopularLocations([0, 1, 2]))
      dispatch(fetchPopularTopics([0, 1, 2]))
    } else {
      dispatch(fetchPopularCompanies([2]))
      dispatch(fetchPopularLocations([2]))
      dispatch(fetchPopularTopics([2]))
    }
  }

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchPopularCompanies([2]))
    dispatch(fetchLocations())
    dispatch(fetchPopularLocations([2]))
    dispatch(fetchTopics())
    dispatch(fetchPopularTopics([2]))
    dispatch(fetchExpertises())
    dispatch(fetchPopularExpertises([2]))
    dispatch(fetchSources())
    dispatch(fetchPopularSources([2]))

    dispatch(fetchSpheres())

    if (query && query.people_filters) {
      setFilters(parseFilters(query.people_filters))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const participants_filters =
      query.participants_filters?.filter((f) => f.includes("source")) || []
    const people_filters = []

    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (filter.field === "status") {
          participants_filters.push(`${filter.field}: ${filter.values}`)
          people_filters.push(`${filter.field}: ${filter.values}`)
        } else {
          if (filter.field === "last_sent") {
            participants_filters.push(`${filter.field}: ${filter.values.join(",")}`)
          }
          people_filters.push(`${filter.field}: ${filter.values.join(",")}`)
        }
      })
    }

    setQuery({
      res: "people",
      people_page: 1,
      people_filters: people_filters,
      participants_page: 1,
      participants_filters: participants_filters,
    })
    // eslint-disable-next-line
  }, [filters])

  const teamMembers = () =>
    _.uniqBy(
      teams.flatMap((team) => team.members),
      "id"
    )

  return (
    <div className="FiltersDrawer_Content">
      <img src={`${process.env.PUBLIC_URL}/filters.svg`} alt="Filters/Settings" />
      {[...spheres, ...teamSharedSpheres]?.length > 0 && (
        <MultiSelectWithList
          useSingularTitle={true}
          title="spheres"
          fillColor="lightGrey"
          options={[...spheres, ...teamSharedSpheres]}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      )}
      <SectionWithFill title="Engagement">
        <ListWithCheckbox
          title="engagement_status"
          options={engagementArray}
          filters={filters}
          onApplyFilter={applyFilter}
          reverse_checked={true}
        />
      </SectionWithFill>
      {teamMembers().length > 0 && (
        <MultiSelectWithList
          title="team_owners"
          fillColor="lightBlue"
          options={[
            { title: "None", id: "null" },
            ...teamMembers().map((member) => ({
              title:
                member.id === user.id
                  ? "This is me"
                  : member.first_name || member.last_name
                  ? `${member.first_name} ${member.last_name}`.trim()
                  : member.email,
              id: member.id,
            })),
          ]}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      )}
      {popular_expertises?.length > 0 && (
        <MultiSelectWithList
          useSingularTitle={true}
          title="expertises"
          fillColor="lightGreen"
          options={expertises}
          popular_options={popular_expertises}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      )}
      {popular_sources?.length > 0 && (
        <MultiSelectWithList
          useSingularTitle={true}
          title="sources"
          fillColor="lightYellow"
          options={sources}
          popular_options={popular_sources}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      )}
      {popular_topics?.length > 0 && (
        <MultiSelectWithList
          useSingularTitle={true}
          title="topics"
          fillColor="lightYellow"
          options={topics}
          popular_options={popular_topics}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      )}
      {popular_companies?.length > 0 && (
        <MultiSelectWithList
          useSingularTitle={true}
          title="companies"
          fillColor="lightPink"
          options={companies}
          popular_options={popular_companies}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      )}
      {popular_locations?.length > 0 && (
        <MultiSelectWithList
          useSingularTitle={true}
          title="location"
          fillColor="lightGreen"
          options={locations}
          popular_options={popular_locations}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      )}
      <SectionWithFill title="Importance">
        <ListWithCheckbox
          title="importance"
          options={importanceArray}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      </SectionWithFill>
      <SectionWithFill title="Strength">
        <ListWithCheckbox
          title="strength"
          options={strengthArray}
          filters={filters}
          onApplyFilter={applyFilter}
        />
      </SectionWithFill>
      <SectionWithFill title="Last Spoke With">
        <RangeDatePicker onApplyRangeFilter={applyRangeFilter} filters={filters} />
      </SectionWithFill>
      <SectionWithFill title="Options" fillColor="lightGrey">
        <ListWithCheckbox
          options={optionsArray}
          filters={filters}
          onApplyFilter={(checked, filter) => {
            applyFilter(checked, filter)
            if (filter.value === "active,archived") updatePopularArrays(checked)
          }}
        />
      </SectionWithFill>
    </div>
  )
}

const FiltersDrawer = ({ visible, handleClose }) => {
  return (
    <Drawer
      title="Filters"
      placement="right"
      onClose={handleClose}
      visible={visible}
      mask={false}
      className={"FiltersDrawer"}
      closable={false}
      destroyOnClose={true}
      getContainer={true}
      width="430px"
    >
      <FiltersDrawerContent />
    </Drawer>
  )
}

export default FiltersDrawer
