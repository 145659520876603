import React from "react"
import { Dropdown, Menu } from "antd"
import Icon from "@ant-design/icons"
import ArrowDownIcon from "../../Icons/ArrowDown.icons"
import "./TeamOwnerSelector.styles.scss"
import { updateTeamOwnership } from "../../../redux/People/People.actions"
import { useDispatch } from "react-redux"

const TeamOwnerSelector = ({
  team,
  currentUser,
  ownership,
  onOwnerChange,
  personId,
  teamId,
  disabled = false,
}) => {
  const dispatch = useDispatch()

  const menu = (
    <Menu className="TeamOwnerSelector_Menu">
      {team.members?.map((member) => (
        <Menu.Item key={member.id} onClick={() => handleTeamOwnerChange(member.id)}>
          {member.id === currentUser.id
            ? "This is me"
            : member.first_name && member.last_name
            ? `${member.first_name} ${member.last_name}`
            : member.email}
        </Menu.Item>
      ))}
    </Menu>
  )

  const handleTeamOwnerChange = (newOwnerId) => {
    if (onOwnerChange) {
      onOwnerChange(newOwnerId)
    } else {
      dispatch(updateTeamOwnership(personId, teamId, newOwnerId))
    }
  }

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomLeft"
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={`TeamOwnerSelector_Trigger ${disabled ? "disabled" : ""}`}>
        <span className="TeamOwnerSelector_Trigger_Text">
          {ownership
            ? ownership.owner_id === currentUser.id
              ? "This is me"
              : ownership.owner_name
            : "Select owner"}
        </span>
        <Icon
          component={ArrowDownIcon}
          className="TeamOwnerSelector_Trigger_Arrow"
        />
      </div>
    </Dropdown>
  )
}

export default TeamOwnerSelector
