import React, { useEffect, useState } from "react"
import { Button, Checkbox, Col, Row, Skeleton, Spin, Table } from "antd"
import { ApiFilled, CloseOutlined, HourglassFilled } from "@ant-design/icons/lib"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteAccount,
  fetchAccounts,
  fetchCalendars,
  makeDefault,
  resyncInteractions,
  updateCriteria,
} from "../../../redux/Accounts/Accounts.actions"
import SettingsIcon from "../../Icons/Settings.icons"
import Icon from "@ant-design/icons"
import "./AccountsSection.styles.scss"
import { nylasAuthenticationURI } from "../../../config/nylas"
import SheetIcon from "../../Icons/SheetIcon.icons"
import ZapierIcon from "../../Icons/Zapier.icons"
import MailIcon from "../../Icons/Mail.icons"
import LinkedinModal from "./LinkedinModal/LinkedinModal.component"
import LinkedInIcon from "../../Icons/LinkedIn.icons"
import CSVModal from "./CSVModal/CSVModal.component"
import KeyIcon from "../../Icons/Key.icons"
import ApiKeysModal from "./ApiKeysModal/ApiKeysModal.component"
import { StringParam, useQueryParam, useQueryParams } from "use-query-params"
import { useHistory } from "react-router"
import RotaryPhone from "../../Icons/RotaryPhone.icons"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const AccountsSection = () => {
  const [rotation, setRotation] = useState(0)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [linkedinModalOpen, setLinkedinModalOpen] = useState(false)
  const [CSVModalOpen, setCSVModalOpen] = useState(false)
  const [queryParams, setQueryParams] = useQueryParams({
    api_keys_modal: StringParam,
  })
  const [linkedinOpen] = useQueryParam("linkedin_open", StringParam)

  const history = useHistory()

  const dispatch = useDispatch()
  const accounts = useSelector((state) => state.AccountsState.accounts)

  const user = useSelector((state) => state.UserState)
  const loading = useSelector((state) => state.AccountsState.loading)
  const criteriaUpdating = useSelector(
    (state) => state.AccountsState.criteriaUpdating
  )
  const initializingID = useSelector((state) => state.AccountsState.initializingID)

  useEffect(() => {
    dispatch(fetchAccounts())
    if (linkedinOpen === "true") {
      setLinkedinModalOpen(true)
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (rotation >= 360) {
        setRotation(0)
      } else {
        setRotation((rotation) => rotation + 10)
      }
    }, 100)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  const onTableRowExpand = (rowId) => {
    if (expandedRowKeys.includes(rowId)) {
      setExpandedRowKeys(expandedRowKeys.filter((r) => r !== rowId))
    } else {
      dispatch(fetchCalendars(rowId))
      setExpandedRowKeys([...expandedRowKeys, rowId])
    }
  }

  const connectAccount = () => {
    nylasAuthenticationURI(null, null, (url) => {
      window.location.replace(url)
    })
  }

  const reconnect = (email) => {
    nylasAuthenticationURI(email, null, (url) => {
      window.location.replace(url)
    })
  }

  const columns = [
    {
      title: "EMAIL",
      key: "email",
      dataIndex: "email",
      render: (text, record) => (
        <Row>
          <Col span={2}>
            <div
              className={`AccountSettings_Accounts_StatusIndicator${
                record.state === "running" ? "-connected" : "-error"
              }`}
            />
          </Col>
          <Col>{text}</Col>
        </Row>
      ),
    },
    {
      title: "STATUS",
      key: "account_state",
      render: (record) => (
        <span>
          {record && record.state === "running"
            ? "Syncing normally"
            : "Syncing issue - please reconnect"}
        </span>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      render: (record) => (
        <div
          className="AccountsTable_Columns_Actions"
          onClick={() => onTableRowExpand(record.id.toString())}
        >
          <Icon component={SettingsIcon} /> Configure
        </div>
      ),
    },
  ]

  return (
    <div className="Wrapper">
      {linkedinModalOpen && (
        <LinkedinModal
          userId={user.id}
          onClose={() => setLinkedinModalOpen(false)}
          visible={linkedinModalOpen}
        />
      )}
      {CSVModalOpen && (
        <CSVModal
          userId={user.id}
          onClose={() => setCSVModalOpen(false)}
          visible={CSVModalOpen}
        />
      )}

      <h2 style={{ marginBottom: "20px" }}>Accounts</h2>
      <Col span={24}>
        <>
          {!accounts ? (
            <Row
              type={"flex"}
              align={"middle"}
              justify={"center"}
              style={{ height: "50vh" }}
            >
              <RelatableLoader />
            </Row>
          ) : (
            <div>
              <Table
                expandable={{
                  expandIconColumnIndex: -1,
                  expandedRowRender: (record) => (
                    <>
                      {loading ? (
                        <div className="AccountsTable_Expanded_CalendarsLoading">
                          {["Header", "Text", "Text", "Text"].map((item) => (
                            <Skeleton.Input
                              className={`AccountsTable_Expanded_CalendarsLoading_${item}`}
                              active
                            />
                          ))}
                        </div>
                      ) : (
                        record?.calendars?.length > 0 && (
                          <div className="AccountsTable_Expanded_Calendars">
                            <h3>Choose which calendars to sync</h3>
                            <Checkbox.Group
                              className="AccountsTable_Expanded_Calendars_List"
                              options={record.calendars}
                              disabled={criteriaUpdating}
                              defaultValue={record.calendars
                                .filter((c) => !record.criteria.includes(c.value))
                                .map((c) => c.value)}
                              onChange={(checkedValue) => {
                                const uncheckedValue = record.calendars
                                  .filter((c) => !checkedValue.includes(c.value))
                                  .map((c) => c.value)
                                dispatch(updateCriteria(record.id, uncheckedValue))
                              }}
                            />
                          </div>
                        )
                      )}
                      <div className="AccountsTable_Expanded_ManageButtons">
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="primary"
                            disabled={record?.default}
                            onClick={() => {
                              dispatch(makeDefault(record.id))
                            }}
                          >
                            Make default
                          </Button>
                          {record?.state === "running" &&
                          record?.grant_id != null ? (
                            <div>
                              <Button
                                onClick={() =>
                                  dispatch(resyncInteractions(record.nylas_id))
                                }
                              >
                                {initializingID === record.nylas_id ? (
                                  <>
                                    <HourglassFilled
                                      style={{
                                        transform: `rotateZ(${rotation}deg)`,
                                        marginRight: 10,
                                      }}
                                    />
                                    Initializing...
                                  </>
                                ) : (
                                  <>
                                    <ApiFilled style={{ marginRight: 10 }} />
                                    Resync
                                  </>
                                )}
                              </Button>
                            </div>
                          ) : (
                            <Button onClick={() => reconnect(record.email)}>
                              <ApiFilled />
                              Connect
                            </Button>
                          )}
                        </div>
                        <div>
                          {record.removing ? (
                            <div className="AccountsTable_Expanded_ManageButtons_Deleting">
                              <span style={{ marginLeft: 10 }}>
                                Deleting account...
                              </span>
                            </div>
                          ) : (
                            <>
                              {record.mark_for_deletion ? (
                                <span className="AccountsTable_Expanded_ManageButtons_MarkedForDeletion">
                                  Marked for deletion
                                </span>
                              ) : (
                                <Button
                                  type="danger"
                                  onClick={() =>
                                    dispatch(deleteAccount(record.nylas_id))
                                  }
                                >
                                  <CloseOutlined />
                                  Remove
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ),
                  rowExpandable: (record) => true,
                  expandedRowKeys: expandedRowKeys,
                }}
                pagination={false}
                rowKey={(record) => `${record.id}`}
                className={"AccountSettings_Accounts_Table"}
                columns={columns}
                dataSource={accounts.filter((account) => account.nylas_id !== null)}
              />
            </div>
          )}
        </>
      </Col>
      <div className="AccountOption">
        <h3>Connect an e-mail/calendar account (Google, Microsoft, and more)</h3>
        <button
          className="BlueButton"
          onClick={() => {
            connectAccount()
          }}
        >
          <Icon component={MailIcon} /> Connect
        </button>
      </div>
      <div className="AccountOption">
        <h3>Import a CSV spreadsheet of contacts from another tool</h3>
        <button
          className="BlueButton"
          onClick={() => {
            setCSVModalOpen(true)
          }}
        >
          <Icon component={SheetIcon} /> Import
        </button>
      </div>

      <div className="AccountOption">
        <h3>Sync your Relatable with LinkedIn</h3>
        <button
          className="BlueButton"
          onClick={() => {
            setLinkedinModalOpen(true)
          }}
        >
          <Icon component={LinkedInIcon} /> Sync
        </button>
      </div>

      <div className="AccountOption">
        <h3>iMessage & Cell Phone Calls (iPhone/OSX Only)</h3>
        <a
          href="https://relatable.helpscoutdocs.com/article/40-imessage-call-syncing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="BlueButton">
            <Icon component={RotaryPhone} /> Install
          </button>
        </a>
      </div>

      <div className="AccountOption">
        <h3>Connect Relatable with 1000+ apps via Zapier</h3>
        <a
          href="https://zapier.com/apps/relatable/integrations"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="BlueButton">
            <Icon component={ZapierIcon} /> Connect
          </button>
        </a>
      </div>
      <div className="AccountOption">
        <h3>Manage your API Keys</h3>
        <button
          className="BlueButton"
          onClick={() => {
            setQueryParams({ api_keys_modal: "show" })
          }}
        >
          <Icon component={KeyIcon} />
          Manage
        </button>
      </div>
      {queryParams.api_keys_modal === "show" && (
        <ApiKeysModal
          visible={queryParams.api_keys_modal === "show"}
          onClose={() => history.push("/account/settings")}
        />
      )}
    </div>
  )
}

export default AccountsSection
