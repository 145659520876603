import { Tabs, Tooltip } from "antd"
import React, { useEffect, useState } from "react"
import IntroductionsTab from "./IntroductionsTab/IntroductionsTab.component"
import "./RightPanel.styles.scss"
import "./IntroductionsTab/IntroductionsTab.component"
import WelcomeTab from "./WelcomeTab/WelcomeTab.component"
import { useDispatch, useSelector } from "react-redux"
import { fetchIntroductions } from "../../../redux/Introductions/Introductions.actions"
import ActivitiesTab from "./ActivitiesTab/ActivitiesTab.component"
import MessagesTab from "./MessagesTab/MessagesTab.component"
import { getStatus } from "../../../redux/User/User.actions"
import {
  setRightPanelTab,
  setRightRailCollapsed,
} from "../../../redux/App/App.actions"
import Sider from "antd/es/layout/Sider"
import Icon from "@ant-design/icons"
import CaretCircleDoubleLeftIcon from "../../../components/Icons/CaretCircleDoubleLeft.icons"
import { StringParam, useQueryParam } from "use-query-params"

const { TabPane } = Tabs

const RightPanel = ({ showInfoBar }) => {
  const dispatch = useDispatch()
  const awaiting_intros = useSelector(
    (state) => state.IntroductionsState.awaiting.introductions
  )
  const completed_intros = useSelector(
    (state) => state.IntroductionsState.completed.introductions
  )

  const status = useSelector((state) => state.UserState.status)
  const hideWelcomeTab = useSelector((state) => state.UserState.hide_welcome_tab)
  const activeRightPanelTab = useSelector(
    (state) => state.AppState.activeRightPanelTab
  )

  const rightRailCollapsed = useSelector(
    (state) => state.AppState.rightRailCollapsed
  )

  const [rightRailCollapsedWithDelay, setRightRailCollapsedWithDelay] =
    useState(rightRailCollapsed)

  const [section] = useQueryParam("section", StringParam)

  useEffect(() => {
    dispatch(getStatus())
    if (status === "onboarded") {
      dispatch(setRightPanelTab("activity"))
    }

    if (section) {
      dispatch(setRightPanelTab(section))
    }
    // eslint-disable-next-line
  }, [section])

  useEffect(() => {
    setTimeout(() => setRightRailCollapsedWithDelay(rightRailCollapsed), 300)
  }, [rightRailCollapsed])

  useEffect(() => {
    if (activeRightPanelTab === "intros") {
      dispatch(fetchIntroductions("awaiting", 1))
      dispatch(fetchIntroductions("completed", 1))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Sider
      breakpoint="lg"
      width={430}
      collapsedWidth="54"
      trigger={null}
      collapsible
      collapsed={rightRailCollapsed}
    >
      <div className={"RightPanel"}>
        <Tabs
          className={`RightPanel_Tabs ${
            showInfoBar && "RightPanel_Tabs--ShowInfoBar"
          }`}
          activeKey={activeRightPanelTab}
          onChange={(value) => dispatch(setRightPanelTab(value))}
          tabBarGutter={0}
          destroyInactiveTabPane={true}
          tabBarExtraContent={
            <button
              className={
                rightRailCollapsed
                  ? "RightPanel_OpenButton"
                  : "RightPanel_CloseButton"
              }
              onClick={() => dispatch(setRightRailCollapsed(!rightRailCollapsed))}
            >
              <Tooltip
                placement="left"
                mouseLeaveDelay={0}
                mouseEnterDelay={0.4}
                title={
                  rightRailCollapsedWithDelay ? "Expand Sidebar" : "Collapse Sidebar"
                }
              >
                <Icon component={CaretCircleDoubleLeftIcon} />
              </Tooltip>
            </button>
          }
        >
          {rightRailCollapsed ? null : (
            <>
              {status !== "onboarded" && !hideWelcomeTab && (
                <TabPane tab="Welcome" key="welcome">
                  <WelcomeTab />
                </TabPane>
              )}
              <TabPane tab="Activity" key="activity">
                <ActivitiesTab />
              </TabPane>
              <TabPane tab="Introductions" key="intros">
                {awaiting_intros && completed_intros && <IntroductionsTab />}
              </TabPane>
              <TabPane tab="Messages" key="messages">
                <MessagesTab />
              </TabPane>
              {status === "onboarded" && !hideWelcomeTab && (
                <TabPane tab="Welcome" key="welcome">
                  <WelcomeTab />
                </TabPane>
              )}
            </>
          )}
        </Tabs>
      </div>
    </Sider>
  )
}

export default RightPanel
