import React, { useState } from "react"
import * as _ from "lodash"
import { Tooltip } from "antd"
import moment from "moment"
import AtIcon from "../../../../../Icons/At.icons"
import CalendarCheckIcon from "../../../../../Icons/CalendarCheck.icons"
import TrashIcon from "../../../../../Icons/Trash.icons"
import "./InteractionItem.styles.scss"
import Icon, {
  EyeInvisibleOutlined,
  EyeOutlined,
  RightCircleFilled,
  LeftCircleFilled,
} from "@ant-design/icons"
import InteractionModal from "../InteractionModal/InteractionModal.component"
import {
  deleteEvent,
  deleteInteraction,
} from "../../../../../../redux/Interactions/Interactions.actions"
import { useDispatch, useSelector } from "react-redux"
import { urlify } from "../../../../../../helpers/helpers.jsx"
import ConfirmModal from "../../../../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"

const DOMPurify = require("dompurify")(window)

const InteractionItem = ({
  interaction,
  contactId,
  deletable = true,
  resetPage,
}) => {
  const [active, setActive] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.UserState)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)

  const formatInteractionDate = (interaction) => {
    if (interaction.last_message_timestamp) {
      return moment
        .unix(interaction.last_message_timestamp)
        .tz(user.time_zone)
        .format("MMM Do")
    } else if (interaction.last_message_received) {
      return moment(interaction.last_message_received)
        .tz(user.time_zone)
        .format("MMM Do")
    } else if (interaction.last_message_sent) {
      return moment(interaction.last_message_sent)
        .tz(user.time_zone)
        .format("MMM Do")
    }
  }

  const getMessageDirection = (interaction) => {
    if (interaction.sent) {
      return "sent"
    } else {
      return "received"
    }
  }

  const handleDeleteInteraction = () => {
    if (interaction.type === "Event") {
      dispatch(deleteEvent(interaction.id, contactId))
    } else {
      dispatch(deleteInteraction(interaction.id, contactId))
    }
    resetPage()
  }

  const direction = getMessageDirection(interaction)

  return (
    <div>
      <ConfirmModal
        title="Are you sure you want to delete this interaction?"
        visible={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        onConfirm={handleDeleteInteraction}
      />
      <InteractionModal
        contactId={contactId}
        interaction={interaction}
        visible={active}
        onVisibleChange={(visible) => {
          setActive(visible)
        }}
      >
        <Tooltip
          title={
            direction === "sent"
              ? "Message Sent"
              : direction === "received"
              ? "Message Received"
              : ""
          }
        >
          <div
            className={`InteractionItem ${
              active ? "InteractionItem_active" : ""
            } ${direction}`}
          >
            <div
              className="InteractionItem_Content"
              onClick={() => {
                setActive(!active)
              }}
            >
              <div className="InteractionItem_Content_Header">
                <Icon
                  component={
                    interaction.type === "Thread" ? AtIcon : CalendarCheckIcon
                  }
                  className="InteractionItem_Content_Header_Icon"
                />
                <div className="InteractionItem_Content_Header_SubjectAndDate">
                  <span>
                    <span>
                      {interaction?.subject?.slice(0, 24)}
                      {interaction?.subject?.length > 24 && "..."}
                    </span>
                  </span>

                  {(interaction?.last_message_timestamp ||
                    interaction?.last_message_received ||
                    interaction?.last_message_sent) && (
                    <span className={deletable ? "date-with-hover" : ""}>
                      <span className="InteractionItem_Content_Header_SubjectAndDate_Date">
                        <span>{formatInteractionDate(interaction)}</span>

                        {interaction.type === "Thread" && (
                          <div className={`MessageDirection ${direction}`}>
                            {direction === "sent" ? (
                              <RightCircleFilled style={{ fontSize: "14px" }} />
                            ) : (
                              <LeftCircleFilled style={{ fontSize: "14px" }} />
                            )}
                          </div>
                        )}
                      </span>
                      {deletable && (
                        <span className="InteractionItem_TrashIcon_Container">
                          <Tooltip title="Remove">
                            <Icon
                              component={TrashIcon}
                              className="InteractionItem_TrashIcon"
                              onClick={(e) => {
                                setConfirmModalVisible(true)
                                e.stopPropagation()
                              }}
                            />
                          </Tooltip>
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="InteractionItem_Content_Body">
                {interaction?.body && (
                  <div>
                    {interaction.body
                      ?.replace(/<\/?[^>]+(>|$)/g, "")
                      ?.substring(0, 100)}
                  </div>
                )}
                {interaction?.last_message?.tracking === "open" && (
                  <>
                    {interaction.last_message?.last_opened ? (
                      <Tooltip
                        title={`Last opened ${moment(
                          interaction.last_message.last_opened
                        )
                          .tz(user.time_zone)
                          .format("LL")}`}
                      >
                        <EyeOutlined className="InteractionItem_Content_Body_Icon" />
                      </Tooltip>
                    ) : (
                      <EyeInvisibleOutlined className="InteractionItem_Content_Body_Icon" />
                    )}
                  </>
                )}
              </div>
            </div>

            {/* {deletable && (
              <Tooltip title="Remove">
                <Icon
                  component={TrashIcon}
                  className="InteractionItem_TrashIcon"
                  onClick={(e) => {
                    setConfirmModalVisible(true)
                    e.stopPropagation()
                  }}
                />
              </Tooltip>
            )} */}
          </div>
        </Tooltip>
      </InteractionModal>
    </div>
  )
}

export default InteractionItem
